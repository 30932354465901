<template> 
        <DrawerItem v-if="props.visible !== false"
                        v-bind="props"
                        @click="props.onClick"
                        :disabled="props.disabled">
                        <span :class="'k-icon ' + props.icon" />
                        <span class="k-item-text">{{ props.text }}</span>
                        <span v-if="props['data-expanded'] !== undefined"
                        :class="props['data-expanded'] ? downClass : null"
                        :style="{
                        position: 'absolute',
                        right: '10px',
                        }" />
            </DrawerItem> 
</template>

<script>

    import ApiService from "@/core/services/api-service"; import PDFForm from "@/core/services/pdf-form";
    import { PanelBar } from '@progress/kendo-layout-vue-wrapper';
    import { loadFileUrl } from "@/core/services/api-service"; 
    import GuidedArrow from "@/components/GuidedArrow.vue";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-vue-layout";
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardSubtitle,
        CardActions,
    } from "@progress/kendo-vue-layout";

    export default {
        name: 'SigningRoomDocs',
        components: { "k-button": Button, },
        data() {
            return {
                documents: null 
            }
        },
        async mounted() { 
            ApiService.GenerateGetRequest("document/nsigner?contextID=KxsKDtiIx0YdwI3I7XdhCA").then((response) => {
                this.documents = response.content;
                console.log("Document Details:", this.document);
            });
        },
        methods: {
             
        },
    }
</script>
