<template>
    <div></div>
</template>

<script>
    import OT from '@opentok/client';
    export default {
        name: 'publisher',
        props: {
            session: {
                type: OT.Session,
                required: true
            }, opts: {
                type: Object,
                required: false,
            },
        },
        watch: {
            // whenever question changes, this function will run
            opts: function () {
                this.createPublisher();
                this.publish();
            },
        },
        data() {
            return {
                publisher: null,
            };
        },
        mounted: function () {
            this.createPublisher();
            this.publish();
        },
        methods: {
            createPublisher: function () {
                const publisher = OT.initPublisher(this.$el, this.opts, (err) => {
                    if (err) {
                        this.$emit("error", err);
                    } else {
                        this.$emit("publisherCompleted");
                    }
                });
                this.$emit("publisherCreated", publisher);
                this.publisher = publisher;
            },
            publish: function () {
                const publish = () => {
                    this.session.publish(this.publisher, (err) => {
                        if (err) {
                            this.$emit("error", err);
                        } else {
                            this.$emit("publisherConnected", this.publisher);
                        }
                    });
                };
                if (this.session && this.session.isConnected()) {
                    publish();
                }
                if (this.session) {
                    this.session.on("sessionConnected", publish);
                }
            },
        },
    };
</script>