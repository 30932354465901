<template>
  <k-dialog
    @close="cancel"
    :style="{ zIndex: 2 }"
    width="300"
    :title="'Edit Document Details'"
  >
    <div :style="{ marginBottom: '1rem' }">
      <label>Review Required</label><br />
      <k-switch v-model="reviewRequired" />  <br /><br />
      <label>Wet Sign</label><br />
      <k-switch v-model="wetSign" />
    </div>
    <dialog-actions-bar :style="{ justifyContent: 'center' }">
      <button class="k-button" @click="cancel">
        Cancel
      </button>
      <button class="k-button k-primary" @click="save">
        Save
      </button>
    </dialog-actions-bar>
  </k-dialog>
</template>
<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Switch } from "@progress/kendo-inputs-vue-wrapper";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    "k-switch": Switch,
  },
  props: ["reviewRequired", "wetSign"],
  data: function() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$emit("save", {
        wetSign: this.wetSign,
        reviewRequired: this.reviewRequired,
      });
    },
  },
};
</script>
