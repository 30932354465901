<template>
	<img class="esignlogo" src="/images/esign-logo.png" />
	<div class="center-text">
		<h2>Welcome to eSign</h2>
	</div>

	<ul>
		<li>For Technical Support: </li>
		<li><a href="https://signiahelp.zendesk.com/hc/en-us">Stavvy Inc.</a></li>
		<li><small>&copy;{{new Date().getFullYear()}} Stavvy Inc. - Ref. #{{ref}}</small></li>
	</ul>
</template>

<style scoped>
	.esignlogo {
		margin: 0;
		padding: 0;
		height: 80px;
		position: absolute;
		left: 30px;
		top: 30px;
	}

	header, footer {
		height: 20%;
	}

    ul {
        list-style: none;
        font-size: 20px;
        color: #1A1C44;
        position: absolute;
        bottom: 0;
        right: 20px;
    }

		ul a {
			text-decoration: underline;
		}

		ul li {
			line-height: 28px;
		}

		ul small {
			font-size: 14px;
		}

	h2 {
		font: 600 16px/80px 'Arial','Open Sans',sans-serif;
		font-size: 5em;
		color: white;
		text-shadow: 2px 2px rgb(108 108 108 / 68%);
		line-height: 54px;
		text-align: center;
	}

    .center-text {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1A1C44;
    }
    .center-text  h2{
        color: #1A1C44;
    }

	@media screen and (max-width: 767px) {
		ul {
			text-align: right;
		}

		h2 {
			font-size: 50px;
			text-align: center;
		}

		.esignlogo {
			height: 80px;
			display: block;
			width: auto;
		}

		h2 {
			width: 80%;
		}
	}
</style>
<script>

	export default {
		name: 'Landing',
		created() {
			// reset login status
			document.body.id = "login";
		},
	}
</script>