<template>
    <!-- <k-button
      :look="'flat'"
      primary="true"
      @click="sendInviteHandler"
      class="customBtn"
    >
      Send Invitation
    </k-button>
    <k-button
      :look="'flat'"
      primary="true"
      @click="copyHandler"
      class="customBtn"
    >
      Copy Invite Link
    </k-button> -->
    <k-button :look="'flat'" @click="removeHandler" class="customBtn deleteBtn">
      Remove
    </k-button>
</template>
<script>
import { Button } from "@progress/kendo-vue-buttons";
import Swal from "sweetalert2"
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  components: {
    "k-button": Button,
  },
  methods: {
    // sendInviteHandler: function() {
    //   this.$emit("send", this.dataItem);
    // },
    // copyHandler: function() {
    //   navigator.clipboard.writeText(this.dataItem.inviteLink).then(
    //     function() {
    //       alert("Invite link copied to clipboard");
    //     },
    //     function(err) {
    //       console.error("Async: Could not copy text: ", err);
    //     }
    //   );
    // },
    removeHandler: function() {
      debugger
      Swal.fire({
        title: `Do you want to remove ${this.dataItem.firstName}  ${this.dataItem.lastName}` ,
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Confirm',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$emit("remove", this.dataItem);
        }
      })
    },
  },
};
</script>

<style scoped>
.customBtn {
  font-size: small;
  padding: 4px 8px;
}
.deleteBtn {
  color: #fa4d52;
}
</style>
