<template>
  <div style="display: flex; align-items: center; justify-content: center">
    <vue-recaptcha
      v-if="showRecaptcha"
      siteKey="6Lc24PUcAAAAAD6O98QTgmrk4k8KSlFHMDpfxpUI"
      size="normal"
      theme="light"
      :tabindex="0"
      @verify="recaptchaVerified"
      @expire="recaptchaExpired"
      @fail="recaptchaFailed"
      ref="vueRecaptcha"
    >
    </vue-recaptcha>
  </div>
</template>

<script>
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "app",
  components: {
    vueRecaptcha,
  },
  emits: ["set2FAVisible"],
  data() {
    return { showRecaptcha: true };
  },
  methods: {
    recaptchaVerified(response) {
      this.$emit("set2FAVisible");
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},
  },
};
</script>
