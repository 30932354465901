class KendoPopupGenerator {
    static generatePopUpFormFields(fieldName, label, bindTo, isRequired = true) {
        let required = isRequired ? 'required="required"' : "";
        let labelHTML = `<div class="k-edit-label"><label for="${fieldName}">${label}</label></div>`;
        let inputHTML = `<div data-container-for="${fieldName}" class="k-edit-field"><input type="text" id="${fieldName}" name="${fieldName}" title="${label}" ${required} autocomplete="off" data-bind="value:${bindTo}" class="k-textbox" aria-invalid="false" aria-describedby="${fieldName}-error"/></div>`;

        return labelHTML + inputHTML;
    }

    static generateNumberPopUpFormFields(fieldName, label, bindTo) {
        let labelHTML = `<div class="k-edit-label"><label for="${fieldName}">${label}</label></div>`;
        let inputHTML = `<div data-container-for="${fieldName}" class="k-edit-field"><input type="number" id="${fieldName}" name="${fieldName}" title="${label}" required="required" autocomplete="off" data-bind="value:${bindTo}" class="k-textbox" aria-invalid="false" aria-describedby="${fieldName}-error"/></div>`;

        return labelHTML + inputHTML;
    }

    static generatePopUpFormFieldsReadOnly(fieldName, label, bindTo) {
        let labelHTML = `<div class="k-edit-label"><label for="${fieldName}">${label}</label></div>`;
        let inputHTML = `<div data-container-for="${fieldName}" class="k-edit-field" style="background-color:gainsboro;"><input type="text" id="${fieldName}" name="${fieldName}" title="${label}" required="required" autocomplete="off" data-bind="value:${bindTo}" class="k-textbox" aria-invalid="false" aria-describedby="${fieldName}-error" readonly/></div>`;

        return labelHTML + inputHTML;
    }

    static generateEmailPopUpFormFields(fieldName, label, bindTo) {
        let labelHTML = `<div class="k-edit-label"><label for="${fieldName}">${label}</label></div>`;
        let inputHTML = `<div data-container-for="${fieldName}" class="k-edit-field"><input type="email" pattern="[A-Za-z0-9._%+-]{2,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})" id="${fieldName}" name="${fieldName}" title="${label}" required="required" autocomplete="off" data-bind="value:${bindTo}" class="k-textbox" aria-invalid="false" aria-describedby="${fieldName}-error"/></div>`;

        return labelHTML + inputHTML;
    }

    static generateMiddlePopUpFormFields(fieldName, label, bindTo) {
        let labelHTML = `<div class="k-edit-label"><label for="${fieldName}">${label}</label></div>`;
        let inputHTML = `<div data-container-for="${fieldName}" class="k-edit-field"><input type="text" id="${fieldName}" name="${fieldName}" title="${label}" autocomplete="off" data-bind="value:${bindTo}" class="k-textbox" aria-invalid="false" aria-describedby="${fieldName}-error"/></div>`;

        return labelHTML + inputHTML;
    }

    static generatePopUpHiddenFormFields(fieldName, label, bindTo) {
        let inputHTML = `<div  data-container-for="${fieldName}" class="populate-data k-edit-field"><input type="hidden" id="${fieldName}" name="${fieldName}" title="${label}" autocomplete="off" data-bind="value:${bindTo}" class="k-textbox" value="text" /></div>`;

        return inputHTML;
    }


    static generatePopUpFileFormField(fieldName, label, id, isRequired = true) {
        let required = isRequired ? 'required="required"' : "";
        let labelHTML = `<div class="k-edit-label"><label for="${fieldName}">${label}</label></div>`;
        let inputHTML = `<div data-container-for="${fieldName}" class="k-edit-field"><input type="file" id="${id}" name="${fieldName}" title="${label}" ${required} autocomplete="off"  class="k-textbox" aria-invalid="false" aria-describedby="${label}-error" accept=".pdf"/></div>`;

        return labelHTML + inputHTML;
    }



    static generatePopUpFormDropdown(fieldName, label, bindTo, options, id, text, isRequired = true) {
        let required = isRequired ? 'required="required"' : "";
        let labelHTML = `<div class="k-edit-label"><label for="${fieldName}">${label}</label></div>`;
        let dropdown = `<select style="width:100%" id="${fieldName}" name="${fieldName}" title="${label}"  ${required}  autocomplete="off" data-bind="value:${bindTo}">`;
        options.forEach(function (item) {
            dropdown += `<option value="${item[id]}">${item[text]}</option>`;
        });
        dropdown += `</select>`;
        let inputHTML = `<div data-container-for="${fieldName}" class="k-edit-field">${dropdown}</div>`;
        console.log(inputHTML);
        return labelHTML + inputHTML;
    }


    static generatePopUpFormCheckboxFields(fieldName, label, bindTo, dataId = "", disabled=false) {
        let inputHTML = `<div class="k-edit-field toggle" style="overflow-wrap: break-word;">${label}<div class="Toggle-Switch pull-right"><input data-id="${dataId}" id="${fieldName}" name="${fieldName}" type="checkbox" value="false"  data-bind="checked:${bindTo}" ${disabled ? "disabled" : ""} />`;
        inputHTML += `<label for="${fieldName}" class="label-primary"></label></div></div>`;

        return inputHTML;
    }

    static generatePopUpFormTextarea(fieldName, label, bindTo, rows = 30, cols = 50, isRequired = false) {
        let required = isRequired ? 'required="required"' : "";
        let labelHTML = `<div class="k-edit-label"><label for="${fieldName}">${label}</label></div>`;
        let inputHTML = `<textarea id="${fieldName}" name="${fieldName}" data-bind="v-model="${bindTo}" rows='${rows}' cols='${cols}' style='width:100%;' ${required}> </textarea>`;
        return labelHTML + inputHTML;
    }

    static generatePopUpFormDefaultCheckboxFields(fieldName, label, bindTo, dataId = "", disabled = false) {
        let inputHTML = `<div class="k-edit-field toggle" style="overflow-wrap: break-word;">
                        ${label}<div class="Toggle-Switch"><input data-id="${dataId}" id="${fieldName}" name="${fieldName}" type="checkbox" value="false"  data-bind="checked:${bindTo}" ${disabled ? "disabled" : ""} />`;
        inputHTML += `<label for="${fieldName}" class="label-primary"></label></div></div>`;

        return inputHTML;
    }
     
}

export default KendoPopupGenerator;