<template>

    <card class="card">
        <cardHeader>
            <h2 class="text-center">{{title}}</h2>
        </cardHeader>
        <cardBody>
            <progress-bar :percentage="percentage" />
            <h4 v-if="hide && isRon === true" class="text-center">{{name}} <br /><strong>{{documentNumber}}</strong> of {{totalDocumentCount}}</h4>
            <div class="row p-m">
                <div class="row ">
                    <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12  text-center">
                        <div>
                            Please be patient while the system is processing your request. Loading times can vary depending upon your internet/Wi-Fi connection, speed and number of documents that are rendering.
                        </div>
                    </div>
                </div>
            </div>
        </cardBody>
    </card>

</template>

<script>
    import moment from "moment";
    import ProgressBar from "@/components/ProgressBar.vue";
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle
    } from "@progress/kendo-vue-layout";
    export default {
    name: "LoadingCard",
        props: ["percentage", "name", "documentNumber", "totalDocumentCount", "title", "hide", "isRon"],
        emits: [],
        components: {
            ProgressBar,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
        },
        data() {
            return {
            }
        },
        computed: { moment: () => moment },
        created() {
            console.log("HIDE HIDE HIDE", this.hide);
        },
        async mounted() {
          
        },
        methods: {
        }
    };
</script>
