<template>
    <h1>Waiting List</h1>

    <div class="frame">
        <form>
            <div data-panel="data-panel">
                <div data-options="data-options">
                    <div class="panel__line">
                        <div v-for="i in waitingParticipants" style="width:100%; margin-bottom:1em;" class="row" :key="waitingParticipants">
                            <label class="col-md-4">
                                {{i.firstName}} {{i.lastName}}
                            </label>
                            <label class="col-md-4">
                                <kbutton @click.prevent="admitParticipant(i.userID)" class="k-button-primary" style="width:100%;">Admit</kbutton>
                            </label>
                            <label class="col-md-4">
                                <kbutton @click="declineParticipant(i.userID)" class="k-button-secondary" style="width:100%; background: rgba(255, 104, 32, 0.87); color: white;">Decline</kbutton>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>




</template>
<script>
    import { Button } from "@progress/kendo-vue-buttons";
    import ApiService from "@/core/services/api-service";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import Swal from "sweetalert2";

    export default {
        name: 'NotaryWaitingList',
        components: {
            "kbutton": Button,
            'dropdownlist': DropDownList,
        },
        props: ['NotarizeID'],
        data() {
            return {
                waitingParticipants: []
            };
        },
        async mounted() {
            //get the waiting list from the db
            await this.updateList();
            console.log(this.NotarizeID, this.waitingParticipants);
        },
        methods: {
            admitParticipant(i) {
                //push to participant & update waiting list
                ApiService.GeneratePostRequest(`transaction/notaryAdmitParticipant`, { "SignerID": i, "NotarizeID": this.NotarizeID, "NotaryAction": "accept" });
                this.updateList();
            },
            declineParticipant(i) {
                //send decline to user
                ApiService.GeneratePostRequest(`transaction/notaryAdmitParticipant/`, { "SignerID": i, "notarizeID": this.NotarizeID, "NotaryAction": "decline" });
                this.updateList();
            },
            async updateList() {
                await ApiService.GenerateGetRequest("notarize/waitingParticipants/" + this.NotarizeID).then(
                    response => {
                        this.waitingParticipants = response["content"];
                        console.log(this.NotarizeID, this.waitingParticipants);
                    }
                );
            }
        }
    }
</script>
<style scoped>
    .frame {
        width: 100%;
        border-radius: 4px;
        box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background: #fff;
        color: #333;
        font-family: "Open Sans", Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    header {
        text-align: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
    }

    h1 {
        font-size: 1.7rem;
        margin-bottom: 4px;
    }

    h2 {
        font-size: 1rem;
        font-weight: 400;
    }

    .list {
        list-style: none;
        margin-top: 24px;
        padding-left: 0;
    }

        .list li {
            margin-top: 10px !important;
            padding-bottom: 0px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        .list label {
            cursor: pointer;
            flex-grow: 1;
            transition: color 0.5s;
        }

        .list input {
            position: relative;
        }

            .list input::after {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                border: 1px solid #888888;
                border-radius: 50%;
                transform: translate(-50%, -50%) scale(1);
                top: 50%;
                left: 50%;
                background-color: white;
                transition: transform 0.6s, border 0.6s;
            }

            .list input::before {
                position: absolute;
                /*content: "\2714";*/
                height: 18px;
                width: 18px;
                transform: translate(-50%, -50%) scale(0.8);
                top: 60%;
                left: 80%;
                color: #888;
                z-index: 1;
                opacity: 0;
                transition: transform 0.6s, opacity 0.6s;
            }

            .list input:checked + label {
                color: rgba(51, 51, 51, 0.4);
            }

            .list input:checked::after {
                transform: translate(-50%, -50%) scale(1.2);
                border: 1px solid rgba(136, 136, 136, 0);
            }

            .list input:checked::before {
                opacity: 0.4;
                transform: translate(-50%, -50%) scale(1);
            }

    .title-container {
        width: 100%;
        padding-top: 10px;
        padding-left: 10px;
    }

        .title-container h2 {
            margin: 0;
            font-size: 1.6rem;
        }

    .panel {
        display: flex;
    }

    .panel__body {
        width: 100%;
        border-left: 1px solid #CCC;
    }

    .panel__side {
        width: 30%;
        padding: 20px;
    }

    .panel__line {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        border-bottom: 1px solid #CCC;
    }

    .panel__item {
        flex-direction: row;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        /*width: calc(100% / 4);*/
        padding: 10px 10px;
    }

        .panel__item > span {
            padding: 10px 0;
        }

    .cart {
        border: 2px solid transparent;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .cart--active {
        border-color: red;
    }

    .cart__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .panel__img {
        cursor: pointer;
    }

    .accordion .card-header {
        border: 1px solid #CCC;
    }

    .accordion .card {
        margin: 0;
        border-radius: 0;
    }

    .accordion h2 {
        margin: 0;
        font-size: 1.7rem;
        padding: 10px;
    }
</style>
