<template>
        <kendo-datasource ref="notesource"
                          :transport-read="readData"
                          :transport-create="createData"
                          :transport-update="updateData"
                          :transport-destroy="deleteData"
                          :schema-model-id="'noteId'"
                          :schema-model-fields="schema"
                          :transport-parameter-map="parameterMap"
                          :page-size="8">

        </kendo-datasource>

        <kendo-grid ref="kendoJqueryGrid"
                    :data-source-ref="'notesource'"
                    :height="500"
                    :class="'notes'"
                    :sortable="true"
                    :resizable="true"
                    :navigatable="true"
                    :pageable="true"
                    :pdf-all-pages="true"
                    :pdf-avoid-links="true"
                    :editable="packagePopUpTemplate"
                    :pdf-paper-size="'A4'"
                    :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                    :pdf-landscape="true"
                    :pdf-repeat-headers="true"
                    :pdf-scale="0.8"
                    :columns="columns"
                    :edit="onEdit"
                    @databound="noteDataBound"
                    @save="onPopUpModalSaved">


            <toolbar>
                    <button title="Add New" class="k-button k-primary" @click="noteAdd">
                        Add
                    </button>
            </toolbar>
        </kendo-grid>
    </template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .note-cmd, /deep/ .note-cmd:hover, /deep/ .note-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 .94em 'Lato', sans-serif;
        color: #4d97ac;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.completed /deep/ span, .status.remote /deep/ span {
            background: #19a71c;
        }

        .status.pending /deep/ span, .status.pendingsigning /deep/ span {
            background: #f8ab10;
        }

        .status.canceled /deep/ span {
            background: #ff2b2b;
        }

        .status.reviewed /deep/ span {
            background: #e960ea;
        }

        .status.pendingreview /deep/ span {
            background: #c14e4e;
        }

        .status.paper /deep/ span {
            background: #c6c6c6;
        }

        .status.signing /deep/ span, .status.signed /deep/ span {
            background: #296298;
        }
</style>
<script>
    import { useRoute } from 'vue-router';
    import ApiService from "@/core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';
    import Swal from "sweetalert2";

    export default {
        name: 'Notes',
        props:["packageid"],
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'dropdownlist': DropDownList,
            'native-grid': NativeGrid,
            toolbar: GridToolbar,
        },
        data() {
            ///****************************************************************************** NOTE ******************************************************************************///
            ///This is the ONLY WAY right now to include a function to specify if a column can be visible. All other ways fail due to a bug in kendo that attempts to add a _ctx ///
            ///to variables in the function thereby breaking the generated template. The workaround is to have an inline function so that kendo cannot access the function scope ///
            ///******************************************************************************************************************************************************************///

            return {
                schema: {
                    noteId: { editable: false },
                    notes: { type: 'string', editable: true },
                    createdBy: { type: 'string', editable: true },
                    createdDate: {type:'date', editable:false}
                },
                columns: [
                    { field: "noteId", title: 'ID', hidden: true },
                    { field: "createdDate", title: 'Date', width: '14%', format:"{0:MM/dd/yyyy h:mm tt}" },
                    { field: "notes", title: 'Note' },
                    
                ],

                notesList: [],

            }
        },
        async created() {

            let response = await ApiService.GenerateGetRequest(`Package/notes/${this.packageid}`);
            console.log(response, "notes");
            if (response["isSuccess"])
                this.notesList = response["content"];
        },
        computed: {
            packagePopUpTemplate(e) {
                let html = "";
                html += "<div id='NotesGrid'>";
                html += "   <div class='col-md-6'>";
                html += KendoPopupGenerator.generatePopUpFormFields('Notes', 'Note', 'notes');
                html += "   </div>";
                html += "</div>";
                return { 'template': html, 'mode': 'popup', width: "600px", height: "300px", }
            },
        },
        methods: {
            onPopUpModalSaved(e) {
                console.log("onPopUpModalSaved", e);
            },
            noteAdd() {
                this.$refs.kendoJqueryGrid.kendoWidget().addRow();
            }, 
            noteDataBound: function (e) {
                var self = this;
                var grid = e.sender;
                var items = e.sender.items();
            },
            parameterMap: function (options, operation) {
                console.log("options", options);
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {
                //console.log(e)
                return ApiService.GenerateGetRequest(`package/notes/${this.packageid}`).then(
                    response => {
                        if (response["isSuccess"]) {
                            e.success(response["content"]);
                            console.log("notes2", response["content"]);
                        }
                    }
                );
            },
            createData: function (e) {
                console.log("Creating", e);
                return ApiService.GeneratePostRequest("package/addnote", {
                    PackageID: this.packageid,
                    Notes: e.data.notes
                }).then(
                    response => {
                        e.success(response["content"]);
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'Note created',
                                text: 'Note added successfully',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: 'Unable to add note',
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                    }
                );
            },

            updateData: function (e) {
                console.log("updating", e);
                
            },
            deleteData: function (e) {
                console.log("deleting", e);
               
            },
            searchGrid: function () {
                console.log(this.search);
            },
            onEdit(e) {
                if (e.model.isNew()) {
                    $(".k-window-title").text("Add");
                }
                // handles resizing the size of the edit form container
                $(".k-edit-form-container").parent().width(1000).data("kendoWindow").center();
                $(".k-edit-form-container").css('width', 'auto');

            }
        },
    }
</script>