<template>

    <form role="form">
        <div class="panel panel-primary setup-content">
            <div class="row">
                <div class="innerbox col-md-7 ">
                    <div class=" col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <h2>Confirm Your Details</h2>
                                <br />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <h3><label for="firstName">First Name</label></h3>
                                <input class="form-control bdr-rad-0 valid" v-model="firstName" name="firstName" placeholder="First Name" type="text" aria-describedby="firstName-error">
                                <span v-if="v$.firstName.$error" class="text-danger field-validation-valid">Name cannot be empty.</span>
                            </div>
                            <div class="col-md-4">
                                <h3><label for="middleName">Middle Name</label></h3>
                                <input class="form-control bdr-rad-0" v-model="middleName" name="middleName" placeholder="Middle Name" type="text">


                            </div>
                            <div class="col-md-4">
                                <h3><label for="lastName">Last Name</label></h3>
                                <input class="form-control bdr-rad-0" v-model="lastName" name="lastName" placeholder="Last Name" type="text">
                                <span v-if="v$.lastName.$error" class="field-validation-valid text-danger">Last name cannot be empty</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h3>Date of Birth</h3>
                            </div>

                            <div class="col-md-6">
                                <select class="form-control bdr-rad-0" v-model="birthMonth" name="birthMonth">
                                    <option value="" disabled selected>Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                                <span v-if="v$.birthMonth.$error" class="field-validation-valid text-danger">Select birth month</span>
                            </div>
                            <div class="col-md-3">
                                <select class="form-control bdr-rad-0" v-model="birthDay" name="birthDay">
                                    <option value="" disabled selected>Day</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                </select>
                                <span v-if="v$.birthDay.$error" class="field-validation-valid text-danger">Select birth day</span>
                            </div>
                            <div class="col-md-3">
                                <select class="form-control bdr-rad-0" v-model="birthYear" name="birthYear">
                                    <option value="" disabled selected>Year</option>
                                    <option v-for="year in yearRanges" :value="year">{{year}}</option>
                                </select>
                                <span v-if="v$.birthYear.$error" class="field-validation-valid text-danger">Select birth year</span>
                            </div>
                            <div class="col-md-12 ">
                                <h3>Address</h3>
                                <input class="form-control bdr-rad-0" data-val-required="*" v-model="addressLine1" name="addressLine1" placeholder="Address Line 1" type="text">
                                <span v-if="v$.addressLine1.$error" class="field-validation-valid text-danger">Address Line 1 cannot be empty</span>

                                <input class="form-control bdr-rad-0" v-model="addressLine2" name="addressLine2" placeholder="Address Line 2" type="text">
                                <span class="field-validation-valid text-danger"></span>

                                <input class="form-control bdr-rad-0" v-model="city" name="city" placeholder="city" type="text">
                                <span v-if="v$.city.$error" class="field-validation-valid text-danger"></span>

                                <select class="form-control bdr-rad-0" v-model="countryID" name="countryID">
                                    <option value="" selected>Select Country</option>
                                    <option v-for="country in countryList" :value="country.id">{{country.countryName}}</option>
                                </select>
                                <div>

                                    <span v-if="v$.countryID.$error"
                                          class="field-validation-valid text-danger">Select country</span>
                                </div>

                            </div>

                            <div class="col-md-6" v-show="statesList.length > 0">
                                <select class="form-control bdr-rad-0" v-model="stateID" name="stateID">
                                    <option value="">Select State</option>
                                    <option v-for="state in statesList" :value="state.id">{{state.stateName}}</option>
                                </select>
                                <span v-if="v$.stateID.$error" class="field-validation-valid text-danger">Select state</span>
                            </div>
                            <div :class="zipCodeContainerWidth">
                                <input class="form-control bdr-rad-0" v-model="zipCode" name="zipCode" placeholder="Zip Code" type="text">
                                <span v-if="v$.zipCode.$error" class="field-validation-valid text-danger">Zip Code cannot be empty</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ">
                                <h3>Email</h3>
                                <input class="form-control bdr-rad-0" disabled="disabled" v-model="emailAddress" name="emailAddress" type="text">

                                <input v-model="emailAddress" name="emailAddress" type="hidden">
                            </div>
                            <div class="col-md-12">
                                <span class="field-validation-valid text-danger" data-valmsg-for="ErrorMsg" data-valmsg-replace="true"></span>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    // Future todo - this component can be converted to composition api to make the watch property mroe cleaner.
    // learning curve tho.

    import ApiService from "@/core/services/api-service";
    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';
    const stateValidator = (value) => {
        value = value + "";
        return value === -1 || value.trim().length > 0;
    };
    export default {
        name: 'SignerDetails',
        setup() {
            return { v$: useVuelidate() }
        },
        emits: ["componentValid"],
        props: ["preloadedData"],
        data() {
            return {
                firstName: "",
                middleName: "",
                lastName: "",
                birthMonth: "",
                birthDay: "",
                birthYear: "",
                city: "",
                countryID: "",
                stateID: "",
                zipCode: "",
                addressLine1: "",
                addressLine2: "",
                emailAddress: "",


                firstLoad: true,
                countryList: [],
                statesList: []
            }
        },
        async created() {
            // fetch country list
            let countryResponse = await ApiService.GenerateGetRequest("Country/all");
            if (countryResponse && countryResponse.length) {
                this.countryList = countryResponse;
                // Find the index of "United States of America" in the countryList
                let usaIndex = this.countryList.findIndex(element => element.countryName === "United States of America");

                // Check if "United States of America" is found in the list
                if (usaIndex !== -1) {
                    // Remove the USA from its original position
                    let usa = this.countryList.splice(usaIndex, 1)[0];

                    // Sort the remaining countries alphabetically
                    this.countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));

                    // Add USA back to the top
                    this.countryList.unshift(usa);
                }
            }
            // set up basic available information
            let signerData = this.$store.getters["Auth/signerSessionInformation"];
            if (signerData) {
                this.firstName = signerData["firstName"];
                this.lastName = signerData["lastName"];
                this.emailAddress = signerData["emailAddress"];
            }

            // fetch local saved data from session store
            // TODO add watcher to populate country and zipCode when ajax fetches it
            let signerDetails = this.$store.getters["Invitation/signerDetails"];
            if (signerDetails) {
                this.middleName = signerDetails["middleName"];
                this.birthMonth = signerDetails["birthMonth"];
                this.birthDay = signerDetails["birthDay"];
                this.birthYear = signerDetails["birthYear"];
                this.city = signerDetails["city"];
                this.countryID = signerDetails["countryID"];
                this.stateID = signerDetails["stateID"];
                this.zipCode = signerDetails["zipCode"];
                this.addressLine1 = signerDetails["addressLine"];
            }



            let signerDetailsResponse = await ApiService.GenerateGetRequest("signer/details");
            console.log("Signer Details signerDetailsResponse", signerDetailsResponse);

            if (signerDetailsResponse["isSuccess"]) {
                let sd = signerDetailsResponse["content"];
                this.firstName = sd["firstName"];
                this.lastName = sd["lastName"];
                this.emailAddress = sd["emailAddress"];
                this.middleName = sd["middleName"];
                this.birthMonth = sd["dobMonth"];
                this.birthDay = sd["dobDay"];
                this.birthYear = sd["dobYear"];
                this.city = sd["city"];
                this.countryID = sd["countryID"];
                this.stateID = sd["stateID"];
                this.zipCode = sd["zipCode"];
                this.addressLine1 = sd["address"];
            }


            this.emitValidState();
        },
        computed: {
            yearRanges() {
                let arr = [];
                var year = new Date().getFullYear();
                for (let i = 1920; i < year; i++) {
                    arr.push(i);
                }
                return arr;
            },
            zipCodeContainerWidth() {
                return this.statesList.length === 0 ? 'col-md-12' : 'col-md-6'
            }
        },
        methods: {
            async isValid() {
                this.v$.$touch();
                let hasErrors = this.v$.$error;
                if (!hasErrors) {
                    let updateSuccessful = await this.updateInformation();
                    // show toast message
                    return updateSuccessful;
                }
                return false;// todo uncomment !hasErrors;
            },
            async updateInformation() {
                this.v$.$touch();
                let hasErrors = this.v$.$error;
                if (!hasErrors) {
                    // save to store
                    let obj = {
                        firstName: this.firstName,
                        middleName: this.middleName,
                        lastName: this.lastName,
                        birthMonth: this.birthMonth,
                        birthDay: this.birthDay,
                        birthYear: this.birthYear,
                        city: this.city,
                        countryID: this.countryID,
                        stateID: this.stateID,
                        zipCode: this.zipCode,
                        addressLine: this.addressLine1,
                        emailAddress: this.emailAddress,
                    };
                    this.$store.commit("Invitation/updatesignerDetails", obj);

                    // send to api too
                    obj = {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        middleName: this.middleName,
                        dobMonth: parseInt(this.birthMonth),
                        dobDay: parseInt(this.birthDay),
                        dobYear: parseInt(this.birthYear),
                        Address: this.addressLine1,
                        city: this.city,
                        countryId: parseInt(this.countryID),
                        stateId: parseInt(this.stateID),
                        zipCode: this.zipCode,
                    };

                    let response = await ApiService.GeneratePostRequest("verify/signer_details", obj);
                    console.log("Update response", response);
                    return response["isSuccess"];
                }

                return false;
            },
            emitValidState() {
                this.v$.$touch();
                let hasErrors = this.v$.$error;
                this.$emit("componentValid", !hasErrors);
            }
        },
        watch: {
            async countryID(value) {
                if (value !== null && value !== "") {
                    let response = await ApiService.GenerateGetRequest(`Country/${value}/states`);
                    if (response != null && response.length > 0) {
                        this.statesList = response;
                        if (!this.firstLoad)
                            this.stateID = "";
                        else
                            this.firstLoad = false;
                    }
                    else {
                        this.stateID = -1;
                        this.statesList = [];
                    }
                }
                this.emitValidState();
            },
            // watch these variables for changes and verify form is valid - if not valid emit
            firstName(value) { this.emitValidState() },
            middleName(value) { this.emitValidState() },
            lastName(value) { this.emitValidState() },
            birthMonth(value) { this.emitValidState() },
            birthDay(value) { this.emitValidState() },
            birthYear(value) { this.emitValidState() },
            city(value) { this.emitValidState() },
            stateID(value) { this.emitValidState() },
            zipCode(value) { this.emitValidState() },
            addressLine1(value) { this.emitValidState() },
            addressLine2(value) { this.emitValidState() },
            emailAddress(value) { this.emitValidState() },
        },
        validations() {
            return {
                firstName: { required },
                lastName: { required },
                birthMonth: { required },
                birthDay: { required },
                birthYear: { required },
                city: { required },
                countryID: { required },
                stateID: { stateValidator },
                zipCode: { required },
                addressLine1: { required },
                emailAddress: { required, email },
            }
        }
    }
</script>
