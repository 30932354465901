<template>
    <section id="opt-out-page">
        <div class="opt-out-page-main">
            <div class="opt-out-body">
                <h1>{{ firstName }} {{ lastName }}</h1>
                <p v-html="optout"></p>
            </div>
            <div class="confirm-block">
                <div class="go-back">
                    <router-link :to="prevRoutePath" style="color: white; text-decoration: none">Go Back</router-link>
                </div>
                <button class="confirm-opt-out" @click="confirmOptOut">Confirm
                    <!--<router-link to="/optoutfinal" style="color: white; text-decoration: none">Confirm</router-link>-->
                </button>
            </div>
        </div>
    </section>
</template>
<style scoped>

    .opt-out-body h1, .opt-out-body p {
        color: #858585;
    }

    .opt-out-page-main h1 {
        padding-top: 5%;
        font-size: 45px;
        text-align: center;
    }

    .opt-out-page-main p {
        font-size: 30px;
        text-align: center;
    }

    .go-back {
        background: #28a745;
        font-size: 20px;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        cursor: pointer;
    }

    .go-back:hover {
        background: linear-gradient(rgba(0, 153, 0, 1), rgba(0, 153, 0, 1));
        cursor: pointer;
    }

    .confirm-opt-out {
        background: #585f63;
        font-size: 20px;
        color: #fff;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        cursor: pointer;
        border-style: none;
    }

    .confirm-opt-out:hover {
        background: linear-gradient(rgba(80, 80, 80, 1), rgba(80, 80, 80, 1));
        cursor: pointer;
    }

    .confirm-block {
        display: flex;
        justify-content: space-evenly;
        padding-left: 25%;
        padding-right: 25%;
    }
</style>
<script>
    import ApiService from "../../core/services/api-service";
    import Swal from 'sweetalert2';
    export default {
        name: 'Optout',
        data() {
            this.readData();
            return {
                firstName: "",
                lastName: "",
                optout: [],
                lenderid: null,
                signerData: []
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from;
            });
        },
        computed: {
            prevRoutePath() { return this.prevRoute ? this.prevRoute.path : '/signer/details' },
        },
        methods: {
            async readData() {
                let signerData = this.$store.getters["Auth/signerSessionInformation"];
                let lenderid = this.$store.state.App.lenderId;                              
                return ApiService.GenerateGetRequest(`econsent/optout?lenderid=${lenderid}`).
                    then((response) => {
                        this.firstName = signerData["firstName"];
                        this.lastName = signerData["lastName"];
                        this.optout = response["content"];
                    });
            },
            confirmOptOut(){              
                this.signerData = this.$store.getters["Auth/signerSessionInformation"];
                var payload = {
                    "SignerId": this.signerData["signerId"],
                    "SignerName": this.signerData["firstName"] + " " + this.signerData["lastName"],
                    "SignerEmail": this.signerData["emailAddress"],
                    "PackageName": this.signerData["description"],
                    "NotarizeId": this.signerData["notarizeId"],
                    "RequesterId": this.signerData["requesterId"],
                    "Lender": this.signerData["lender"]
                };
                
                //Update all statuses to wetsign/opted out
                return ApiService.GeneratePostRequest(`package/update/wetsign/optout`).then(
                    (response) => {
                        if (response.isSuccess) {
                            this.$router.push("/optoutfinal");
                            //then update audit log
                            ApiService.GeneratePostRequest(`econsent/signerOptOut`);
                            
                            this.$store.commit("App/updateLoader", { showLoader: false });
                            Swal.fire("Opt Out Confirmed!", "", "success");

                            //finally send notifications
                            ApiService.GeneratePostRequest(`econsent/optout/sendLenderNotification`, payload);
                            ApiService.GeneratePostRequest(`econsent/optout/sendBorrowerNotification`, payload);                            
                            
                        } else {
                            this.$store.commit("App/updateLoader", { showLoader: false });
                            Swal.fire("Failed to Confirm Opt Out", response.message, "error");
                        }
                    })
            },

        },
    }
</script>