<template>

    <section id="screen-page">
        <div class="row">
            <div class="col-md-12">
                <div class="row personal-signup">
                    <div class="col-md-offset-2 col-md-3 col-xs-12  ">
                        <div class="bdr-rad-10 innerbox lightblue-bg">
                            <h2>Personal</h2>
                            <p>Need to Sign or Notarize Your Document?</p>
                            <img src="/images/icon-personal.png" alt="" class="img-responsive">
                        </div>
                    </div>
                    <div class="col-md-offset-1 col-md-4 col-xs-12">
                        <div class="bdr-rad-10 innerbox darkblue-bg">
                            <h2>Signup</h2>
                            <form action="/Account/SignUp" method="post">
                                <input name="__RequestVerificationToken" type="hidden" value="j0D48SxSTLmMGKe7R5tIReLrAMN1bEjwWFxqHLPwiijF5RBhaFHHJJujPs0mpwUK7qIZW0EO_znLR7njtIe8hhxpPvlBGlPTNja5hgZTEhY1" />                            <div class="col-md-12">
                                    <input data-val="true" data-val-required="Please enter Email." id="Email" name="Email" placeholder="Email" type="text" value="" />
                                    <span class="field-validation-valid text-danger" data-valmsg-for="Email" data-valmsg-replace="true"></span>

                                </div>
                                <div class="col-md-12">
                                    <input data-val="true" data-val-required="Please enter Password." id="Password" name="Password" placeholder="Password" type="password" />
                                    <span class="field-validation-valid text-danger" data-valmsg-for="Password" data-valmsg-replace="true"></span>
                                </div>
                                <div class="col-md-12">
                                    <input type="checkbox" id="chkAgree" />
                                    <label for="chkAgree" style="color: white !important; font-size: 12px">
                                        I agree to the <a style="color: white; text-decoration: underline;" href="/Home/eSignConsent" target="_blank">eSign Consent Disclosure</a> and have read and accepted E-Notary Seal <a style="color: white; text-decoration: underline;" href="/Home/TermsOfService" target="_blank">Terms of Service</a>
                                    </label>
                                </div>
                                <span class="field-validation-valid text-danger" data-valmsg-for="ErrorMsg" data-valmsg-replace="true"></span>                            <div class="col-md-12">
                                    <input type="submit" value="Create Account" onclick="return preSave()">
                                </div>
                            </form>                        <a style="color: white" class="stripe" href="/BusinessClientPackage/Package">View Business Client Pricing</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
    export default {
        name: 'SignIn',
    }
</script>