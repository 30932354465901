<template>
    <div class=" col-md-12 text-center">
        <div class="row">
            <div class="col-xs-2">
                <img src="/images/3.jpg" alt="" class="img-resonsive">
            </div>
            <div class="col-xs-7 text-left">
                <h3>Location</h3>
                <p id="locationText" style="margin-top:2rem">Your location is: {{setLocation}}<br></p>
            </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: 'TestLocation',
        data() {
            return {
                deviceInfos: [],
                cameraCount: 0,
                showVideo: false,
                setLocation: "",
            }
        },
        mounted() {
            /**
             * TODO - REMOVE TEST ACCOUNT
             * https://ipinfo.io/account/login
             * Carissa.Samaroo@evolvemortgageservices.com
             * Password12345
             **/
            fetch("https://ipinfo.io/json?token=a18d16dc910712")
                .then(response => response.json())
                .then(data => {
                    this.setLocation = data.region + ', ' + data.country;
                    console.log("setLocation", this.setLocation);
                    this.$emit("location", this.setLocation);
                });            
        },
        methods: { },
    }


</script>
<style scoped>
    .pids-wrapper {
        width: 100%;
    }

    .pid {
        width: calc(10% - 10px);
        height: 10px;
        display: inline-block;
        margin: 5px;
    }
</style>