<template>
    <header>
        <nav class="navbar navbar-default">
            <div class="col-md-offset-1 col-md-10">
                <div class="navbar-header">
                    <a class="navbar-brand" href="Home/Index"><img :src="Logo" alt=""></a>
                </div>
            </div>
        </nav>
    </header>
    <div class="container">
        <div class="col-md-12 loading-content" style="display:flex; justify-content:center;margin-bottom:20px;">
            <loading-card :hide="hide"  :percentage="percentage" :name="name" :documentNumber="documentNumber" :totalDocumentCount="totalDocumentCount" :isRon="isRon" :title="title" />
        </div>
        <dic class="col-md-12">
            <div class="contact-info" id="contact-info">
            </div>
        </dic>
    </div>
</template>
<script>
    import LoadingCard from "@/components/LoadingCard.vue";
    import ApiService from "@/core/services/api-service";

    export default {
        name: 'WaitingParticipants',
        props: ["mainBorrower", "totalDocumentCount", "isRon", "lenderSettings", "hide"],
        emits:['documentsLoaded'],
        data() {
            return {
                Logo: this.getLogo(),
                documentNumber: 0,
                name: 'Loading Document',
            };
        },
        components: {
            LoadingCard
        },
        async created() {
            //get lender settings
            let lenderid = this.$store.state.App.lenderId;
            console.log("lenderid ", lenderid);
            ApiService.GenerateGetRequest(`signer/fulllenderSettings?lenderid=${lenderid}`).then((response) => {
                    console.log("get settings ", response);
                    document.getElementById("contact-info").innerHTML = response.content.technicalSupportText == null ? "" : response.content.technicalSupportText;
                }
            );

        },
        computed: {
            percentage() {
                let percent = this.totalDocumentCount === 0 ? 0 : parseInt((this.documentNumber / this.totalDocumentCount) * 100);
                return percent;
            },
            title() {
                if ((this.isRon && this.mainBorrower) || !this.isRon)
                    return "Preparing Signing Room...";
                else if (this.isRon && !this.mainBorrower)
                    return "Waiting On Notary...";
                else
                    return "Waiting On Notary...";

                return "";
            }
        },
        methods: {
            getLogo() {
                var logo = this.$store.getters["App/getLogo"];
                if (logo == null)
                    logo = url.endPoint + '/api/admin/logo';
                else
                    logo = url.endPoint + '/api/admin/logo?name=' + logo;
                return logo;
            },
            updateNextDocCount() {
                this.documentNumber++;
                if (this.percentage >= 100) {
                    this.$emit('documentsLoaded');
                }
            },
            updateNextDocName(val) {
                if (val)
                    this.name = val;
               
            },
        }
    }
</script>



<style scoped>
    h2 {
        font-weight: bolder;
        font-size: 50px;
        text-align: center;
        margin-top: 150px;
    }

    p {
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .contact-info {
        margin-top: 60px;
    }

    h6 {
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
    }

    .logo {
        display: block;
        width: 260px;
        height: auto;
        margin: 200px auto 60px auto;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
        font-family: 'Lato', sans-serif;
        text-align: center;
    }

    .loading-content .card {
        width: 45%;
    }

	@media only screen and (max-width: 600px) {
		.loading-content .card {
			width: 100%;
		}

		.card {
			border-radius: 6px;
		}
	}
</style>
