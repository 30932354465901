<template>
  <div class="p-m">
    <div class="row k-pb-md"></div>
    <div class="row pb-m">
      <div class="col-lg-3 col-md-6">
        <div v-if="hasGeneralSettings">
          <button @click="save" class="k-button k-primary">
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="k-card k-card-vertical">
      <div class="k-card-header">
        <div class="col-lg-12">
          <h4>Document Review</h4>
        </div>
      </div>
      <div class="k-card-body">
        <div class="row k-pb-md">
          <div class="col-lg-3 col-md-6">
            <div>
              Review Type
            </div>
            <div style="vertical-align:bottom;">
              <dropdownlist
                :style="{ width: '230px' }"
                :disabled="!canEditDocumentReview"
                :data-items="reviewTypes"
                @change="handleChange"
                :value="reviewValue"
              >
              </dropdownlist>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              Signing Ink Color
            </div>
            <div style="vertical-align:bottom;">
              <dropdownlist
                :style="{ width: '230px' }"
                :data-items="fontColorItems"
                @change="fontColorChange"
                :value="fontColor"
                :disabled="!canEditDocumentReview"
              >
              </dropdownlist>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              Show e-Consent on Signing
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch
                :checked="signDateConsent"
                @change="signDateConsentChange"
                :disabled="!canEditDocumentReview"
              />
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              Default TimeZone
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <dropdownlist
                id="dropdownlist"
                :style="{ width: '230px' }"
                :data-items="timeZones"
                :data-item-key="'id'"
                :text-field="'timeZoneName'"
                :value="selectedTZ"
                @change="changeTimeZone"
                :disabled="!canEditDocumentReview"
              >
              </dropdownlist>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              Allow Wetsign
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch
                :checked="enableWetsign"
                @change="enableWetsignChange"
                :disabled="!canEditDocumentReview"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row k-pb-md"></div>
    <div class="k-card k-card-vertical">
      <div class="k-card-header">
        <div class="col-lg-12">
          <h4>Signing Room</h4>
        </div>
      </div>
      <div class="k-card-body">
        <div class="row k-pb-md">
          <div class="col-lg-1 col-md-6">
            <div>
              Draw
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch
                :checked="drawSignature"
                @change="drawSignatureChange"
                :disabled="!canEditSigningOptions"
              />
            </div>
          </div>
          <div class="col-lg-1 col-md-6">
            <div>
              Type
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch
                :checked="typeSignature"
                @change="typeSignatureChange"
                :disabled="!canEditSigningOptions"
              />
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div>
              Upload Signature
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch
                :checked="uploadSignature"
                @change="uploadSignatureChange"
                :disabled="!canEditSigningOptions"
              />
            </div>
          </div>
          <!--<div class="col-lg-2 col-md-6">
                        <div>
                            Skip Authorization
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="skipSigningRoomAuthorization" @change="skipSigningRoomAuthorizationChange" />
                            <p>Skip directly to signing room</p>
                        </div>
                    </div>-->

          <div class="col-lg-2 col-md-6">
            <div>
              Skip 2Factor
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch :checked="skip2Factor" @change="skip2FactoronChange" />
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div>
              Skip Confirm Signer Details
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch
                :checked="skipSignerDetails"
                @change="skipSignerDetailsChange"
              />
            </div>
          </div>

          <div class="col-lg-2 col-md-6">
            <div>
              Skip SSN
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch :checked="skipSSN" @change="skipSSNChange" />
            </div>
          </div>

          <div class="col-lg-2 col-md-6">
            <div>
              Tag Opacity <strong>{{ tagOpacity }}%</strong>
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <kendo-slider
                v-model="tagOpacity"
                :orientation="'horizontal'"
                :min="0"
                :max="100"
                :small-step="1"
                :large-step="10"
              >
              </kendo-slider>
              <br />
              <span
                class="text-center"
                style="display: inline-block; width: 100%;position:relative"
              >
                Can You See Me?
                <span
                  class="opacity-cover"
                  style="position: absolute;left: 0; width: 90%; height: 100%"
                ></span>
              </span>
            </div>
          </div>

          <div class="col-lg-1 col-md-6">
            <div>
              Auto Navigate
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch :checked="autoNavigate" @change="autoNavigateChange" />
            </div>
          </div>
          <div class="col-lg-1 col-md-6">
            <div>
              Express Signing
            </div>
            <div style="vertical-align:bottom;">
              <br />
              <k-switch
                :checked="isExpressSigning"
                @change="expressSigningChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row k-pb-md"></div>
    <div class="row k-pb-md"></div>
    <div class="k-card k-card-vertical">
      <div class="k-card-header">
        <div class="col-lg-1">
          <h4>Watermarks</h4>
        </div>
      </div>
      <div class="k-card-body">
        <div class="row k-pb-md"></div>
        <div class="row k-pb-md">
          <div class="col-lg-3 col-md-6">
            <div>
              eSign Document - View Only
            </div>
            <div style="vertical-align:bottom;">
              <k-input
                v-if="canEditWatermarks"
                :style="{ width: '230px' }"
                :size="small"
                v-model="eSignWatermark"
              ></k-input>
              <k-input
                v-else
                :style="{ width: '230px' }"
                :size="small"
                v-model="eSignWatermark"
                :disabled="true"
              ></k-input>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              Ink Sign Document
            </div>
            <div style="vertical-align:bottom;">
              <k-input
                v-if="canEditWatermarks"
                :style="{ width: '230px' }"
                v-model="wetSignWatermark"
              ></k-input>
              <k-input
                v-else
                :style="{ width: '230px' }"
                v-model="wetSignWatermark"
                :disabled="true"
              ></k-input>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div>
              Notarized Document
            </div>
            <div style="vertical-align:bottom;">
              <k-input
                v-if="canEditWatermarks"
                :style="{ width: '230px' }"
                v-model="notarizeWatermark"
              ></k-input>
              <k-input
                v-else
                :style="{ width: '230px' }"
                v-model="notarizeWatermark"
                :disabled="true"
              ></k-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row k-pb-md"></div>
    <div class="k-card k-card-vertical">
      <div class="k-card-header">
        <div class="col-lg-12">
          <h4>Text Settings</h4>
        </div>
      </div>
      <div class="k-card-body">
        <div class="row k-pb-md">
          <div class="col-lg-2 col-md-6">
            <div style="vertical-align:bottom;">
              <br />
              <k-button
                class="k-button k-primary"
                @click="eSignVerbiage"
                ref="button"
                :disabled="!canEditTextSettings"
              >
                Edit eConsent
              </k-button>
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div style="vertical-align:bottom;">
              <br />
              <k-button
                class="k-button k-primary"
                @click="eSignOptOutText"
                ref="button"
                :disabled="!canEditTextSettings"
              >
                Edit Opt-Out Text
              </k-button>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div style="vertical-align:bottom;">
              <br />
              <k-button
                class="k-button k-primary"
                @click="eSignOptOutConfirmText"
                ref="button"
                :disabled="!canEditTextSettings"
              >
                Edit Opt-Out Confirmation Text
              </k-button>
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div style="vertical-align:bottom;">
              <br />
              <k-button
                class="k-button k-primary"
                @click="eSignDeclineText"
                ref="button"
                :disabled="!canEditTextSettings"
              >
                Edit Decline Text
              </k-button>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div style="vertical-align:bottom;">
              <br />
              <k-button
                class="k-button k-primary"
                @click="eSignDeclineConfirmText"
                ref="button"
                :disabled="!canEditTextSettings"
              >
                Edit Decline Confirmation Text
              </k-button>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div style="vertical-align:bottom;">
              <br />
              <k-button
                class="k-button k-primary"
                @click="eSignTechnicalSupportText"
                ref="button"
                :disabled="!canEditTextSettings"
              >
                Edit Technical Support Text
              </k-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row k-pb-md"></div>
    <div class="k-card k-card-vertical">
      <div class="k-card-header">
        <div class="col-lg-12">
          <h4>Display Settings</h4>
        </div>
      </div>
      <div class="k-card-body">
        <div class="row k-pb-md">
          <div class="col-lg-6 col-md-6">
            <div>
              Current Logo
            </div>
            <div style="vertical-align:bottom;">
              <img :src="logoPath" alt="" style="width:100%" />
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div style="vertical-align:bottom;" v-if="canEditDisplaySettings">
              <br />
              <input
                type="file"
                id="logoInput"
                name="filename"
                accept="image/png, image/jpeg, image/jpg"
              />
              <k-button
                class="k-button k-primary"
                @click="uploadLogo"
                ref="button"
              >
                Upload Logo
              </k-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row k-pb-md"></div>
  </div>
  <div ref="modalSignatureRef" class="modal" style="display: none;">
    <input type="hidden" id="MS_Mode" value="Signature" />
    <div id="divDrawing">
      <link
        href="https://fonts.googleapis.com/css?family=Allura&amp;display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Dancing+Script&amp;display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Mansalva&amp;display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Satisfy&amp;display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Sacramento&amp;display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Vibes&amp;display=swap"
        rel="stylesheet"
      />

      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <ul class="nav nav-tabs">
              <li class="active">
                <a v-show="signingTypes.Draw" data-toggle="tab" href="#Draw"
                  >Draw</a
                >
              </li>
              <li>
                <a v-show="signingTypes.Type" data-toggle="tab" href="#Type"
                  >Type</a
                >
              </li>
              <li>
                <a v-show="signingTypes.Upload" data-toggle="tab" href="#Upload"
                  >Upload</a
                >
              </li>
            </ul>
            <div>
              <button type="button" class="close" data-dismiss="modal">
                x
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="tab-content">
              <div id="Draw" class="tab-pane fade" v-show="signingTypes.Draw">
                <div>
                  <div class="img-relative">
                    <canvas
                      ref="signatureCanvas"
                      style=" width: 360px; height: 160px;"
                    ></canvas>
                    <img
                      id="commentImg"
                      style="border: solid; display: none;"
                      height="360px"
                      width="160px"
                    />
                  </div>
                  <div class="modal-footer">
                    <div align="right">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click.prevent="erase"
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        style="background-color: #5cb85c; border-color: #4cae4c;"
                        class="btn btn-secondary"
                        @click.prevent="saveDrawSignature"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Type" class="tab-pane fade" v-show="signingTypes.Type">
                <input
                  type="text"
                  name="signature-text"
                  v-model="signatureText"
                  placeholder="Type here"
                  class="form-control"
                  :style="typeErrorList.length > 0 ? 'border-color:red' : ''"
                />
                <ul v-show="typeErrorList.length > 0">
                  <li class="errorLi" v-for="item in typeErrorList" :key="item">
                    {{ item }}
                  </li>
                </ul>
                <div class="font-sample-preview">
                  <div
                    v-for="(item, index) in fonts"
                    :key="index"
                    class="signature-view"
                    :class="{
                      active: activeSignatureFontFamily == item.fontFam,
                    }"
                    :style="{ color: lineColor }"
                    @click.prevent="activeSignatureFontFamily = item.fontFam"
                  >
                    <h1 :style="{ fontFamily: item.fontFam }">
                      {{ signatureText }}
                    </h1>
                    <!--<canvas width="250" height="80" v-insert-message="signatureText" :ref="item.fontFam" :id="item.fontFam" :style="{fontFamily: item.fontFam}"></canvas>-->
                  </div>
                </div>
                <div class="modal-footer">
                  <div align="right" class="pull-right">
                    <button
                      type="button"
                      class="btn btn-success"
                      @click.prevent="saveFontSignature"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div
                id="Upload"
                class="tab-pane fade"
                v-show="signingTypes.Upload"
              >
                <div class="bdr upload-files">
                  <img
                    v-if="signatureImageBase64 !== ''"
                    class="img-fluid img-responsive"
                    :src="signatureImageBase64"
                  />
                  <img
                    v-else
                    src="/images/upload-icon.png"
                    alt=""
                    class="img-fluid img-responsive"
                  />
                  <p>
                    <input
                      ref="uploadSignatureFile"
                      type="file"
                      class="custom-file-input"
                      accept="image/x-png,image/jpeg"
                      @change="convertToBase64($event)"
                    />
                  </p>
                </div>
                <div class="modal-footer">
                  <div align="right">
                    <button
                      type="button"
                      class="btn btn-success btn-block"
                      @click.prevent="saveImageSignature"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <popup
    :show="show"
    :popup-class="'popup-content'"
    :offset="offset"
    style="width:60vw;"
  >
    <div id="eConsent" style="padding:1em;">
      <div class="row pb-m">
        <div class="col-lg-6">
          <h4>Edit eConsent</h4>
        </div>
        <div class="col-lg-6" style="text-align:right;">
          <button
            @click="saveVerbiage"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Save
          </button>
          <button
            @click="close"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Cancel
          </button>
        </div>
      </div>
      <br />
      <div class="col-md-12">
        <editor
          ref="editor"
          :tools="tools"
          :content-style="{
            height: '490px',
          }"
          :default-content="verbiage"
        />
      </div>
    </div>
  </popup>
  <popup
    :show="showOptOutText"
    :popup-class="'popup-content'"
    :offset="offset"
    style="width:60vw;"
  >
    <div id="optOutText" style="padding:1em;">
      <div class="row pb-m">
        <div class="col-lg-6">
          <h4>Edit Opt-Out Text</h4>
        </div>
        <div class="col-lg-6" style="text-align:right;">
          <button
            @click="saveOptOutText"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Save
          </button>
          <button
            @click="closeOptOut"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Cancel
          </button>
        </div>
      </div>
      <br />
      <div class="col-md-12">
        <editor
          ref="editorOptOut"
          :tools="tools"
          :content-style="{
            height: '490px',
          }"
          :default-content="optOutText"
        />
      </div>
    </div>
  </popup>
  <popup
    :show="showOptOutConfirmText"
    :popup-class="'popup-content'"
    :offset="offset"
    style="width:60vw;"
  >
    <div id="optOutConfirmText" style="padding:1em;">
      <div class="row pb-m">
        <div class="col-lg-6">
          <h4>Edit Opt-Out Confirmation Text</h4>
        </div>
        <div class="col-lg-6" style="text-align:right;">
          <button
            @click="saveOptOutConfirmText"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Save
          </button>
          <button
            @click="closeOptOutConfirm"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Cancel
          </button>
        </div>
      </div>
      <br />
      <div class="col-md-12">
        <editor
          ref="editorOptOutConfirm"
          :tools="tools"
          :content-style="{
            height: '490px',
          }"
          :default-content="optOutConfirmText"
        />
      </div>
    </div>
  </popup>
  <popup
    :show="showDeclineText"
    :popup-class="'popup-content'"
    :offset="offset"
    style="width:60vw;"
  >
    <div id="declineText" style="padding:1em;">
      <div class="row pb-m">
        <div class="col-lg-6">
          <h4>Edit Decline Text</h4>
        </div>
        <div class="col-lg-6" style="text-align:right;">
          <button
            @click="saveDeclineText"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Save
          </button>
          <button
            @click="closeDecline"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Cancel
          </button>
        </div>
      </div>
      <br />
      <div class="col-md-12">
        <editor
          ref="editorDecline"
          :tools="tools"
          :content-style="{
            height: '490px',
          }"
          :default-content="declineText"
        />
      </div>
    </div>
  </popup>
  <popup
    :show="showDeclineConfirmText"
    :popup-class="'popup-content'"
    :offset="offset"
    style="width:60vw;"
  >
    <div id="declineConfirmText" style="padding:1em;">
      <div class="row pb-m">
        <div class="col-lg-6">
          <h4>Edit Decline Confirmation Text</h4>
        </div>
        <div class="col-lg-6" style="text-align:right;">
          <button
            @click="saveDeclineConfirmText"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Save
          </button>
          <button
            @click="closeDeclineConfirm"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Cancel
          </button>
        </div>
      </div>
      <br />
      <div class="col-md-12">
        <editor
          ref="editorDeclineConfirm"
          :tools="tools"
          :content-style="{
            height: '490px',
          }"
          :default-content="declineConfirmText"
        />
      </div>
    </div>
  </popup>
  <popup
    :show="showTechnicalSupportText"
    :popup-class="'popup-content'"
    :offset="offset"
    style="width:60vw;"
  >
    <div id="technicalSupportText" style="padding:1em;">
      <div class="row pb-m">
        <div class="col-lg-6">
          <h4>Edit Technical Support Text</h4>
        </div>
        <div class="col-lg-6" style="text-align:right;">
          <button
            @click="saveTechnicalSupportText"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Save
          </button>
          <button
            @click="closeTechnicalSupportText"
            class="k-button k-primary"
            style="margin-right:1em;"
          >
            Cancel
          </button>
        </div>
      </div>
      <br />
      <div class="col-md-12">
        <editor
          ref="editorTechnicalSupportText"
          :tools="tools"
          :content-style="{
            height: '490px',
          }"
          :default-content="technicalSupportText"
        />
      </div>
    </div>
  </popup>
</template>

<script>
import moment from "moment";
import ApiService from "@/core/services/api-service";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Button } from "@progress/kendo-vue-buttons";
import { Editor } from "@progress/kendo-vue-editor";
import { Input } from "@progress/kendo-vue-inputs";
import { Popup } from "@progress/kendo-vue-popup";
import Swal from "sweetalert2";
import { Switch, Slider } from "@progress/kendo-inputs-vue-wrapper";
import PermissionHelper from "../core/permission-helper";

const PERMISSION_PREFIX = "GeneralSettings_";

const GENERAL_SETTINGS = {
  DOC_REVIEW: "CanEditDocumentReview",
  SIGNING: "CanEditSigningOptions",
  WATERMARKS: "CanEditWatermarks",
  TEXT: "CanEditTextSettings",
  DISPLAY: "CanEditDisplaySettings",
};

export default {
  name: "GeneralSettings",
  computed: {
    moment: () => moment,
    canEditDocumentReview: function() {
      return this.permissions[GENERAL_SETTINGS.DOC_REVIEW];
    },
    canEditSigningOptions: function() {
      return this.permissions[GENERAL_SETTINGS.SIGNING];
    },
    canEditWatermarks: function() {
      return this.permissions[GENERAL_SETTINGS.WATERMARKS];
    },
    canEditTextSettings: function() {
      return this.permissions[GENERAL_SETTINGS.TEXT];
    },
    canEditDisplaySettings: function() {
      return this.permissions[GENERAL_SETTINGS.DISPLAY];
    },
    hasGeneralSettings: function() {
      return (
        this.canEditDisplaySettings |
        this.canEditSigningOptions |
        this.canEditWatermarks |
        this.canEditTextSettings |
        this.canEditDisplaySettings
      );
    },
  },
  components: {
    dropdownlist: DropDownList,
    "k-switch": Switch,
    "k-input": Input,
    "k-button": Button,
    popup: Popup,
    editor: Editor,
    "kendo-slider": Slider,
  },
  data: function() {
    return {
      reviewTypes: ["Continuous Scroll", "Non-Continuous Scroll"],
      reviewValue: "",
      wetSignWatermark: "Wetsign View Only",
      eSignWatermark: "View Only until Signing Date",
      notarizeWatermark: "Signed",
      verbiage: "",
      optOutText: "",
      optOutConfirmText: "",
      declineText: "",
      declineConfirmText: "",
      show: false,
      showOptOutText: false,
      showOptOutConfirmText: false,
      showDeclineText: false,
      showDeclineConfirmText: false,
      signDateConsent: false,
      enableWetsign: false,
      drawSignature: true,
      typeSignature: true,
      uploadSignature: true,
      selectedTZ: "",
      tagOpacity: 80,
      opacityColor: "rgba(255, 255, 0, .80)",
      autoNavigate: false,
      fontColorItems: ["Blue", "Red", "Black"],
      fontColor: "",
      logoName: "",
      logoPath: url.endPoint + "/api/admin/logo",
      skipSigningRoomAuthorization: false,
      skip2Factor: false,
      skipSSN: false,
      skipSignerDetails: false,
      lineColor: "pink",
      Logo: url.endPoint + "/api/admin/logo",
      //Signature: url.endPoint + '/api/admin/signature',
      signatureType: "font", // Canvas / Font / Image
      signatureText: "Insert Signature",
      activeSignatureFontFamily: "",
      signatureCanvasContext: null,
      signatureImageBase64: "",
      fonts: [
        { fontFam: "Allura" },
        { fontFam: "Dancing Script" },
        { fontFam: "Sacramento" },
        //{ fontFam: "Mansalva" },
        //{ fontFam: "Satisfy" },
        { fontFam: "Vibes" },
      ],
      signingTypes: {
        Draw: true,
        Type: true,
        Upload: true,
      },
      typeErrorList: [],
      tools: [
        ["Bold", "Italic", "Underline", "Strikethrough"],
        ["Subscript", "Superscript"],
        ["AlignLeft", "AlignCenter", "AlignRight", "AlignJustify"],
        ["Indent", "Outdent"],
        ["OrderedList", "UnorderedList"],
        "FontSize",
        "FontName",
        "FormatBlock",
        ["Undo", "Redo"],
        ["Link", "Unlink", "InsertImage", "ViewHtml"],
        ["InsertTable"],
        ["AddRowBefore", "AddRowAfter", "AddColumnBefore", "AddColumnAfter"],
        ["DeleteRow", "DeleteColumn", "DeleteTable"],
        ["MergeCells", "SplitCell"],
      ],
      offset: { left: 335, top: 108 },
      timeZones: [],
      lenderid: 30,
      permissions: {},
      fastSignFont: null,
      showTechnicalSupportText: false,
      technicalSupportText: "",
      isExpressSigning: false,
    };
  },
  async created() {
    this.permissions = PermissionHelper.GetPermissions(PERMISSION_PREFIX);
  },
  async mounted() {
    //default
    this.reviewValue = "Continuous Scroll";
    this.fontColor = "Blue";

    //timezone ddl
    this.loadTimeZones();

    //load settings
    await ApiService.GenerateGetRequest("Admin/LenderSettings").then(
      (response) => {
        console.log("get settings ", response);
        this.reviewValue = response.content.docReviewType;
        this.verbiage = response.content.eConsentText;
        this.optOutText = response.content.optOutText;
        this.optOutConfirmText = response.content.optOutConfirmText;
        this.declineText = response.content.declineText;
        this.declineConfirmText = response.content.declineConfirmText;
        this.eSignWatermark = response.content.eSignDocWatermark;
        this.wetSignWatermark = response.content.wetSignWatermark;
        this.notarizeWatermark = response.content.notarizeWatermark;
        this.signDateConsent = response.content.showEConsentonSign;
        this.fontColor = response.content.fontColor;
        this.drawSignature = response.content.drawSignature;
        this.typeSignature = response.content.typeSignature;
        this.uploadSignature = response.content.uploadSignature;
        let lenderid = this.$store.state.App.lenderId;
        this.enableWetsign = response.content.enableWetsign;
        this.tagOpacity = response.content.tagOpacity;
        this.opacityColor = `rgba(255, 255, 0, ${this.tagOpacity / 100})`;
        this.autoNavigate = response.content.autoNavigate;
        // signature
        this.lineColor = response.content.fontColor;
        this.signingTypes.Draw = response.content.drawSignature;
        this.signingTypes.Type = response.content.typeSignature;
        this.signingTypes.Upload = response.content.uploadSignature;
        this.technicalSupportText =
          response.content.technicalSupportText == null
            ? ""
            : response.content.technicalSupportText;

        for (const [key, value] of Object.entries(this.signingTypes)) {
          if (value == true) {
            $("#" + key).addClass("active in");
            break;
          }
        }
        this.skipSSN = response.content.skipSSN;
        this.skipSignerDetails = response.content.skipSignerDetails;
        this.skipSigningRoomAuthorization =
          response.content.skipSigningRoomAuthorization;
        this.skip2Factor = response.content.skip2Factor;
        if (response.content.logo != null) {
          this.logoPath =
            url.endPoint + "/api/admin/logo?name=" + response.content.logo;
          this.logoName = response.content.logo;
        }
        this.fastSignFont = response.content.fastSignFont;
        this.isExpressSigning =
          response.content.isExpressSigning != null
            ? response.content.isExpressSigning
            : false;

        //get timezone details
        ApiService.GenerateGetRequest(
          "Package/timezone/" + response.content.timeZone
        ).then((response) => {
          this.selectedTZ =
            response.content != null
              ? response.content
              : this.timeZones.find((x) => x.id == 7);
        });

        this.$nextTick(() => {
          // signattureconsole.log(signatureCanvas, "canvas");
          let signatureCanvas = this.$refs.signatureCanvas;
          console.log(signatureCanvas, "canvas");
          let currentMousePosition = {
            x: 0,
            y: 0,
          };

          //previous mouse position
          let previousMousePosition = {
            x: 0,
            y: 0,
          };

          //make a 2D context
          this.signatureCanvasContext = signatureCanvas.getContext("2d");

          //set the line parameters
          this.signatureCanvasContext.lineWidth = 3;
          this.signatureCanvasContext.lineJoin = "round";
          this.signatureCanvasContext.lineCap = "round";
          this.signatureCanvasContext.strokeStyle = this.lineColor;

          //capture mouse moves
          this.$refs.signatureCanvas.addEventListener("mousemove", function(e) {
            //store the old current mouse position and the previous mouse position
            previousMousePosition.x = currentMousePosition.x;
            previousMousePosition.y = currentMousePosition.y;

            var rect = signatureCanvas.getBoundingClientRect();
            //get the new mouse position
            currentMousePosition.x = e.clientX - rect.left;
            currentMousePosition.y = e.clientY - rect.top;
          });

          //mouse down
          signatureCanvas.addEventListener("mousedown", function(e) {
            //add an additional listener to draw
            signatureCanvas.addEventListener("mousemove", onPaint);
          });

          //mouse up
          signatureCanvas.addEventListener("mouseup", function() {
            //remove the additional mouse move listener
            signatureCanvas.removeEventListener("mousemove", onPaint);
          });

          //an additional mouse listener to paint part of a line
          var onPaint = () => {
            //draw the line
            this.signatureCanvasContext.beginPath();
            this.signatureCanvasContext.moveTo(
              previousMousePosition.x,
              previousMousePosition.y
            );
            this.signatureCanvasContext.lineTo(
              currentMousePosition.x,
              currentMousePosition.y
            );
            this.signatureCanvasContext.strokeStyle = this.lineColor;
            this.signatureCanvasContext.closePath();
            this.signatureCanvasContext.stroke();
          };
        });
      }
    );
  },
  watch: {
    tagOpacity: function(newVal, oldVal) {
      if (newVal !== oldVal)
        this.opacityColor = `rgba(255, 255, 0, ${newVal / 100})`;
    },
  },
  methods: {
    handleChange(event) {
      this.reviewValue = event.value;
    },
    expressSigningChange(event) {
      this.isExpressSigning = event.checked;
    },
    autoNavigateChange(event) {
      this.autoNavigate = event.checked;
    },
    fontColorChange(event) {
      this.fontColor = event.value;
    },
    signDateConsentChange(e) {
      this.signDateConsent = !this.signDateConsent;
    },
    enableWetsignChange(e) {
      this.enableWetsign = !this.enableWetsign;
    },
    drawSignatureChange(e) {
      this.drawSignature = !this.drawSignature;
    },
    typeSignatureChange(e) {
      this.typeSignature = !this.typeSignature;
    },
    uploadSignatureChange(e) {
      this.uploadSignature = !this.uploadSignature;
    },
    showSignModal() {
      this.erase();
      $(this.$refs.modalSignatureRef).modal("show");
    },
    erase() {
      this.signatureImageBase64 = "";
      if (this.signatureCanvasContext)
        this.signatureCanvasContext.clearRect(0, 0, 360, 160);

      let ctx = this.$refs.signatureCanvas.getContext("2d");
      ctx.canvas.height = 160;
      ctx.canvas.width = 360;
    },
    cropImageFromCanvas(context) {
      let ctx = context || this.$refs.signatureCanvas.getContext("2d");
      var canvas = ctx.canvas,
        w = canvas.width,
        h = canvas.height,
        pix = { x: [], y: [] },
        imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
        x,
        y,
        index;

      for (y = 0; y < h; y++) {
        for (x = 0; x < w; x++) {
          index = (y * w + x) * 4;
          if (imageData.data[index + 3] > 0) {
            pix.x.push(x);
            pix.y.push(y);
          }
        }
      }
      pix.x.sort(function(a, b) {
        return a - b;
      });
      pix.y.sort(function(a, b) {
        return a - b;
      });
      var n = pix.x.length - 1;

      w = 1 + pix.x[n] - pix.x[0];
      h = 1 + pix.y[n] - pix.y[0];
      var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

      canvas.width = w;
      canvas.height = h;
      ctx.putImageData(cut, 0, 0);

      var image = canvas.toDataURL(); //open cropped image in a new window
      return image;
    },
    async saveDrawSignature() {
      let img = this.cropImageFromCanvas(); // this.$refs.signatureCanvas.toDataURL();

      // replace input with image
      this.setImageInPdf(img);

      let results = await ApiService.GeneratePostRequest(
        "signer/signature/upload",
        {
          subType: this.signatureTypeId,
          imageData: img.split(",")[1],
        }
      );
      if (results.isSuccess && results.content) {
        const imgPath = results.content + "?timestamp=" + new Date().getTime();
        let control = this.pdfForm.allFormFields[
          this.setControlIndexForDrawSignature
        ];
        $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut(
          "slow"
        );
        console.log(
          "TAG TO HIDE",
          `#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`
        );
        this.updateControlData(
          this.pdfForm.allFormFields[this.setControlIndexForDrawSignature],
          imgPath
        );
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 7000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Signature saved",
        });
      }
      this.allDocumentsSignedAndReviewed();
      $(this.$refs.modalSignatureRef).modal("hide");
      this.erase();
    },
    async saveFontSignature() {
      let errorList = [];

      if (this.activeSignatureFontFamily == "") {
        Swal.fire({
          title: "Please select a font",
          text: "",
          icon: "info",
          confirmButtonText: "Ok",
        });
        return;
      }

      if (this.signatureText.length < 2) {
        errorList.push("Signature length cannot be less than 2 characters");
      }
      if (this.signatureText.length > 50) {
        errorList.push("Signature length cannot be greater than 50 characters");
      }
      const regex = new RegExp(/^[A-zÀ-ÿ][-,A-z. ']+$/);
      console.log(this.signatureText);
      console.log(regex.test(this.signatureText));
      if (regex.test(this.signatureText) == false) {
        errorList.push("Signature contains invalid characters");
      }
      if (errorList.length > 0) {
        this.typeErrorList = [...errorList];
        return;
      } else {
        this.typeErrorList = [];
      }

      this.signatureType = "canvas";

      var canvas = document.createElement("canvas");
      canvas.width = 250;
      canvas.height = 80;
      canvas.style.height = 200;
      var ctx = canvas.getContext("2d");

      ctx.font = "32px " + this.activeSignatureFontFamily;
      var text = this.signatureText;
      ctx.fillStyle = this.lineColor;
      ctx.textAlign = "center";
      ctx.fillText(text, 125, 40);

      let img = this.cropImageFromCanvas(ctx); //canvas.toDataURL();
      this.setImageInPdf(img);

      let results = await ApiService.GeneratePostRequest(
        "signer/signature/upload",
        {
          subType: this.signatureTypeId,
          imageData: img.split(",")[1],
        }
      );
      if (results.isSuccess && results.content) {
        const imgPath = results.content + "?timestamp=" + new Date().getTime();
        let control = this.pdfForm.allFormFields[
          this.setControlIndexForDrawSignature
        ];
        $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut(
          "slow"
        );
        this.updateControlData(
          this.pdfForm.allFormFields[this.setControlIndexForDrawSignature],
          imgPath
        );
      }
      this.allDocumentsSignedAndReviewed();
      $(this.$refs.modalSignatureRef).modal("hide");
      this.erase();
    },
    async saveImageSignature() {
      let results = await ApiService.GeneratePostRequest(
        "signer/signature/upload",
        {
          subType: this.signatureTypeId,
          imageData: this.signatureImageBase64.split(",")[1],
        }
      );
      if (results.isSuccess && results.content) {
        const imgPath = results.content + "?timestamp=" + new Date().getTime();
        // replace input with image
        this.setImageInPdf(this.apiUrl + imgPath);

        let control = this.pdfForm.allFormFields[
          this.setControlIndexForDrawSignature
        ];
        $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut(
          "slow"
        );
        this.updateControlData(
          this.pdfForm.allFormFields[this.setControlIndexForDrawSignature],
          imgPath
        );
      }
      this.allDocumentsSignedAndReviewed();
      $(this.$refs.modalSignatureRef).modal("hide");
      this.erase();
    },
    convertToBase64(event) {
      const [file] = event.target.files;
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.signatureImageBase64 = reader.result;
        };
      }
    },
    //skipSigningRoomAuthorizationChange(e) {
    //    this.skipSigningRoomAuthorization = !this.skipSigningRoomAuthorization;
    //},

    skip2FactoronChange(e) {
      this.skip2Factor = !this.skip2Factor;
    },
    skipSignerDetailsChange(e) {
      this.skipSignerDetails = !this.skipSignerDetails;
    },
    skipSSNChange(e) {
      this.skipSSN = !this.skipSSN;
    },
    save(e) {
      console.log("autoNavigate", this.autoNavigate);
      console.log("autoNavigate", this);
      return ApiService.GeneratePostRequest("Admin/UpdateLenderSettings", {
        DocReviewType: this.reviewValue,
        fontColor: this.fontColor,
        showEConsentonSign: this.signDateConsent,
        eConsentText: this.verbiage,
        optOutText: this.optOutText,
        optOutConfirmText: this.optOutConfirmText,
        declineText: this.declineText,
        declineConfirmText: this.declineConfirmText,
        eSignDocWatermark: this.eSignWatermark,
        wetSignWatermark: this.wetSignWatermark,
        notarizeWatermark: this.notarizeWatermark,
        timeZone: this.selectedTZ.id,
        drawSignature: this.drawSignature,
        typeSignature: this.typeSignature,
        Logo: this.logoName,
        uploadSignature: this.uploadSignature,
        skipSigningRoomAuthorization: this.skipSigningRoomAuthorization,
        skip2Factor: this.skip2Factor,
        skipSSN: this.skipSSN,
        skipSignerDetails: this.skipSignerDetails,
        enableWetsign: this.enableWetsign,
        tagOpacity: parseInt(this.tagOpacity),
        autoNavigate: this.autoNavigate,
        fastSignFont: this.fastSignFont,
        technicalSupportText: this.technicalSupportText,
        isExpressSigning: this.isExpressSigning,
      }).then((response) => {
        if (response["isSuccess"]) {
          Swal.fire("Success", " Settings updated sucessfully", "success");
        } else {
          Swal.fire(
            "Operation failed",
            "Settings could not be updated",
            "error"
          );
        }
      });
    },
    eSignVerbiage() {
      this.show = true;
    },
    saveVerbiage(e) {
      this.verbiage = this.$refs.editor.getHTML();
      this.show = !this.show;
      this.save(e);
    },
    eSignOptOutText() {
      this.showOptOutText = true;
    },
    saveOptOutText() {
      this.optOutText = this.$refs.editorOptOut.getHTML();
      this.showOptOutText = !this.showOptOutText;
    },
    eSignOptOutConfirmText() {
      this.showOptOutConfirmText = true;
    },
    saveOptOutConfirmText(e) {
      this.optOutConfirmText = this.$refs.editorOptOutConfirm.getHTML();
      this.showOptOutConfirmText = !this.showOptOutConfirmText;
      this.save(e);
    },
    eSignDeclineText() {
      this.showDeclineText = true;
    },
    saveDeclineText(e) {
      this.declineText = this.$refs.editorDecline.getHTML();
      this.showDeclineText = !this.showDeclineText;
      this.save(e);
    },
    eSignDeclineConfirmText() {
      this.showDeclineConfirmText = true;
    },
    eSignTechnicalSupportText() {
      this.showTechnicalSupportText = true;
    },
    saveDeclineConfirmText(e) {
      this.declineConfirmText = this.$refs.editorDeclineConfirm.getHTML();
      this.showDeclineConfirmText = !this.showDeclineConfirmText;
      this.save(e);
    },
    saveTechnicalSupportText(e) {
      this.technicalSupportText = this.$refs.editorTechnicalSupportText.getHTML();
      this.showTechnicalSupportText = !this.showTechnicalSupportText;
      this.save(e);
    },
    close() {
      this.show = !this.show;
    },
    closeOptOut() {
      this.showOptOutText = !this.showOptOutText;
    },
    closeOptOutConfirm() {
      this.showOptOutConfirmText = !this.showOptOutConfirmText;
    },
    closeDecline() {
      this.showDeclineText = !this.showDeclineText;
    },
    closeDeclineConfirm() {
      this.showDeclineConfirmText = !this.showDeclineConfirmText;
    },
    closeTechnicalSupportText() {
      this.showTechnicalSupportText = !this.showTechnicalSupportText;
    },
    loadTimeZones: function() {
      return ApiService.GeneratePostRequest("Package/timezones").then(
        (response) => {
          this.timeZones = response.content;
        }
      );
    },
    changeTimeZone(event) {
      this.selectedTZ = event.value;
      console.log("event changed", event.value);
    },
    uploadLogo() {
      // for some odd reason kendo placing form data in array investigate -
      let file = $("#logoInput")[0].files[0];
      if (file === undefined) return;

      const ext = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
      if (ext != "png" && ext != "jpg" && ext != "jpeg") {
        Swal.fire(
          "Operation failed",
          "File can only be png, jpg or jpeg",
          "error"
        );
      } else {
        let formData = new FormData();
        formData.append("File", file);
        formData.append("FileName", file.name);
        return ApiService.GeneratePostRequest("admin/logo/upload", formData, {
          "Content-Type": "multipart/form-data",
        }).then((response) => {
          if (response["isSuccess"]) {
            this.$store.commit("App/updateLogo", response.content.logo);
            this.$emit("updateLogo");
            this.logoName = response.content.logo;
            this.logoPath =
              url.endPoint + "/api/admin/logo?name=" + response.content.logo;
            Swal.fire("Success", " Settings updated sucessfully", "success");
          } else {
            Swal.fire(
              "Operation failed",
              "Settings could not be updated",
              "error"
            );
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.opacity-cover {
  background: v-bind(opacityColor);
}
</style>
