<template>
    <div class="p-m">
        <div class="row pb-m">
            <div class="col-md-2 col-sm-4">
                <div>
                    Added By
                </div>
                <div>
                    <b>{{ summary.lenderName }}</b>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div>
                    Date Added
                </div>
                <div>
                    <b>
                        {{
            summary.dateAdded
              ? moment(summary.dateAdded).format("MM/DD/YYYY h:mm A")
              : "N/A"
                        }}
                    </b>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div>
                    Date Modified
                </div>
                <div>
                    <b>
                        {{
            summary.dateModified
              ? moment(summary.dateModified).format("MM/DD/YYYY h:mm A")
              : "N/A"
                        }}
                    </b>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div>
                    Status
                </div>
                <div v-if="summary.status"
                     :class="'status ' + this.summary.status.toLowerCase()">
                    <span>{{ this.normalizeStatus(summary.status) }}</span>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div>
                    Document Type
                </div>
                <div>
                    <b>{{ summary.documentType }}</b>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2 col-sm-4">
                <div>
                    Signing Date
                </div>
                <div>
                    <b>
                        {{
            summary.signingDate
              ? moment(summary.signingDate).format("MM/DD/YYYY h:mm A")
              : "N/A"
                        }}
                    </b>
                </div>
            </div>            
            <div v-if="allowWetsign" class="col-md-2 col-sm-4">
                <div>
                    Wet Signed
                </div>
                <div>
                    <k-switch @change="onSwitchChangeWetSign" v-model="summary.wetSign" :disabled="!canEditWetSign" />
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div>
                    Review Required
                </div>
                <div>
                    <k-switch @change="onSwitchChangeReviewRequired" v-model="summary.reviewRequired" :disabled="!canEdit"/>
                </div>
            </div>
            <div class="col-md-2 col-sm-4">
                <div>
                    Available for Pre-Sign
                </div>
                <div>
                    <k-switch @change="onSwitchChangeAvailableForPresign" v-model="summary.availableForPreSign" :disabled="!canEditPreSign"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import GridDataHelper from "../../core/services/grid-data-helper";
    import { Switch } from "@progress/kendo-inputs-vue-wrapper";
    import PermissionHelper from "../../core/permission-helper";
    import ApiService from "../../core/services/api-service";

    const CAN_EDIT_PERM = "Loan_Document_CanEditDocumentDetail";

    export default {       
        name: "PackageDocumentSummary",
        async created() {           
            var perm = PermissionHelper.GetPermission(CAN_EDIT_PERM);
            if (perm[CAN_EDIT_PERM] !== undefined) this._canEdit = perm[CAN_EDIT_PERM];
            var settings = await ApiService.GenerateGetRequest("Admin/LenderSettings");
            if (settings != null && settings.isSuccess) {                
                this._allowWetsign = settings.content.enableWetsign;
            }
            var role = this.$store.state.Admin.loginInformation.role;
            this._isNotary = (role == "Notary") ? true : false;
        },
        components: {
            "k-switch": Switch,
        },
        props: ["summary"],
        data() { return { _canEdit: false, _allowWetsign: false, _isNotary:false } },
        computed: {
            moment: () => moment,
            canEdit: function () { return this._canEdit; },
            canEditWetSign: function () {                
                if (this._canEdit) {
                    if (this.summary.availableForPreSign
                        || this.summary.isEnote
                        || this.summary.loanPackageStatus != "In_Progress"
                        || (!this.summary.status.includes("Ready") &&
                            this.summary.status != "Uploaded" &&
                            this.summary.status != "Reviewed" &&
                            this.summary.status != "Wet_Sign"))
                    return false;
                    else return true;
                }
                return false;
            },
            canEditPreSign: function () {                
                if (this._canEdit) {
                    if (this.summary.wetSign
                        || this.summary.isEnote
                        || this.summary.loanPackageStatus != "In_Progress"
                        || (!this.summary.status.includes("Ready") &&
                            this.summary.status != "Uploaded" &&
                            this.summary.status != "Reviewed" &&
                            this.summary.status != "Wet_Sign"))
                        return false;
                    else return true;
                }
                return false;
            },
            allowWetsign: function () { return this._isNotary ? true : this._allowWetsign }
        },       
        methods: {           
            normalizeStatus(status) {
                return GridDataHelper.normalizeStatus(status);
            },
            onSwitchChangeWetSign(value) {              
                if (!this.canEdit) return;
                this.$emit("save", {
                    wetSign: value.checked,
                    reviewRequired: this.summary.reviewRequired,
                    availableForPreSign: this.summary.availableForPreSign
                });
            },
            onSwitchChangeReviewRequired(value) {
                if (!this.canEdit) return;
                this.$emit("save", {
                    wetSign: this.summary.wetSign,
                    reviewRequired: value.checked,
                    availableForPreSign: this.summary.availableForPreSign
                });
            },
            onSwitchChangeAvailableForPresign(value) {
                if (!this.canEdit) return;
                this.$emit("save", {
                    wetSign: this.summary.wetSign,
                    reviewRequired: this.summary.reviewRequired,
                    availableForPreSign: value.checked,
                });
            }
        },
    };
</script>

<style scoped>
    .p-m {
        padding: 16px;
    }

    .pb-m {
        padding-bottom: 16px;
    }

    .status /deep/ span {
        display: block;
        padding: 5px 0 5px 0;
        text-align: center;
        color: white;
        font-weight: 900;
    }

    .status.ready_for_review /deep/ span, .status.ready_for_signing /deep/ span {
        background: #4cb64c;
    }

    .status.default /deep/ span{
        background: #f3a638;
    }
    
    .status.uploaded /deep/ span {
        background: #33b3ab;
    }

    .status.reviewed /deep/ span,
    .status.signed /deep/ span,
    .status.notarized /deep/ span {
        background: #54b7d3;
    }

    .status.archived /deep/ span {
        background: #e3d4d4;
    }

    .status.cancelled /deep/ span,
    .status.wet_sign /deep/ span {
        background: slategray;
    }
</style>
