<template>
    <div class="container mt-20">
        <div class="col-sm-12 col-md-6 col-md-offset-3 darkblue-bg">
            <div v-if="invitationValid">
                <h2 class="text-center" style="color: #ffffff;">Verify it's you</h2>
                <p class="text-center" style="color: #ffffff !important;">Hello <b>{{ borrowerName }}</b>, Please complete the CAPTCHA below so we can send you a SMS verification code</p>
                <recaptcha @set2FAVisible="setCaptchaComplete"></recaptcha>
                <two-factor-verification :inviteToken="inviteToken"
                                         v-if="captchaComplete"
                                         @updateStore="updateStore" />
            </div>
            <div v-else>
                <div v-html="errorText"></div>
            </div>
        </div>
    </div>
</template>


<style scoped>
    .darkblue-bg {
        background-color: #4d97ac;
        padding: 100px;
        color: #FFFFFF;
    }

	.darkblue-bg p {
		font-size: 16px;
        color: #ffffff !important;
	}

    @media screen and (max-width: 1600px) {
        .darkblue-bg {
            background-color: #4d97ac;
            padding: 50px 30px;
            color: #FFFFFF;
        }
    }
</style>
<script>
    import ApiService from "@/core/services/api-service";
    import TwoFactorVerification from "@/components/TwoFactorVerification";
    import recaptcha from "@/components/reCaptcha";

    export default {
        name: "AuthenticateIdentityToken",
        components: {
            TwoFactorVerification,
            recaptcha,
        },
        data() {
            return {
                ssn: "",
                serverMsg: "",
                inviteToken: null,
                isLoggedIn: false,
                borrowerName: "",
                captchaComplete: false,
                invitationValid: false,
                isDateSignable: false,
                invitationText: "Loading invitation",
                Logo: '/images/smart-esign-logo.svg',
                errorText: ""
            };
        },
        async created() {
            this.$store.commit("App/updateLoaderOverride", { showLoaderOverride: false });

            // check if old invite token exists
            let oldInviteToken = this.$store.getters["Auth/invite"];
            let { t: token } = this.$route.params;
            this.inviteToken = token;

            // reset app state if new invite token.
            if (oldInviteToken !== token) {
                this.$store.commit("Auth/resetAuthState");
                this.$store.commit("Invitation/resetInvitationState");
                this.$store.commit("App/resetAppState");

                this.$store.commit("Auth/updateInviteToken", { invite: token });
            }

            var signingDetailsResponse = await ApiService.GenerateGetRequest(
                `signer/invite?identitytoken=${this.inviteToken}`
            );
            if (signingDetailsResponse.isSuccess == true) {
                this.invitationValid = true;
                this.$store.commit("App/updateLenderId", { LenderID: signingDetailsResponse["content"]["lenderID"] });
                this.$store.commit("App/updateAppSettings", signingDetailsResponse["content"]["settings"]);
                if (signingDetailsResponse && signingDetailsResponse["isSuccess"]) {


                    let settings = this.$store.getters["App/appSettings"];
                    //only consider skipping 2FA for non-ron packages
                    if (signingDetailsResponse["content"]["isRon"] == false) {
                        if (settings.skip2Factor) {
                            // since we skipping 2fa we need to get sigenr details directly.
                            // we usually got it after verify_2fa call. so this is  just the opposite.
                            let response = await ApiService.GeneratePostRequest("verify/skip_2fa", {
                                signerInviteToken: this.inviteToken,
                            });
                            if (response["isSuccess"]) {
                                this.$store.commit("App/updateToken", response["content"]["token"]);
                                this.updateStore(response);
                            }

                            return;
                        }
                    }

                    //this.agreementPageDetails.invitedBy = signingDetailsResponse["content"]["lender"];
                    this.isDateSignable = signingDetailsResponse["content"]["isDateSignable"];
                    //this.agreementPageDetails.details = signingDetailsResponse["content"]["description"];
                    //this.agreementPageDetails.invitedByID = signingDetailsResponse["content"]["lenderID"];
                    this.borrowerName = signingDetailsResponse["content"]["firstName"] + " " + signingDetailsResponse["content"]["lastName"];

                    this.$store.commit("App/updateLogo", signingDetailsResponse.content.logo);
                    this.$emit('updateLogo');
                    this.updateLogo();
                }
            } else if (!signingDetailsResponse.isSuccess && signingDetailsResponse.content == "redirect") {
                this.$router.push("/admin")
            } else {
                this.errorText = "<h2 style=\"color: white!important; margin-top: 20px\">" + signingDetailsResponse.message + "</h2>"
            }
        },
        computed: {
            alreadyAgreed() {
                return this.$store.getters["App/termsAndConditions"];
            },
        },
        methods: {
            setCaptchaComplete() {
                this.captchaComplete = true;
            },
            async userChoseOption({ ToS, econsent }) {

                this.$store.commit("App/updateTermsAndConditions", { AgreedTo: ToS });
                console.log("consent", econsent);
                let response = await ApiService.GeneratePostRequest(`verify/update`, { "agreement": ToS, "eConsentText": econsent });
                if (ToS) {
                    this.$router.push({ path: `/authenticate/${this.$store.getters["Auth/invite"]}` });
                }

                if (this.isLoggedIn && response && response["isSuccess"]) {
                    // Get Signer Details
                    response = await ApiService.GenerateGetRequest("signer/getInvite", {
                        identityToken: this.inviteToken,
                    });

                    console.log("Signer Invite Response", response);
                    if (response && response["isSuccess"]) {
                        this.$store.commit(
                            "Auth/updateSignerSessionInfo",
                            response["content"]
                        );
                        this.$router.push({ name: "SignerDetails" });
                    } else {
                        this.serverMsg = response["message"];
                    }
                } else {
                    this.serverMsg = response["message"];
                }
            },
            updateLogo() {
                var logo = this.$store.getters["App/getLogo"];
                if (logo == null)
                    logo = url.endPoint + '/api/admin/logo';
                else
                    logo = url.endPoint + '/api/admin/logo?name=' + logo;
                this.Logo = logo;
                return logo;
            },
            async updateStore(response) {
                this.serverMsg = "";
                if (this.inviteToken !== null) {
                    // GET JWT Token
                    if (response && response["isSuccess"]) {
                        this.$store.commit("Auth/signInSuccessful", {
                            token: response["content"]["token"],
                            invite: this.inviteToken,
                        });
                        //this.$store.commit("Auth/signInSuccessful", { token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaWduZXJJZCI6IjEyODAiLCJub3Rhcml6ZUlkIjoiOTAyIiwiaWF0Ijo2Mzc2OTY2ODc5MzQ3MzQ0MjMsImV4cCI6MTYzNDA3NTU5MywiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NTAwMDEiLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo1MDAwMSJ9.qPULI2NzqheX-Va0CyojYMTC6aihta_2Z1RUsXghU5g", invite: this.inviteToken });
                        this.$store.commit("App/updateVerificationSteps", {
                            VerificationSteps: response["content"]["verificationSteps"],
                        });
                        let stepIndex = response["content"]["verificationSteps"].findIndex(x => x.stepOrder == response["content"]["signerVerification"]["stepOrderId"]);
                        if (response["content"]["signerVerification"]) {
                            this.$store.commit("App/updateSignerVerificationData", {
                                SignerId: response["content"]["signerVerification"]["signerId"],
                                NotarizeId:
                                    response["content"]["signerVerification"]["notarizeId"],
                                AgreedToS: response["content"]["signerVerification"]["agreedToS"],
                                StepOrderId: stepIndex,
                            });
                            this.$store.commit("Invitation/enableRon", response["content"]["isRon"]);
                        }

                        this.isLoggedIn = true;

                        // Get Signer Details
                        let invite_response = await ApiService.GenerateGetRequest("signer/getInvite", {
                            identityToken: this.inviteToken, //"5c17057d-8e77-4dff-a351-68befa7b4a3f",
                        });

                        if (invite_response && invite_response["isSuccess"]) {
                            this.$store.commit("Auth/updateSignerSessionInfo", invite_response["content"]
                            );
                        }

                        if (invite_response && invite_response["isSuccess"]) {
                            let settings = this.$store.getters["App/appSettings"];

                            //Go straight to room only if not ron
                            if (settings.skipSigningRoomAuthorization && response["content"]["isRon"] == false) {
                                this.$router.push({ path: `/signer/room` });
                            }
                            else {
                                //go to e-Consent if it is not accepted
                                if (!response["content"]["signerVerification"] ||
                                    !response["content"]["signerVerification"]["agreedToS"]) {

                                    // ensure local storage is false as well
                                    this.$store.commit("App/updateTermsAndConditions", {
                                        AgreedTo: false,
                                    });
                                    this.$router.push({ name: "TermsAndConditions" });
                                    return;
                                } else {
                                    if (this.isDateSignable) {
                                        let showConsent = ApiService.GenerateGetRequest('signer/closingEconsent');
                                        console.log("show consent", showConsent);
                                        if (showConsent)
                                            this.$store.commit("App/updateTermsAndConditions", { AgreedTo: !showConsent });

                                    }
                                    this.$router.push({ name: "TermsAndConditions" });
                                    return;

                                }
                            }
                        } else {
                            this.serverMsg = "Something went wrong fetching your invitation data.";
                        }
                    } else {
                        this.serverMsg = invite_response["message"];
                    }
                }
            },
        },
    };
</script>