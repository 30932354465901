<template>
    <div class=" col-md-12 text-center">

        <input type="hidden" id="CameraID" name="CameraID">
        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
                <img id="cam_icon" src="/images/icon-video.png" alt="" class="img-resonsive testing-icon">
            </div>
            <div class="col-xs-10">
                <h3 style="text-align:left">Camera</h3>
            </div>
        </div>
        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
            </div>
            <div class="col-xs-10 text-left">
                <select name="" v-model="selectedCamera" id="videoSource" class="form-control bdr-rad-0" @change="cameraSelected" style="min-width:15rem">
                    <option v-for="(device, index) in videoDevices" :value="device.deviceId">
                        {{ device.label || 'Camera ' + (index + 1) }}
                    </option>
                </select>
                <p class="text-left" style="margin-top:2em">Please select your Camera setting preferences.</p>
            </div>
            <div class="col-xs-2">
            </div>
            <div class="col-xs-10" style="text-align:left">
                <button :disabled="selectedCamera == null" @click.prevent="previewVideo" class="btn-primary btn-block btn">{{ showVideo ? 'Close' : 'Preview'}}</button>
            </div>
        </div>

        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
            </div>
            <div class="col-xs-10" style="text-align:left">
                <video autoplay="" id="vidPreview" style="width: 25rem; height: 25rem; " v-show="showVideo"></video>
            </div>
        </div>

    </div>
</template>

<style>
    #cam_icon{
        margin: 0em 1.25em 0em 0em;
    }
    #box {
        margin-bottom: 15em;
    }
</style>

<script>
    import Webcam from 'webcam-easy';

    const delay = 500;

    export default {
        name: 'TestCamera',
        emits: ["cameraSelected", "camID"],
        data() {
            return {
                deviceInfos: [],
                cameraCount: 0,
                showVideo: false,
                selectedCamera: null,
                webcam: null,
                videoDevices: [],
                timeout: null,
            }
        },
        async created() {
            this.recheckPermissions();

            if (this.deviceInfos != null && this.deviceInfos.length > 0) {
                if (this.selectedCamera == null)
                    this.selectedCamera = this.videoDevices[0].deviceId;
                else
                    this.cameraSelected();
            }

            this.$emit("camID", this.selectedCamera);
        },
        methods: {
            recheckPermissions() {
                const self = this;
                clearTimeout(self.timeout);
                self.timeout = setTimeout(async () => {
                    navigator.mediaDevices.getUserMedia({video: true, audio: false}).then(async function(stream) {
                        let devices = await navigator.mediaDevices.enumerateDevices();
                        self.deviceInfos = devices;
                        self.videoDevices = self.deviceInfos.filter(d => d.kind === 'videoinput');
                        self.selectedCamera = self.videoDevices[0].deviceId;
                        self.$emit("camID", self.selectedCamera);
                    })
                    .catch(function(err) {
                        self.recheckPermissions();
                    });
                }, delay);
            },
            isValid() {
                console.log("hello");
                return true;
            },
            cameraSelected() {
                console.log("Changed", this.selectedCamera);
                if (this.webcam)
                    this.webcam.stop();

                if (this.selectedCamera !== null) {
                    this.$store.commit("Invitation/updateCamera", { Camera: this.selectedCamera });
                    this.$emit("camID", this.selectedCamera);
                }
            },
            getUserMedia() {
                return !!(navigator.mediaDevices &&
                    navigator.mediaDevices.getUserMedia);
            },
            previewVideo() {
                if (!this.showVideo) {
                    this.showVideo = true;
                    console.log("called", this.showVideo);
                    const webcamElement = document.getElementById('vidPreview');
                    this.webcam = new Webcam(webcamElement, 'user');
                    this.webcam.start()
                        .then(result => {
                            console.log("webcam started");
                        })
                        .catch(err => {
                            console.log(err);
                        });
                } else {
                    this.showVideo = false;
                    this.webcam.stop();
                }
            },
        },
    }
</script>
