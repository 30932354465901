<template>
    <pdfexport ref="NotaryJournalExport" forcePageBreak=".page-break" :page-template="pageTemplate" margin="0.25in" :paper-size="'Letter'">
        <div id="exportContent" ref="container" class="container">
            
            <div class="pdfexport">
                <div style="text-align:center;">
                    <div class="header">
                        <div class="navbar-header">
                            <div class="logo"><img src="/images/smart-esign-logo.svg" alt=""></div>
                        </div>
                        <br />
                        <h1>Notary Journal</h1>
                        <hr class="k-hr" />
                    </div>
                    <div class="header-details" v-if="loanDetail">
                        <table style="margin:auto; width:55%; text-align:left;">
                            <tr>
                                <td>
                                    <strong>Digital Close Order #:</strong>
                                </td>
                                <td>
                                    {{loanDetail.loanNumber}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Property Address:</strong>
                                </td>
                                <td>
                                    {{loanDetail.propertyAddress}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Lender:</strong>
                                </td>
                                <td>
                                    {{loanDetail.lenderName}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Borrower(s): </strong>
                                </td>
                                <td>
                                    {{loanDetail.borrowerName}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Report Generated Date:</strong>
                                </td>
                                <td>
                                    {{ moment().format("MMMM Do, YYYY &nbsp;&nbsp; h:mm:ss a ") }}
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div v-if="notary" id="notary-details" class="container" style="margin-bottom:2em;">
                        <div>
                            <h3>Notary Details</h3>
                        </div>
                        <div style="width:3.2in; float:left;">
                            <table style="text-align:left;">
                                <tr>
                                    <td style="width:.8in; font-weight:bold;">
                                        Notarial Act
                                    </td>
                                    <td style="width:.5in;">
                                        <span v-for="i in notarialActs">{{ notarialActs ? i.notarialActDescription : "Pending" }}<br /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: .8in; padding-right: 1px; font-weight: bold;">
                                        Notarial Public
                                    </td>
                                    <td style="width: 1.8in; padding-right: 1px;">
                                        {{ notaryAssigned ? notaryAssigned : "N/A" }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: .8in; font-weight: bold;">
                                        Platform
                                    </td>
                                    <td style="width:.8in; margin-left:1px;">
                                        Web
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: .8in; font-weight: bold;">
                                        Customer ID
                                    </td>
                                    <td style="width:1.2in;">
                                        {{loanDetail.loanNumber}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div style="width:3.2in; float:left;">
                            <table>
                                <tr>
                                    <td style="width: .8in; font-weight: bold;">
                                        Document Type
                                    </td>
                                    <td style="width:1in;">
                                        {{loanDetail.packageType}}
                                    </td>
                                    </tr>
                                <tr>
                                    <td style="width: .8in; font-weight: bold;">
                                        Signing Status
                                    </td>
                                    <td style="width:1in;">
                                        {{normalizeStatus(loanDetail.statusDescription)}}
                                    </td>
                                </tr>
                                    <tr>
                                    <td style="width: .8in; font-weight: bold;">
                                        Signing Date
                                    </td>
                                    <td style="width:1in;">
                                        {{moment(loanDetail.closingDate).format("MM/DD/YYYY h:mm A")}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        
                        <br />

                    </div>

                    <div id="signers" class="container" style="padding-right:2.5in; margin-bottom:2em;">
                        <div>
                            <h3>Signers</h3>
                        </div>
                        <div>
                            <table style="width:5.2in; text-align:left;">
                                <thead>
                                    <tr>
                                        <th style="width:.5in;">
                                            Signer Name
                                        </th>
                                        <th style="width:1.2in; padding-right:1px;">
                                            Email
                                        </th>
                                        <th style="width:1in;">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tr v-for="item in signers" :key="item.participantId">
                                    <td style="width: .5in; word-wrap: normal;">
                                        {{ item.firstName }} {{item.lastName}}
                                    </td>
                                    <td style="width: 1.2in; padding-right: 1px; word-wrap: break-word;">
                                        {{item.emailAddress}}
                                    </td>
                                    <td style="width: 1in; margin-left: 1px; word-wrap: break-word;">
                                        {{normalizeStatus(item.participantStatus)}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div id="others" class="container" style="margin-bottom:2em;">
                        <div>
                            <h3>Other Participants</h3>
                        </div>
                        <div>
                            <table style="width:5.2in; text-align:left;">
                                <thead>
                                    <tr>
                                        <th style="width:.5in;">
                                            Participant Name
                                        </th>
                                        <th style="width:1.1in; padding-right:1px;">
                                            Email
                                        </th>
                                        <th style="width:.4in; padding-right:1px;">
                                            Type
                                        </th>
                                        <th style="width:.6in;">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tr v-for="item in others" :key="item.participantId">
                                    <td style="width:.5in;">
                                        {{ item.firstName }} {{item.lastName}}
                                    </td>
                                    <td style="width: 1.1in; padding-right: 1px; word-wrap: break-word;">
                                        {{item.emailAddress}}
                                    </td>
                                    <td style="width: .4in; margin-left: 1px; word-wrap: break-word;">
                                        {{normalizeStatus(item.participantType)}}
                                    </td>
                                    <td style="width:.5in; margin-left:1px; word-wrap:break-word;">
                                        {{normalizeStatus(item.participantStatus)}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div v-if="notaryNotes" class="container" style="padding-right:2in; width:7.5in;">
                        <div>
                            <h3>Notary Notes</h3>
                        </div>
                        <div v-for="n in notaryNotes" :key="n.noteId" style="margin-bottom:.1in;">
                            <span style="font-weight:bold; word-wrap:break-word;">{{moment(n.createdDate).format("MM/DD/YYYY h:mm a")}}</span> <span>{{n.notes}}</span>
                        </div>

                    </div>
                    <span class="page-break"></span>
                </div>                               
            </div>
        </div>
    </pdfexport>
</template>

<script>
    import moment from "moment";
    import { PDFExport, savePDF } from "@progress/kendo-vue-pdf";
    import ApiService from "@/core/services/api-service";
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { markRaw, h } from 'vue';
    import GridDataHelper from "@/core/services/grid-data-helper";

export default {
        name: "NotaryJournalPDF",
        computed: {
            moment: () => moment
        },
        components: {
            grid: Grid,
            pdfexport: PDFExport
        },
        props: ["pckgId"],
        data: function () {           
            return {
                loanDetail: {},
                packageId: "",
                notaryNotes:[],
                lenderName: "",
                logType: "",
                notary: [],
                signers: [],
                others: [],
                notaryAssigned:null,
                pageTemplate: markRaw({
                    props: {
                        pageNum: Number,
                        totalPages: Number
                    },
                    render: function () {
                        return h('div', {
                            style: { position: 'absolute', bottom: '10px', right: '10px' }
                        }, ['Page ' + this.$props.pageNum + ' of ' + this.$props.totalPages])
                    }
                }),
                notarialActs: []
            }
        },
        async mounted() {
            this.populateLog();
        },
        methods: {
            moment() {
                return moment();
            },
            normalizeStatus(string) {
                return GridDataHelper.normalizeStatus(string);
            },
            exportPDFWithComponent: function () {
                savePDF(this.$refs.container, {
                    paperSize: "A4",
                    margin: 10,
                    fileName: `NotaryJournal`,
                    pageTemplate: "pageTemplate",
                    forcePageBreak: ".page-break",
                });
            },

            async populateLog() {
                
                if (this.pckgId && this.pckgId > 0) {

                    await ApiService.GeneratePostRequest(
                        "Package/GetLoanPackageById/" + this.pckgId
                    ).then((response) => {
                        this.loanDetail = response["content"];
                        console.log("loan detail pdf", this.loanDetail);
                    });

                    await ApiService.GenerateGetRequest(
                        "package/notes/" + this.pckgId
                    ).then((response) => {
                        this.notaryNotes = response["content"];
                        console.log("notes");
                    });

                    await ApiService.GenerateGetRequest(
                        `package/${this.pckgId}/participants`
                    ).then((response) => {

                        let participants = response["content"];
                        console.log("participants", participants);
                        this.others = participants.filter(
                                (x) => x.participantType != "Signer"
                            );
                        this.signers = participants.filter(
                                (x) => x.participantType == "Signer"
                            );
                    });

                    this.getPackageNotary();

                    await ApiService.GenerateGetRequest(
                        `notarize/getPackageNotarialActs?notarizeid=${this.loanDetail.notarizeID}`
                    ).then((response) => {
                        console.log("package acts", response);
                        this.notarialActs = response.content;

                    });
                }
            },
            async getPackageNotary() {

                if (this.loanDetail != null && this.loanDetail.loanPackageID != null) {
                    let obj = {
                        LoanID: parseInt(0),
                        PackageId: parseInt(this.loanDetail.loanPackageID)
                    };
                    let response = await ApiService.GeneratePostRequest(`Package/notary`, obj);

                    obj = null;
                    console.log("response content", response.content);
                    if (response.content != null && response.content.data.length > 0) {

                        console.log("response content data", response.content.data);
                        obj = response.content.data[0];
                    }

                    this.notaryAssigned = obj.notary;
                    console.log("notary ", this.notaryAssigned);
                }
            },
            
        },

};
</script>

<style scoped>
    .container{
        text-align:left;
    }
    table td, th {
        vertical-align: top;
        padding: 0.5em 0.5em 0em 0em;
    }
    table {
        table-layout: fixed;
    }

    td {
        word-wrap: break-word
    }

.audit_list {
  margin: 0;
}

.audit_list ul {
  margin: 0;
  padding: 0;
  height: 400px;
  width: 100%;
  float: left;
  overflow-y: scroll;
}

.audit_list ul li {
  margin: 5px 0 15px 0;
  list-style: none;
  padding: 2px 0 8px 0;
}

.audit_list ul li:hover {
  background: rgba(78, 78, 78, 0.1);
}

.audit_list ul li .icon {
  float: left;
  margin: 6px 10px 0 0;
}

.audit_list ul li .icon .material-icons {
  font-size: 35px;
}

.audit_list ul li .title {
  font: 600 1.14em "Lato", sans-serif;
}

.audit_list ul li .time {
}

.pdfexport{
    width:6.5in;
    font-size:9px;
    overflow:hidden;
}
.pdfexport h1{
    font-size:14px;
}
    .pdfexport h2, .pdfexport h3, h2 {
        font-size: 12px!important;
    }
    .pdfexport .logo img{
        width: 50%!important;
        margin-top:10px;
    }
    .pdfexport td, th{
        padding:0 2px 0 2px;
    }

    .header{
        width:5in;
        text-align:left;
    }

    .header-details{
        margin-bottom:2em;
    }
</style>
