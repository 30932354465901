window.url = require("./core/services/urls");
window.globalCallback = [];
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import store from './store';

// register callbacks function
export const startConnection = (callbackFunc) => {
    if (window.globalCallback.find(x => x == callbackFunc) == null)
        window.globalCallback.push(callbackFunc);

    if (window.connected_started === true)
        return;
    window.connected_started = true;

    // singleton connection.
    const connection = new HubConnectionBuilder()
        .withUrl(url.endPoint + '/video', {
            accessTokenFactory: () => store.getters["App/token"],
            withCredentials: true,
            headers: { 'Authorization': `Bearer ${store.getters["App/token"]}` },
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build()

    connection
        .start({ pingInterval: 10000 })
        .then(() => console.log('Connection started', this))
        .catch(err => console.log('Error while starting connection: ' + err))

    connection
        .onclose(() => setTimeout(connection.start(connection), 5000))


    connection.on('sendCallToAllNotaries', (signerName, notarizeID, signerID) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("signerInQueue", { "signerName": signerName, "notarizeID": notarizeID, "signerID": signerID });
            }
        })
    });

    connection.on('sendNotarySignerUploadID', () => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("signerUploadedID");
            }
        })
    });

    connection.on('reuploadSignerID', () => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("reuploadSignerID");
            }
        })
    });

    connection.on('sendNotarySignerUploadID', () => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("signerUploadedID");
            }
        })
    });

    connection.on('reuploadSignerID', () => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("reuploadSignerID");
            }
        })
    });

    connection.on("addChatMessage", (sender, message) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc(sender, message);
            }
        })
    });

    connection.on("receiveAcknowledgement", (userID, isAccepted, apiKey, sessionID, token, notarizeID) => {
        window.globalCallback.forEach(callbackFunc => {
        })
    });

    connection.on("SignerInQueueAlert", (userID, signerConnectionID, SignerName, NotaryName, notarizeID) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("signerInQueue");
            }
        })
    });

    connection.on("cancelThisNotary", (signerConenctionID) => {
        window.globalCallback.forEach(callbackFunc => {
        })
    });

    connection.on("loadAppliedTags", (tagSignerID, tagDocumentID, imgUrl, timestamp) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("loadAppliedTags", { "tagSignerID": tagSignerID, "tagDocumentID": tagDocumentID, "imgUrl": imgUrl, "timestamp": timestamp });
            }
        })
    });

    connection.on("loadSignerTagsToWitness", (tagSignerID, tagDocumentID, imgUrl, timestamp) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("loadSignerTagsToWitness", { "tagSignerID": tagSignerID, "tagDocumentID": tagDocumentID, "imgUrl": imgUrl, "timestamp": timestamp });
            }
        })
    });

    connection.on("enableSigning", (enableSigning) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("enableSigning", enableSigning);
            }
        })
    });

    connection.on("notifyNotarySignerTagsSigned", (tagID, control, timestamp) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("notifyNotarySignerTagsSigned", { "tagID": tagID, "control": control, "timestamp": timestamp });
            }
        })
    });
    connection.on("notifyNotaryWitnessTagsPlaced", (from, tagID, control, timestamp) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("notifyNotaryWitnessTagsPlaced", { from: from, "tagID": tagID, "control": control, "timestamp": timestamp });
            }
        })
    });

    connection.on("dynamicSignerTag", (stringifiedTag) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("dynamicSignerTag", { "tag": JSON.parse(stringifiedTag) });
            }
        })
    });

    connection.on("sendNewPage", (documentId, page) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("newPage", { docId: documentId, pageNum: page });
            }
        })
    });

    connection.on("sendNewPageWitness", (documentId, page) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("newPageWitness", { docId: documentId, pageNum: page });
            }
        })
    });

    connection.on("dynamicSignerTagWitness", (stringifiedTag) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("dynamicSignerTagWitness", { "tag": JSON.parse(stringifiedTag) });
            }
        })
    });


    connection.on("sendScrollToWitness", (scrollPos, scrollHeight) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("receiveBorrowerScrollAsWitness", { "position": scrollPos, "height": scrollHeight });
            }
        })
    });


    connection.on("closewaiting", () => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("closewaiting");
            }
        })
    });

    connection.on("endSessionAll", () => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("endSession");
            }
        })
    });

    connection.on("notaryDeclinedCall", () => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("calldeclined");
            }
        })
    });

    connection.on("updateSignerWaiting", (type) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("updateSignerWaiting", type);
            }
        })
    });

    connection.on("sendBorrowerWaiting", (borrowerName, isFirst) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("sendBorrowerWaiting", { "participantName": borrowerName, "isFirst": isFirst });
            }
        })
    });

    connection.on("sendBorrowerDisconnected", (borrowerName, isFirst) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("refreshSignerList");
            }
        })
    });

    connection.on("showIAttestLegalWitness", (forSigner_ID) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("showIAttestLegalWitness", { forSigner_ID: forSigner_ID });
            }
        })
    });

    connection.on("sendmessagetonotary", (message) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("sendmessagetonotary", { message: message });
            }
        })
    });

    connection.on("signersCompletedSigning", (message) => {
        window.globalCallback.forEach(callbackFunc => {
            if (callbackFunc != null) {
                callbackFunc("signersCompletedSigning", { message: message });
            }
        })
    });
}
    
