<template>
    <kendo-datasource ref="documentsource"
                      :transport-read="readData"
                      :transport-update="updateData" 
                      :schema-model-id="'id'"
                      :schema-model-fields="schema"
                      :schema-data="'data'"
                      :schema-total="'total'"
                      :schema-groups="'groups'"
                      :schema-aggregates="'aggregates'"
                      :schema-errors="'errors'"
                      :transport-parameter-map="parameterMap"
                      :server-filtering="true"
                      :server-paging="true"
                      :batch='true'
                      :page-size='100'>
    </kendo-datasource>

    <kendo-grid :data-source-ref="'documentsource'"
                ref="kendoJqueryGrid"
                :class="'documents'"
                :pageable="false"
                :edit="onEdit"
                :editable="packagePopUpTemplate"
                :sortable="true"
                :resizable="true"
                :pageable-refresh="true"
                :pageable-button-count="5"
                :pageable-page-sizes="[5, 10, 20, 100]"
                :pageable-always-visible="true"
                :navigatable="true"
                selected-field="selected"
                :filterable="shouldFilter"
                :pdf-all-pages="true"
                :pdf-avoid-links="true"
                :pdf-paper-size="'A4'"
                :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                :pdf-landscape="true"
                :pdf-repeat-headers="true"
                :pdf-scale="0.8"
                :columns="columns"
                @databound="documentDataBound"
                @filterchange="filterChange"
                @selectionchange="onSelectionChange"
                @headerselectionchange="onHeaderSelectionChange">
        <toolbar v-show="!hideCol"> 
        </toolbar>
    </kendo-grid>
    <pdfexport ref="gridPdfExport" v-show="false">
        <native-grid :columns="exportColumns" />
    </pdfexport>
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .participant-cmd, /deep/ .participant-cmd:hover, /deep/ .participant-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 .94em 'Lato', sans-serif;
        color: #4d97ac;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.ready_for_review /deep/ span {
            background: #387a38;
        }

        .status.ready_for_signing /deep/ span {
            background: #4cb64c;
        }

        .status.default /deep/ span {
            background: #f3a638;
        }

        .status.reviewed /deep/ span, .status.signed /deep/ span, .status.notarized /deep/ span {
            background: #54b7d3;
        }

        .status.archived /deep/ span {
            background: #E3D4D4;
        }

        .status.cancelled /deep/ span, .status.wet_sign /deep/ span {
            background: slategray;
        }
</style>
<script>
    import { useRoute } from 'vue-router';
    import ApiService from "@/core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { process } from '@progress/kendo-data-query';
    import { loadFileUrl } from "@/core/services/api-service";
    import { downloadFileUrl } from "@/core/services/api-service";
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';
    import GridDataHelper from "../../core/services/grid-data-helper";
    import Swal from 'sweetalert2';

    export default {
        name: 'PackageNotary',
        //props: ['packageide', 'pnotarizeId', 'pnotaries', 'ntype'],
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'dropdownlist': DropDownList,
            'native-grid': NativeGrid,
        },
        data() {
            ///****************************************************************************** NOTE ******************************************************************************///
            ///This is the ONLY WAY right now to include a function to specify if a column can be visible. All other ways fail due to a bug in kendo that attempts to add a _ctx ///
            ///to variables in the function thereby breaking the generated template. The workaround is to have an inline function so that kendo cannot access the function scope ///
            ///******************************************************************************************************************************************************************///
            function canViewDocument(e) {

                console.log("can view", e);
                if (e.pdftemplateFilePath != null && e.pdftemplateFilePath!="") {
                    return true;
                }
                else { return false; }  
                 
            }


            return {
                hideCol: false,
                shouldFilter: false,
                schema: {
                    id: { editable: false },
                    notarialActName: { type: 'string', editable: true },
                    description: { type: 'string', editable: true },
                    pageNo: { type: 'int', editable: true },
                    noOfPages: { type: 'int', editable: true },
                    pdftemplateFilePath: { type: 'string', editable: true },
                },
                columns: [ 
                    { field: "notarialActName", title: "Name", width: "250px", visible: true  },
                    { field: "description", title: "Description", width: "250px", visible: true  },
                    { field: "pageNo", title: "Pages No#", width: "80px", visible: true  },
                    { field: "noOfPages", title: "Pages", width: "80px", visible: true  },  
                    {
                        title: '', width: "350px", command: [
                            { className: 'participant-cmd', text: 'Edit', click: this.documentEdit, visible: function (e) { return true; } },
                            { className: 'participant-cmd', text: 'View', click: (e) => { this.documentView(e) }, visible: canViewDocument },
                        ]
                    }, 
                ],

                exportColumns: [ 
                    { field: "id", title: "ID#", width: "35px", visible: false },
                    { field: "notarialActName", title: "Name", width: "250px" },
                    { field: "description", title: "Description", width: "250px" },
                    { field: "pageNo", title: "Page No#", width: "80px" },
                    { field: "noOfPages", title: "Pages", width: "80px" },
                    //{ field: "pdftemplateFilePath", title: "Template Path", width: "250px" }, 
                ],
                newid: "",
                newdoc: {},
                show: true,
                currentNotary: "",
                 
            }
        },
        created() {
        },
        computed: {
            packagePopUpTemplate(e) {
                   
                let html = "";
                html += "<div id='ActGrid'>";
                html += "   <div class='col-md-12'>";
                html += KendoPopupGenerator.generatePopUpFormFieldsReadOnly('notarialActName', 'Name', 'notarialActName');
                html += KendoPopupGenerator.generatePopUpFormFieldsReadOnly('description', 'Description', 'description');
                html += KendoPopupGenerator.generatePopUpFormFieldsReadOnly('pageNo', 'Page Number', 'pageNo');
                html += KendoPopupGenerator.generatePopUpFormFieldsReadOnly('noOfPages', 'Pages', 'noOfPages');   
                html += KendoPopupGenerator.generatePopUpFileFormField('CreateDocumentGridFile', 'Upload Template', 'CreateDocumentGridFile', true);
                html += "   </div>";
                html += "</div>";
                return { 'template': html, 'mode': 'popup' } 
            },
        },
        methods: {
            reloadParent: function () {
                this.$emit('reload-parent');
            },  
            documentView(e) {
                var gridRow = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                this.pdftemplateFilePath = gridRow.pdftemplateFilePath;
                console.log(gridRow) 
                var newWindow = window.open(loadFileUrl + gridRow.pdftemplateFilePath, "_blank"); 
            },
            documentEdit(event) {
                event.preventDefault();
                this.$refs.kendoJqueryGrid.kendoWidget().editRow($(event.target).closest("tr")); 
            },
            documentDataBound: function (e) {
                var self = this;

                var grid = e.sender;
                var items = e.sender.items(); 
                this.reloadParent();
            },
            parameterMap: function (options, operation) {
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {

                if (!e.data) {
                    e.data = {};
                } 
                var userId = this.$store.state.Admin.loginInformation.userId;
                return ApiService.GenerateGetRequest(`Notarize/getUserNotarialActs?userId=` + userId).then(
                    response => {
                        e.success(response.content);
                        console.log("notarizeact: ", response.content);
                    }
                ); 
            }, 
            updateData: function (e) {
                console.log("updating", e);

                let name = e.data.models[0]["notarialActName"];
                let Id = e.data.models[0]["id"]
                let file = $("#CreateDocumentGridFile")[0].files[0];
                let formData = new FormData();
                formData.append("File", file);
                formData.append("FileName", name);
                formData.append("Id", Id);

                this.$refs.kendoJqueryGrid.kendoWidget().refresh();
                return ApiService.GeneratePostRequest("Notarize/notarialact/update", formData, { "Content-Type": "multipart/form-data" }).then(
                    response => {
                        console.log("on update: ",response.content);
                        e.success(response.content);
                        if (response.isSuccess) {
                            Swal.fire(
                                'Notarial Act updated',
                                '',
                                'success'
                            );
                        }
                        else {
                            Swal.fire(
                                'Update Failed',
                                'Document could not be updated',
                                'error'
                            );
                        }
                    }
                );

            }, 
            async onEdit(e) {
                 
                $("#ActGrid .k-window-title").text("Upload Template");

                // handles resizing the size of the edit form container
                $(".k-edit-form-container").parent().width(1000).data("kendoWindow").title("Add").center();
                $(".k-edit-form-container").css('width', 'auto'); 

                $("#ActGrid #CreateDocumentGridFile").on("change", () => {
                    e.model.dirty = true;  
                })
            },

            exportExcel() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };

                        saveExcel({
                            data: process(arr.data, options).data,
                            fileName: "packages",
                            columns: self.columns
                        });
                    }
                });
            },
            exportPDF() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };
                        const itemsToExport = process(arr, options).data;
                        (self.$refs.gridPdfExport).save(itemsToExport);
                        return arr;
                    }
                });
            },
            filterChange() {

            },
            onSelectionChange() {

            },
            onHeaderSelectionChange() {

            },
            reloadGrid() {
                this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read()
            }
        },
    }
</script>