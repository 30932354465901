<template>
    <div class="innerbox col-md-7 col-xs-12 column-flex">
        <div class=" col-md-12 text-center">
            <br>
            <br>

            <img src="/images/driving-license-new.png" style="width:80%; height:80%" alt="" class="img-responsive m-auto mb-3" v-show="!takePhoto && docType == 1">
            <img src="/images/passport.png" style="width:80%; height:80%" alt="" class="img-responsive m-auto mb-3" v-show="!takePhoto && docType == 2">

            <div v-show="takePhoto">
                <video id="webcam" autoplay playsinline style="width:640px; height:480px; background:#CCC;"></video>
                <canvas id="canvas" style="display: none;"></canvas>
                <h1 v-if="loadingCamera">Loading Camera</h1>
                <button type="button"
                        class="btn btn-secondary" @click="snap">
                    SNAP
                </button>
                <button type="button"
                        class="btn btn-primary" @click="cancelVideoCapture">
                    CANCEL
                </button>
            </div>
            <br>
            <div class="row text-left">
                <div class="col-md-10 col-md-offset-1">
                    <p class="text-center">We will now Verify and Validate your Identity</p>
                    <form method="Post" action="/Verification/TakePicture" id="frmVerificationDoc">
                        <ul class="text-center">
                            <li>
                                Choose Identity Document Type
                                <div>
                                    <ul>
                                        <li><label style="font-size: 14px" for="rbtn1"><input id="rbtn1" v-model="docType" type="radio" title="License / State ID" value="1"> License / State ID</label></li>
                                        <li><label style="font-size: 14px" for="rbtn2"><input id="rbtn2" v-model="docType" type="radio" title="Passport" value="2"> Passport</label></li>

                                    </ul>
                                </div>
                                <input type="hidden" name="IdentityDocType">
                            </li>
                        </ul>
                    </form>
                    <p>
                        <div id="divPicOptions" v-if="!takePhoto">
                            <br>
                            <div class="col-md-12 btn-screen-page no-padding text-center">
                                <input type="file" style="display: none" id="fileUploadIdentityPicture" accept="image/png, image/jpeg" @change="imageUpdated($event)" @click="onInputClick($event)">
                            </div>
                            <div class="col-md-6" style="margin-top:2rem;">
                                <div class="container" style="border-style:solid; border-color:lightgrey; width:20rem; height:15rem; cursor:pointer;" onclick="$('#fileUploadIdentityPicture').trigger('click');">
                                    <div class="col-md-12" style="margin-top:1rem;">
                                        <h5 class="text-center">UPLOAD A PHOTO</h5>
                                    </div>
                                    <div class="col-md-12">
                                        <img src="/images/upload-photo.png" style="width:10rem;height:10rem; padding-bottom:1rem;">
                                    </div>                                    
                                </div>
                            </div>
                            <div class="col-md-6" style="margin-top:2rem;">
                                <div class="container" style="border-style: solid; border-color: lightgrey; width: 20rem; height: 15rem; cursor: pointer;" @click="takePhotoFromCamera">
                                    <div class="col-md-12" style="margin-top:1rem;">
                                        <h5 class="text-center">TAKE PHOTO</h5>
                                    </div>
                                    <div class="col-md-12">
                                        <img src="/images/take-photo-new.png" style="width: 10rem; height: 10rem; padding-bottom: 1rem;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p>
                </div>
            </div>

            <br>
        </div>
    </div>
</template>

<style scoped>
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
</style>

<script>
    import Webcam from 'webcam-easy';
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'DriversLicense',
        emits: ["imageBlob", "docTypeChange", "takePhoto"],
        components: {
            "k-button": Button,
        },
        data() {
            return {
                docType: '1',
                takePhoto: false,
                loadingCamera: false,
                webcam: null
            }
        },
        created() {

        },
        watch: {
            docType(value) {
                console.log("emting");
                this.$emit("docTypeChange", value);
            }
        },
        methods: {
            isValid() {
                // check inputs ref for data
                console.log("hello");
                return true;
            },
            onInputClick(event) {
                event.target.value = '';
            },
            imageUpdated(event) {
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.$emit("imageBlob", reader.result);
                    };
                }
            },
            takePhotoFromCamera() {
                this.takePhoto = true;
                this.loadingCamera = true;
                if (!this.webcam) {
                    const webcamElement = document.getElementById('webcam');
                    const canvasElement = document.getElementById('canvas');
                    this.webcam = new Webcam(webcamElement, 'user', canvasElement);
                }
                this.webcam.start()
                    .then(result => {
                        console.log("webcam started");
                        this.loadingCamera = false;
                    })
                    .catch(err => {
                        this.loadingCamera = false;
                    });
            },
            cancelVideoCapture() {
                this.takePhoto = false;
                this.webcam.stop();
            },
            snap() {
                var picture = this.webcam.snap();
                this.$emit("imageBlob", picture);
            }

        },
    }
</script>
