import { Store } from 'vuex';
import { createStore } from 'vuex-extensions';
import Auth from './modules/auth';
import Admin from './modules/admin';
import Invitation from './modules/invitation';
import App from './modules/app';

import createPersistedState from "vuex-persistedstate";

export default createStore(Store, {
    plugins: [createPersistedState({ paths: ["Auth", "Admin", "Invitation", "App"]})],
    modules: {
        Auth,
        Admin,
        Invitation,
        App
    }
})
