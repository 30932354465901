<template>
    <section id="declined-page">
        <div class="declined-final-page-main">
            <p v-html="declinedfinal"></p>
            <div class="confirm-block">
                <div class="declined-final-logout">
                    <router-link to="/authenticate/out" style="color: white; text-decoration: none">Log Out</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<style>

    .declined-final-page-main h1 {
        padding-top: 5%;
        font-size: 45px;
        text-align: center;
    }

    .declined-final-page-main p {
        font-size: 30px;
        text-align: center;
    }

    .declined-final-logout {
        background: #e53935;
        font-size: 20px;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        cursor: pointer;
    }


    .declined-final-logout:hover {
        background: linear-gradient(rgba(153,0, 0, 1), rgba(153, 0, 0, 1));
        cursor: pointer;
    }
</style>
<script>
    import ApiService from "../../core/services/api-service";
    export default {
        name: 'DeclinedFinal',
        data() {
            this.readData();
            return {
                declinedfinal: [],
                lenderid: null,
            }
        },
        methods: {
            async readData() {
                let lenderid = this.$store.state.App.lenderId;
                return ApiService.GenerateGetRequest(`econsent/declinedfinal?lenderid=${lenderid}`).
                    then((response) => {
                        this.declinedfinal = response.content;
                        console.log(response["content"]);
                    });
            }
        },
    }
</script>