<template>

    <section id="screen-page">
        <div class="container personal-signup">
            <div class="col-md-offset-4 col-md-4 col-xs-12">
                <div class="bdr-rad-10 innerbox darkblue-bg">
                    <h2>Login</h2>
                    <form action="/Account/Login" method="post">
                        <input name="__RequestVerificationToken" type="hidden"
                               value="usFeLdYJ1A6wGwb8PKyGr5yFOc03TB4TH-MAtZ61KJSLZ-61elxGM5mqf69sNvXjJAu4MEvscFo1S2AmcOiHNOocJ5olfQ1lZJn27IfSrs41" />
                        <input data-val="true" data-val-required="Please enter Email." id="Email" name="Email" placeholder="Email" type="text" value="" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Email" data-valmsg-replace="true"></span>
                        <input data-val="true" data-val-required="Please enter Password." id="Password" name="Password" placeholder="Password" type="password" />
                        <span class="field-validation-valid text-danger" data-valmsg-for="Password" data-valmsg-replace="true"></span>
                        <span class="field-validation-valid text-danger" data-valmsg-for="ErrorMsg" data-valmsg-replace="true"></span>
                        <input type="submit" value="Login">
                    </form>                  
                    <a class="forgot-pdw-lnk" href="/Account/ForgetPassword">Click here if you forgot your password</a>
                    <a class="businessBtn" href="/BusinessClientPackage/Package">View Business Client Pricing</a>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
    export default {
        name: 'SignIn',
    }
</script>