<template>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container">
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <div class="col-md-12 text-center">
                        <h2>Your Documents</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="innerbox1" id="user-screen">
                    <div class="col-md-121 text-left" id="UserDocumentList">
                        <form action="/Document/Documents" id="formStatus" method="post" novalidate="novalidate">
                            <input id="StatusId" name="SearchModel.Status" type="hidden" value="">                        <ul class="nav nav-pills">
                                <li onclick="SetStatus('1,2,3,5',this)" data-value="1,2,3,5" class="active"><a href="javascript:void(0)">ALL</a></li>
                                <li onclick="SetStatus('1,2',this)" data-value="1,2"><a href="javascript:void(0)">UNSIGNED</a></li>
                                <li onclick="SetStatus('3',this)" data-value="3"><a href="javascript:void(0)">UNPAID</a></li>
                                <li onclick="SetStatus('5',this)" data-value="5"><a href="javascript:void(0)">COMPLETED</a></li>
                            </ul>
                            <div class="tab-content">
                                <div id="home" class="tab-pane fade in active">
                                    <document-list :session="Sessions[SelectedSession]" />
                                </div>
                            </div>
                            <br>
                            <div class="col-md-12 text-center">

                                <ul class="pagination">
                                    <input data-val="true" data-val-number="The field PageNumber must be a number." data-val-required="The PageNumber field is required." id="PageNumber" name="SearchModel.PageNumber" type="hidden" value="1">

                                    <li onclick="SetPageNum(1,this)">
                                        <a href="javascript:void(0)">1</a>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import ApiService from "../core/services/api-service";
    import DocumentList from "@/components/page/DocumentList.vue";
    export default {
        name: 'Dashboard',
        components: {
            DocumentList
        },
        data() {
            return {
                SelectedSession: "Session1",
                Sessions: {
                    Session1: {
                        Name: "Session 1",
                        SigningDate :"09/12/2021",
                        Documents: [{
                            DocumentTitle: "Doc Title",
                            NotarizeStatus: 1,
                            NotarizeType: 2,
                            IsAgreed: true,
                        }, {
                            DocumentTitle: "Doc Title",
                            NotarizeStatus: 1,
                            NotarizeType: 2
                        }, {
                            DocumentTitle: "Doc Title",
                            NotarizeStatus: 1,
                            NotarizeType: 2,
                            IsReview: true
                        }, {
                            DocumentTitle: "Doc Title",
                            NotarizeStatus: 1,
                            NotarizeType: 2,
                            IsSigned: true,

                        },]
                    },
                },
            }
        },
        async created() {
            //var response = await ApiService.GenerateGetRequest("Document/DocumentsSPA", {UserId: 1195});//this.$store.getters["Auth/UserId"]});
            //console.log("home response");
            //console.log(response);

            // if(response["list"] !== null){
            //     this.Documents = response["list"]["DocumentList"];
            // }
        },
        methods: {

           
        }
    }
</script>