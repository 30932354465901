<template>
    <div class="container main-container">
        <div class="col-md-10 col-md-offset-1 col-sm-12">
            <h2>Confirm Your Details</h2>
            <form>
                <div class="row">
                    <div class="col-md-12 col-sm-12 form-group">
                        <h5>Email:</h5>
                        <k-input :label="'Email'"
                                 :name="'Email'"
                                     :min-length="3"
                                     v-model="emailAddress"
                                     :disabled="true"
                                     :read-only="true"
                                     :required="true">
                            </k-input>
                            <input v-model="emailAddress" name="emailAddress" type="hidden">
                        </div>
                    </div>
                    <div class="row pb-m">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h5>Name:</h5>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group">
                        <k-input :label="'First Name'"
                                 :name="'firstName'"
                                     :pattern="'[A-Za-z]+'"
                                     :min-length="1"
                                     v-model="firstName"
                                 :disabled="participantType != 'Legal_Witness'"
                                 :read-only="participantType != 'Legal_Witness'"
                                     :required="true">
                            </k-input>
                        </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group">
                        <k-input :label="'Middle Name'"
                                 :name="'middleName'"
                                     :pattern="'[A-Za-z]+'"
                                     :min-length="1"
                                 :disabled="participantType != 'Legal_Witness'"
                                 :read-only="participantType != 'Legal_Witness'"
                                     v-model="middleName"
                                     :required="false">
                            </k-input>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <k-input :label="'Last Name'"
                                 :name="'lastName'"
                                     :pattern="'[A-Za-z]+'"
                                     :min-length="1"
                                 :disabled="participantType != 'Legal_Witness'"
                                 :read-only="participantType != 'Legal_Witness'"
                                     v-model="lastName"
                                     :required="true">
                            </k-input>
                        </div>
                    </div>
                    <div class="row pb-m">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h5>Date of Birth:</h5>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group">
                            <dropdownlist id="birthMonth"
                                          :label="'Month'"
                                          :data-items='months'
                                          :text-field="'value'"
                                          :value-field="'id'"
                                          :name="'birthMonth'"
                                          :data-item-key="'id'"
                                          :required="true"
                                          v-model="selectedMonth"
                                          @change="changeMonth">
                            </dropdownlist>
                        </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group">
                            <dropdownlist id="dropdownlist"
                                          :label="'Day'"
                                          :data-items='monthDays'
                                          v-model="birthDay"
                                          :required="true"
                                          :name="'birthDay'">
                            </dropdownlist>
                        </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group">
                            <dropdownlist id="dropdownlist"
                                          :label="'Year'"
                                          :data-items='yearRanges'
                                          v-model="birthYear"
                                          :required="true"
                                          :name="'birthYear'">
                            </dropdownlist>
                        </div>
                    </div>
                    <div class="row pb-m">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h5>Address:</h5>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <k-input :name="'addressLine1'"
                                     :label="'Address Line 1'"
                                     :min-length="5"
                                     v-model="addressLine1"
                                     :valid="(!!addressLine1)"
                                     :required="true">
                            </k-input>
                        </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <k-input :name="'addressLine2'"
                                     :label="'Address Line 2'"
                                     v-model="addressLine2">
                            </k-input>
                        </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <k-input :name="'city'"
                                     :label="'City'"
                                     :min-length="1"
                                     v-model="city"
                                     :valid="(!!city)"
                                     :required="true">
                            </k-input>
                        </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <dropdownlist :label="'Country'"
                                          :data-items='countryList'
                                          :text-field="'countryName'"
                                          :value-field="'id'"
                                          :name="'countryID'"
                                          :data-item-key="'id'"
                                          v-model="selectedCtry"
                                          @change="changeCountry">
                            </dropdownlist>
                        </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group" v-show="statesList.length > 0">
                            <dropdownlist :label="'State'"
                                          :data-items='statesList'
                                          :text-field="'stateName'"
                                          :value-field="'id'"
                                          :name="'stateID'"
                                          :data-item-key="'id'"
                                          :required="statesList.length > 0"
                                          v-model="selectedState"
                                          @change="changeState">
                            </dropdownlist>
                        </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <k-input :name="'zipCode'"
                                     :label="'Zip Code'"
                                     :min-length="1"
                                     v-model="zipCode"
                                     :valid="(!!zipCode)"
                                     :required="true">
                            </k-input>
                        </div>
                    </div>
                <k-button :primary="true" @click="confirmDetails" :disabled="formValid" class="pull-right">
                            CONFIRM
                        </k-button>
            </form>
                    </div>

                </div>
</template>

<style scoped>
    #screen-page input {
        margin-bottom: initial;
    }

    #screen-page h2 {
        color: #303030;
        font-size: 26px;
        font-weight: 600;
        margin: 20px 0 30px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #CCC;
    }


    .k-input-inner {
        border:none;
    }

    .k-input-inner:focus {
        outline: none;
    }

    .k-grid-offset {
        margin-top: 80px;
    }

    .k-textbox-container, .k-floating-label-container, .k-input-inner, .k-input {
        width: 100%;
    }

    .card {
        border-radius: 20px;
        margin-bottom: 20px;
        margin:10px 20px;
    }

    .p-m {
        padding: 16px;
    }

    .pb-m {
        padding-bottom: 16px;
    }

    .name-label {
        color: #7c7c7c !important;
    }

    .main-container {
        margin-bottom: 100px;
    }
</style>

<script>
    // Future todo - this component can be converted to composition api to make the watch property mroe cleaner.
    // learning curve tho.

    import ApiService from "@/core/services/api-service";
    import router from "@/router/index";
    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';
    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
    import { Input } from "@progress/kendo-vue-inputs";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardSubtitle,
        CardActions,
    } from "@progress/kendo-vue-layout";
    import { Button } from "@progress/kendo-vue-buttons";
    const stateValidator = (value) => {
        value = value + "";
        return value === -1 || value.trim().length > 0;
    };

    export default {
        name: 'SignerDetails',
        components: {
            "k-button": Button,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            cardSubtitle: CardSubtitle,
            cardActions: CardActions,
            'dropdownlist': DropDownList,
            'k-input': Input,
        },
        setup() {
            return { v$: useVuelidate() }
        },
        data() {
            return {
                selectedMonth: {},
                selectedCtry: {},
                selectedState: {},
                formValid: false,
                firstName: "",
                middleName: "",
                lastName: "",
                birthMonth: "",
                birthDay: "",
                birthYear: "",
                city: "",
                countryID: "",
                stateID: "",
                zipCode: "",
                addressLine1: "",
                addressLine2: "",
                emailAddress: "",
                firstLoad: true,
                countryList: [],
                statesList: [],
                months: [
                    { id: 1, value: "January" },
                    { id: 2, value: "February" },
                    { id: 3, value: "March" },
                    { id: 4, value: "April" },
                    { id: 5, value: "May" },
                    { id: 6, value: "June" },
                    { id: 7, value: "July" },
                    { id: 8, value: "August" },
                    { id: 9, value: "September" },
                    { id: 10, value: "October" },
                    { id: 11, value: "November" },
                    { id: 12, value: "December" },
                ],
                isRon: this.$store.getters["Invitation/isRon"] || false,
                participantType: "",
            }
        },
        async created() {
            let settings = await this.$store.getters["App/appSettings"];
            if (settings["skipSignerDetails"] && !this.isRon) {
                this.$router.push('/signer/ssn');
                return;
            }

            // fetch country list
            let countryResponse = await ApiService.GenerateGetRequest("Country/all");
            if (countryResponse && countryResponse.length) {
                this.countryList = countryResponse;
                // Find the index of "United States of America" in the countryList
                let usaIndex = this.countryList.findIndex(element => element.countryName === "United States of America");

                // Check if "United States of America" is found in the list
                if (usaIndex !== -1) {
                    // Remove the USA from its original position
                    let usa = this.countryList.splice(usaIndex, 1)[0];

                    // Sort the remaining countries alphabetically
                    this.countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));

                    // Add USA back to the top
                    this.countryList.unshift(usa);
                }
            }

            // set up basic available information
            let signerData = this.$store.getters["Auth/signerSessionInformation"];
            if (signerData) {
                this.firstName = signerData["firstName"];
                this.lastName = signerData["lastName"];
                this.emailAddress = signerData["emailAddress"];
            }

            let signerDetailsResponse = await ApiService.GenerateGetRequest("signer/details");

            if (signerDetailsResponse["isSuccess"]) {
                let sd = signerDetailsResponse["content"];
                this.firstName = sd["firstName"];
                this.middleName = sd["middleName"];
                this.lastName = sd["lastName"];
                this.emailAddress = sd["emailAddress"];
                this.birthMonth = sd["dobMonth"];
                this.birthDay = sd["dobDay"];
                this.birthYear = sd["dobYear"];
                this.city = sd["city"];
                this.countryID = sd["countryID"];
                this.stateID = sd["stateID"];
                this.zipCode = sd["zipCode"];
                this.addressLine1 = sd["address"];
                this.participantType = sd["participantType"];
                this.updateSignerStore();
            }

            if (this.birthMonth > 0)
                this.selectedMonth = this.months[this.birthMonth - 1];
            if (this.countryID > 0)
                this.selectedCtry = this.countryList.find(({ id }) => id == this.countryID);
            if (this.stateID > 0) {
                ApiService.GenerateGetRequest(`Country/${this.countryID}/states`).then((response) => {
                    if (response != null && response.length > 0) {
                        this.statesList = response;
                        this.selectedState = this.statesList.find(({ id }) => id == this.stateID);
                    }
                })
            }

            this.emitValidState();
        },
        computed: {
            yearRanges() {
                let arr = [];
                var year = new Date().getFullYear();
                for (let i = year; i > year-100; i--) {
                    arr.push(i);
                }
                return arr;
            },
            monthDays() {
                let arr = [];
                let mths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                if (this.birthMonth != null && this.birthMonth > 0) {
                    for (let i = 1; i <= mths[this.birthMonth-1]; i++)
                        arr.push(i);
                    return arr;
                } else
                    return [];
            },
            zipCodeContainerWidth() {
                return this.statesList.length === 0 ? 'col-md-12' : 'col-md-6'
            },
        },
        methods: {
            changeMonth(event) {
                this.birthMonth = event.value.id;
            },
            changeCountry(event) {
                this.countryID = event.value.id;
            },
            changeState(event) {
                this.stateID = event.value.id;
            },
            updateSignerStore() {
                let obj = {
                    firstName: this.firstName,
                    middleName: this.middleName,
                    lastName: this.lastName,
                    dobMonth: parseInt(this.birthMonth),
                    dobDay: parseInt(this.birthDay),
                    dobYear: parseInt(this.birthYear),
                    city: this.city,
                    countryId: parseInt(this.countryID),
                    stateId: parseInt(this.stateID),
                    zipCode: this.zipCode,
                    Address: this.addressLine1,
                    emailAddress: this.emailAddress,
                };
                this.$store.commit("Invitation/updatesignerDetails", obj);

                let signerData = this.$store.getters["Auth/signerSessionInformation"];
                signerData.firstName = this.firstName;
                signerData.lastName = this.lastName;
                signerData.middleName = this.middleName;
                this.$store.commit("Auth/updateSignerSessionInfo", signerData);

                return obj;
            },
            async confirmDetails(event) {
                event.preventDefault();
                let hasErrors = this.v$.$error;
                if (hasErrors)
                    return false;

                // save to store
                let obj = this.updateSignerStore();
                // send to api too
                let response = await ApiService.GeneratePostRequest("verify/signer_details", obj);
                if (response["isSuccess"]) {
                    if (this.isRon) {
                        this.$router.push('/signer/setup');
                    } else {
                        let settings = this.$store.getters["App/appSettings"];
                        if (settings["skipSSN"]) {
							if (isMobile())
								this.$router.push({ path: `/signer/room-mobile` });
							else
								this.$router.push({ path: `/signer/room` });
                        } else {
                            this.$router.push('/signer/ssn');
                        }
                    }                    
                }
                return response["isSuccess"];
            },
            emitValidState() {
                this.v$.$touch();
                let hasErrors = this.v$.$error;
                this.formValid = hasErrors;
                console.log(hasErrors);
            }
        },
        watch: {
            async countryID(value) {
                if (value !== null && value !== "") {
                    let response = await ApiService.GenerateGetRequest(`Country/${value}/states`);
                    if (response != null && response.length > 0) {
                        this.statesList = response;
                        if (!this.firstLoad)
                            this.stateID = "";
                        else
                            this.firstLoad = false;
                    }
                    else {
                        this.stateID = -1;
                        this.statesList = [];
                    }
                }
                this.emitValidState();
            },
            // watch these variables for changes and verify form is valid - if not valid emit
            firstName(value) { this.emitValidState() },
            middleName(value) { this.emitValidState() },
            lastName(value) { this.emitValidState() },
            birthMonth(value) { this.emitValidState() },
            birthDay(value) { this.emitValidState() },
            birthYear(value) { this.emitValidState() },
            city(value) { this.emitValidState() },
            stateID(value) { this.emitValidState() },
            zipCode(value) { this.emitValidState() },
            addressLine1(value) { this.emitValidState() },
            addressLine2(value) { this.emitValidState() },
            emailAddress(value) { this.emitValidState() },
        },
        validations() {
            return {
                firstName: { required },
                lastName: { required },
                birthMonth: { required },
                birthDay: { required },
                birthYear: { required },
                city: { required },
                countryID: { required },
                stateID: { stateValidator },
                zipCode: { required },
                addressLine1: { required },
                emailAddress: { required, email },
            }
        }
    }
</script>
