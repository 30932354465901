import ApiService from "../../core/services/api-service";

export default {
    namespaced: true,
    state: () => ({
        isAuthenticated: false,
        token: null,
        invite :null,
        signerSessionInformation: {
            description: null,
            emailAddress: "",
            expirationDate: null,
            firstName: "",
            isValid: false,
            lastName: "",
            lender: "",
            notarizeId: 0,
            requesterId: 0,
            signerId: 0,
            scheduleDate: null,
            token: "",
            isDateSignable: false,
            middleName: "",
            clientID: 0
        },
        signingRoomData: {
            userId: 0,
            contextId: 0,
            notarizeId: 0,
            hist: ""
        },
    }),
    mutations: {
        // MUTATIONS - Any synchronous global state actions must be called with mutations.
        // I.E we might update Signer Information using a mutation but perform an ajax request which updates the token in an action
        updateSignerSessionInfo(state, payload) {
            state.signerSessionInformation.token = payload.token;
            state.signerSessionInformation.description = payload.description;
            state.signerSessionInformation.emailAddress = payload.emailAddress;
            state.signerSessionInformation.expirationDate = payload.expirationDate;
            state.signerSessionInformation.firstName = payload.firstName;
            state.signerSessionInformation.isValid = payload.isValid;
            state.signerSessionInformation.lastName = payload.lastName;
            state.signerSessionInformation.lender = payload.lender;
            state.signerSessionInformation.notarizeId = payload.notarizeId;
            state.signerSessionInformation.requesterId = payload.requesterId;
            state.signerSessionInformation.scheduleDate = payload.scheduleDate;
            state.signerSessionInformation.signerId = payload.signerId;
            state.signerSessionInformation.isDateSignable = payload.isDateSignable;
            state.signerSessionInformation.middleName = payload.middleName;
            state.signerSessionInformation.clientID = payload.clientID;
        },
        updateSigningRoomData(state, payload) {
            state.signingRoomData.userId = payload.userId;
            state.signingRoomData.contextId = payload.contextId;
            state.signingRoomData.notarizeId = payload.notarizeId;
            state.signingRoomData.hist = payload.hist;
        },
        updateInviteToken(state, payload) {
            state.invite = payload.invite;
        },
        signInSuccessful(state, payload) {
            state.token = payload.token;
            state.isAuthenticated = true;
            state.invite = payload.invite;
        },
        signOut(state) {
            state.token = null;
            state.isAuthenticated = null;
        },
        resetAuthState(state) {
            state.token = "";
            state.signerSessionInformation.token = "";
            state.signerSessionInformation.description = "";
            state.signerSessionInformation.emailAddress = "";
            state.signerSessionInformation.expirationDate = null;
            state.signerSessionInformation.firstName = "";
            state.signerSessionInformation.isValid = false;
            state.signerSessionInformation.lastName = "";
            state.signerSessionInformation.lender = "";
            state.signerSessionInformation.notarizeId = 0;
            state.signerSessionInformation.requesterId = 0;
            state.signerSessionInformation.scheduleDate =null;
            state.signerSessionInformation.signerId = 0;
            state.signerSessionInformation.isDateSignable = false;
            state.signingRoomData.userId = 0;
            state.signingRoomData.contextId = 0;
            state.signingRoomData.notarizeId = 0;
            state.signingRoomData.hist = "";
            state.signerSessionInformation.middleName = "";
            state.signerSessionInformation.clientID = 0;
        }
    },
    actions: {
        // ACTIONS - Any global state altering method must be called using an action
        // I.E we might update Signer Information using a mutation but perform an ajax request which updates the token in an action
        async authenticate({ commit }, payload) {
            let response = await ApiService.authenticate(payload);
            if (response["status"] === 200) {
                let data = response["data"];
                commit("signInSuccessful", {
                    token: data.token,
                });
            }
            return response;
        },
        signOut({ commit }) {
            commit("signOut");
        },
        async isUserLoggedIn() {
            let response = await ApiService.GenerateGetRequest("account/isUserLogin");
            console.log("Is Logged in Response", response);
            if (response["status"] === 200) {
            }
        }
    },
    getters: {
        userFullName: (state) => {
            return state.signerSessionInformation.firstName + " " + state.signerSessionInformation.lastName;
        },
        userFirstName: (state) => {
            return state.signerSessionInformation.firstName;
        },
        userLastName: (state) => {
            return state.signerSessionInformation.lastName;
        },
        userEmail: (state) => {
            return state.signerSessionInformation.emailAddress;
        },
        token: (state) => {
            return state.token;
        },
        invite: (state) => {
            return state.invite;
        },
        isLoggedIn: (state) => {
            return state.isAuthenticated;
        },
        signerSessionInformation: (state) => {
            return state.signerSessionInformation;
        },
        signingRoomData: (state) => {
            return state.signingRoomData;
        },
        clientId: (state) => {
            return state.signerSessionInformation.clientID;
        },
    }
}