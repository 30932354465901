<template>
  <div v-for="doc in wetSignDocs" :key="doc.documentId">
    <div class="row">
      <div class="col-sm-6" style="text-align:left">
        {{ doc.description }}
      </div>
      <div class="col-sm-6">
        <dropdownlist
          :data-item-key="'uid'"
          :text-field="'name'"
          :data-items="files"
          :value="doc.docFile"
          @change="handleChange"
          :name="doc.documentId"
          style="width:100%"
        >
        </dropdownlist>
      </div>
    </div>
  </div>
</template>
<script>
import { DropDownList } from "@progress/kendo-vue-dropdowns";

export default {
  props: ["files", "wetSignDocs", "uploadedDocs"],
  components: {
    dropdownlist: DropDownList,
  },
  created() {
    console.log("files", this.files);
    console.log("wet sign docs", this.wetSignDocs);
  },
  data: function() {
    return {
      files: this.files,
      wetSignDocs: this.wetSignDocs,
    };
  },

  methods: {
    handleChange(event) {
      this.$emit("mapFileToDoc", event.target.name, event.target.value);
    },
  },
};
</script>
