import ApiService from "../../core/services/api-service";

export default {
    namespaced: true,
    state: () => ({
        signerDetails: {
            firstName :"",
            middleName :"",
            lastName :"",
            birthMonth :"",
            birthDay :"",
            birthYear :"",
            city :"",
            countryID :"",
            stateID :"",
            zipCode :"",
            addressLine :"",
            emailAddress :"",
        },
        audioVideo: {
            Camera:""
        },
        isSigningEnabled: false,
        isRon: false,
    }),
    mutations: {
        updatesignerDetails(state, payload) {
            state.signerDetails.firstName = payload.firstName;
            state.signerDetails.middleName = payload.middleName;
            state.signerDetails.lastName = payload.lastName;
            state.signerDetails.birthMonth = payload.birthMonth;
            state.signerDetails.birthDay = payload.birthDay;
            state.signerDetails.birthYear = payload.birthYear;
            state.signerDetails.city = payload.city;
            state.signerDetails.countryID = payload.countryID;
            state.signerDetails.stateID = payload.stateID;
            state.signerDetails.zipCode = payload.zipCode;
            state.signerDetails.addressLine = payload.addressLine;
            state.signerDetails.emailAddress = payload.emailAddress;

        },
       updateCamera(state, payload) {
           state.audioVideo.Camera = payload.Camera;
        },
        resetInvitationState(state) {
            state.signerDetails.firstName = "";
            state.signerDetails.middleName = "";
            state.signerDetails.lastName = "";
            state.signerDetails.birthMonth = "";
            state.signerDetails.birthDay = "";
            state.signerDetails.birthYear = "";
            state.signerDetails.city = "";
            state.signerDetails.countryID = "";
            state.signerDetails.stateID = "";
            state.signerDetails.zipCode = "";
            state.signerDetails.addressLine = "";
            state.signerDetails.emailAddress = "";
            state.isSigningEnabled = false;
            state.isRon = false;
        },
        enableSigning(state, isSigningEnabled){
            state.isSigningEnabled = isSigningEnabled || false
        },
        enableRon(state, isRon) {
            state.isRon = isRon || false
        }
    },
    getters: {
        signerDetails: (state) => {
            return state.signerDetails;
        },

        isSigningEnabled: (state) => {
            return state.isSigningEnabled
        },

        isRon: (state) => {
            return state.isRon
        },
        isSigningEnabled: (state) => {
            return state.isSigningEnabled
        },
        
    }
}