<template>
    <popup :popup-class="'popup-content'" style="width:60vw;">
        <div id='eConsent' style="padding:1em;">
            <div class="row pb-m">
                <div class='col-lg-6'>
                    <h4>Edit Email Recipients</h4>
                </div>
                <div class='col-lg-6' style="text-align:right;">
                    <button @click="$emit('handleSaveEmailRecipients')" class="k-button k-primary" style="margin-right:1em;">
                        Save
                    </button>
                    <button @click="$emit('closeEmailRecipientsModal')" class="k-button k-primary" style="margin-right:1em;">
                        Cancel
                    </button>
                </div>
            </div>
            <br />
            <div class='col-md-12'>
                <button @click="$emit('addRecipient')" class="k-button k-primary" style="margin-right:1em; margin-bottom:1rem;">
                    Add
                </button>
                <table class="table table-bordered">
                    <tbody>
                        <tr v-for='(recicipient, index) in emailRecipients'>
                            <td>
                                <select v-model="recicipient.roleId" class="k-dropdown">
                                    <option v-for="role in systemRoles" :value="role.roleId">{{role.name}}</option>
                                </select>
                            </td>
                            <td>
                                <button @click="$emit('deleteRecipient', index)" class="k-button k-primary" style="margin-right:1em;">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </popup>
</template>

<script>
    import { Popup } from "@progress/kendo-vue-popup";
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'videosession',
        props: ['emailRecipients', 'systemRoles'],
        components: {
            'Popup': Popup,
            'kbutton': Button
        },
        data() {
            return {
            }
        },
        created() {
            console.log("this.emailRecipients", this.emailRecipients);
            console.log("this.systemRoles", this.systemRoles);
        },
        async mounted() {
        },
        beforeUnmount() {
        },
        data: () => ({
        }),
        methods: {},
    }
</script>

<style>
</style>
