<template>
    <div class="container">
        <div class="stepwizard">
            <div class="stepwizard-row setup-panel" style=" display: flex; justify-content: space-evenly;">
                <div v-for="(item, i) in pageHeaders" class="stepwizard-step">
                    <a href="#step" type="button" class="btn  btn-circle"
                       @click="stepClickedManually(i)"
                       :class="{'btn-success': i === currentStep, 'btn-default': i !== currentStep}" :disabled="i === currentStep">{{ i + 1}}</a>
                    <p>{{item}}</p>
                </div>
            </div>

        </div>
    </div>

    <div class="container-fluid">

        <slot name="content"></slot>

        <slot name="navigation"></slot>
    </div>

</template>

<script>
    import ApiService from "@/core/services/api-service";
    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';
    export default {
        name: 'Stepper',
        props: ["pageHeaders", "currentStep"],
        emits: ["stepClickedManually"],
        setup() {
            return { v$: useVuelidate() }
        },
        computed: {
            //colSize() {
            //    //12 is max num of bootstrap columns. turn magic number into const variable
            //    var n = Math.floor(12 / this.pageHeaders.length);
            //    return ''// `col-xs-${n}`;
            //},
            showSlotStep() {
                return `step-${this.currentStep}`;
            },
        },
        methods: {
            stepClickedManually(index) {
                this.$emit("stepClickedManually", index);
            }
        }
    }
</script>

<style scoped>

    .stepwizard-step p {
        margin-top: 0px;
        color: #666;
    }

    .stepwizard-row {
        display: table-row;
    }

    .stepwizard {
        display: table;
        width: 100%;
        position: relative;
    }

    .stepwizard-step button[disabled] {
        /*opacity: 1 !important;
    filter: alpha(opacity=100) !important;*/
    }

    .stepwizard .btn.disabled, .stepwizard .btn[disabled], .stepwizard fieldset[disabled] .btn {
        opacity: 1 !important;
    }

    .stepwizard-row:before {
        top: 14px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 100%;
        height: 1px;
        background-color: #ccc;
        z-index: 0;
    }

    .stepwizard-step {
        display: table-cell;
        text-align: center;
        position: relative;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        line-height: 1.428571429;
        border-radius: 15px;
    }

    .stepwizard-step a{
        cursor: pointer !important;
    }
</style>