window.url = require("./urls");
import axios from "axios";
import store from "../../store/index";
import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
import router from "@/router/index";

const URL = url.endPoint + "/api/";
export const loadFileUrl = url.endPoint + "/api/document/load?path=";
export const downloadFileUrl = url.endPoint + "/api/document/download?path=";
export const downloadRecordingUrl = url.endPoint + "/api/recording/download?filePath=";
const GlobalErrorHandler = function (err) {
    // HANDLE API ERRORS HERE like 403 / 401 - unauthorised. To log out client
    console.log(err);
    console.log(err.response);
    if (err && err.response && (err.response.status === 403 || err.response.status === 401)) {
        store.dispatch("Auth/signOut");
        store.dispatch("Admin/logout");
        store.dispatch("App/updateToken",null);
        Swal.fire({
            title: 'Login Expired',
            text: "Your login session has expired. Please login again",
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false 
        }).then(() => {
            store.reset();
            router.push('/Admin/login');
        });
    } else if (err.response.status === 400) {
        Swal.fire("Error", "One or more fields are invalid", "error");
        store.commit("App/updateLoader", { showLoader: false });
    }
    else {
        Swal.fire({
            text: "The system encountered an issue and was unable to process your request.",
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        store.commit("App/updateLoader", { showLoader: false });
        console.log("Server not available");
    }
}

class ApiService {
    static EnvironmentURL() {
        return URL;
    }

    static async authenticate(payload) {
        return await this.GeneratePostRequest("authorize", payload);
    }

    static async verifyToken(token) {
        return await this.GeneratePostRequest("verify", { token });
    }

    static async loginUser(payload) {
        return await this.GeneratePostRequest("account/login", payload);
    }

    static async getRef() {
        return await this.GenerateGetRequest("Account/GetRef");
    }

    static async GenerateRequest(endpoint, data = {}, config, headers = {}, loader = true) {
        console.log("ENDPOINT HIT: ", endpoint);
        if (!endpoint.includes("transaction/sendscrolltowitness") && !endpoint.includes("document/update/status/reviewed") && !endpoint.includes("package/canSign") && !endpoint.includes("signer/signingParticipants") && !endpoint.includes("verify/signerverification")) {
            if (loader) {
                console.log("SHOW LOADER: ", true);
                store.commit("App/updateLoader", { showLoader: headers.loader != undefined ? headers.loader : true });
            }
        } else {
            console.log("SHOW LOADER: ", false);
        }
        activeRequestsCount++;
        let req = { isSuccess: false, message: "There system encountered an issue and was unable to process your request." };
        try {
            req = await axios({
                url: URL + endpoint,
                headers: {
                    'Authorization': `Bearer ${store.getters["App/token"]}`,
                    ...headers
                },
                ...config
            });
        } catch (err) {
            GlobalErrorHandler(err);
        }
        completedRequests++;
        if (activeRequestsCount === completedRequests) {
            console.log("completed");
            activeRequestsCount = 0;
            completedRequests = 0;
            store.commit("App/updateLoader", { showLoader: false });
        } else { 
            console.log(activeRequestsCount, completedRequests);
        }

        if (req && req["data"])
            req = req["data"];

        return req;
    }

    static async GenerateGetRequest(endpoint, data = {}) {
        return await this.GenerateRequest(endpoint, data, { method: 'get', params: data })
    }

    static async GenerateGetBlobRequest(endpoint, data = {}) {
        return await this.GenerateRequest(endpoint, data, { method: 'get', params: data, responseType: "blob" })
    }

    static async GeneratePostRequest(endpoint, data = {}, headers) {
        return await this.GenerateRequest(endpoint, data, { method: 'post', data: data }, headers)
    }

    static async GenerateNonLoadingPostRequest(endpoint, data = {}, headers) {
        return await this.GenerateRequest(endpoint, data, { method: 'post', data: data }, headers, false)
    }

    static async GeneratePostBlobRequest(endpoint, data = {}, headers) {
        return await this.GenerateRequest(endpoint, data, { method: 'post', data: data, responseType: "blob" }, headers)
    }
    static async GeneratePutRequest(endpoint, data = {}, headers) {
        return await this.GenerateRequest(endpoint, data, { method: 'put', data: data }, headers)
    }

    static async GenerateNonLoadingGetRequest(endpoint, data = {}) {
        return await this.GenerateRequest(endpoint, data, { method: 'get', params: data }, {}, false)
    }
}

export default ApiService;