<template>
  <span class="k-input">
    <span>{{ value.firstName + " " + value.lastName }}</span>
  </span>
</template>
<script>
export default {
  props: {
    value: String,
  },
};
</script>
