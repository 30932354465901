<template>
  <router-link-bread-crumbs :navItems="navItems" />
  <h3>Admin Reports</h3>
  <hr class="solid" />
  <Grid
    ref="grid"
    :data-items="reportData"
    :columns="columns"
    :total="total"
    :take="take"
    :skip="skip"
    :pageable="true"
    @pagechange="pageChangeHandler"
    @rowclick="onRowClick"
    :resizable="true"
  >
    <grid-norecords>
      There is no data available custom
    </grid-norecords>
  </Grid>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import ApiService from "@/core/services/api-service";
import moment from "moment";
import { loadFileUrl } from "@/core/services/api-service";
import { Button } from "@progress/kendo-vue-buttons";
import ReportTable from "./table.vue";
import RouterLinkBreadCrumbsVue from "@/components/RouterLinkBreadCrumbs.vue";

export default {
  name: "AdminReportsListing",
  props: [],
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    "k-button": Button,
    "report-table": ReportTable,
    RouterLinkBreadCrumbs: RouterLinkBreadCrumbsVue,
  },
  computed: {
    reportData: {
      get: function() {
        return this.reports.slice(this.skip, this.take + this.skip);
      },
    },
    total() {
      return this.reports ? this.reports.length : 0;
    },
    moment: () => moment,
    columns() {
      return [...this.staticColumns];
    },
  },
  data: function() {
    return {
      skip: 0,
      take: 20,
      staticColumns: [
        {
          field: "reportId",
          editable: false,
          hidden: true,
        },
        {
          field: "name",
          title: "Name",
          width: 400,
          className: "report-clickable report-name",
        },
        {
          field: "description",
          title: "Description",
          className: "report-clickable",
        },
      ],
      reports: [],
      host: loadFileUrl,
      selectedField: "selected",
      navItems: [
        {
          id: 1,
          label: "Home",
          url: "/admin",
          icon: "home",
        },
        {
          id: 2,
          label: "Admin Reports",
          icon: "graph",
        },
      ],
    };
  },
  async created() {
    let response = await ApiService.GenerateGetRequest("admin/reports-listing");
    if (response && response["isSuccess"]) {
      console.log("response docs", response["content"]);
      this.reports = response["content"];
    }
  },
  methods: {
    pageChangeHandler: function(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    onRowClick(event) {
      this.$router.push({
        name: "AdminReportTable",
        params: {
          reportId: event.dataItem.id,
          reportName: event.dataItem.name,
        },
      });
    },
  },
};
</script>
<style>
.select-counter {
  color: #3f51b5;
  font-weight: 900;
}

/* Solid border */
hr.solid {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-left: -16px;
  margin-left: -16px;
}

.report-clickable {
  cursor: pointer !important;
}

.report-name {
  font-weight: bold !important;
  color: #4d97ac;
}
.report-name:hover {
  text-decoration: underline;
}
</style>
