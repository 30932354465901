<template>
    <div class="col-center">
        <div id="loginForm">
            <div class="formarea">
                <h2>NEW PASSWORD </h2>
                <div class="group">
                    <label>Hi {{fullname}}</label>
                    <br />
                </div>
                <div class="group">
                    <label v-show="formerrormsg">{{formerrormsg}}</label>
                </div>
                <form @submit.prevent="handleSubmit"
                      v-show="showForm">
                    <div class="group">
                        <label htmlFor="password">Password</label>
                        <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                        <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                        <div v-show="passwordmsg" class="invalid-feedback">{{passwordmsg}}</div>
                    </div>
                    <div class="group">
                        <label htmlFor="verifypassword">Verify Password</label>
                        <input type="password" v-model="verifypassword" name="verifypassword" class="form-control" :class="{ 'is-invalid': submitted && !verifypassword }" />
                        <div v-show="submitted && !verify-password" class="invalid-feedback">Password is required</div>
                    </div>
                    <div class="group">
                        <label v-show="submitted && errormsg">{{errormsg}}</label>
                    </div>
                    <div class="group loginbutton">
                        <button class="button login">Set New Password</button>
                    </div>
                </form>
                <div class="hr"></div>
                <div class="group policy">
                    If you are experiencing technical problems, please contact<br />
                    <span><a href="https://signiahelp.zendesk.com/hc/en-us">Stavvy Inc.</a></span>.
                    <br /><br />
                    &copy;{{new Date().getFullYear()}} Stavvy Inc. - <span class="ref">Ref. #{{ref}} </span>
                </div>
            </div>
        </div>
    </div>
    <section class="block-loader ctnr" v-if="showLoader">
        <div class="ldr">
            <div class="ldr-blk"></div>
            <div class="ldr-blk an_delay"></div>
            <div class="ldr-blk an_delay"></div>
            <div class="ldr-blk"></div>
        </div>
    </section>
</template>

<style scoped>
/* Login Page */
.col-center {
    align-items: stretch;
    box-sizing:border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    line-height: 19.999px;
    margin-bottom: -14px;
    margin-left: -14px;
    margin-right: -14px;
    margin-top: -14px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-size-adjust: 100%;
    width: 100%;
    -webkit-box-align: stretch;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-box-pack: center;
}

    #loginForm {
        align-self: center;
        background-attachment: scroll;
        background-clip: border-box;
        background-color: #FFF;
        background-image: none;
        background-origin: padding-box;
        background-size: auto;
        box-sizing: border-box;
        color: rgb(106, 111, 140);
        display: block;
        flex-direction: column;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-stretch: 100%;
        font-style: normal;
        font-variant-caps: normal;
        font-variant-east-asian: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-weight: 600;
        height: 713px;
        line-height: 18px;
        max-width: 560px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        position: relative;
        text-align: left;
        text-size-adjust: 100%;
        text-transform: uppercase;
        vertical-align: middle;
        width: 560px;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -ms-flex-direction: column;
        flex-direction: column;
        vertical-align: middle;
        -ms-flex-item-align: center !important;
        align-self: center !important;
        border: 1px solid #ccc;
    }

h2 {
    font: 600 16px/18px 'Arial','Open Sans',sans-serif;
    font-size: 22px;
    color: #000;
    padding-bottom: 5px;
    border-bottom: 10px solid  #CCC;
    width: 78px;
}

label {
    margin-bottom:0px;
}

form {
    font-size: 1.14285714rem;
    position: relative;
    max-width: 100%;
    margin:14px 0 0 0 ;
}

h2:after {
}

#loginForm .formarea {
    padding: 90px 70px 50px 70px;
}

#loginForm label {
    color: #000;
    display: block;
    float: left;
    font: 600 16px/18px 'Arial','Open Sans',sans-serif;
    font-size: 12px;
}

#loginForm .backlink a {
    text-transform: none;
    font-size: 80%;
    font-weight: 300;
    color: #dcdada;
}

#loginForm label, #loginForm h4, #loginForm div.group.reset a {
    color: #000;
}

#loginForm div.group.policy a {
    color: #629afd;
}

input, button {
    border: none;
    padding: 15px 20px;
    border-radius: 5px;
    background: rgba(255,255,255,0.1);
    width: 100%;
    height:44px;
    max-width: 100%;
    font: 400 13.3333px Arial;
}

    input {
        border: 1px solid #CCC;
    }

    .group .button {
        background:#9747FF;
        border: none;
        color: #fff;
        text-transform: uppercase;
    }

input .input-validation-error, button .input-validation-error {
    background: rgb(170, 51, 51);
}

.group {
    margin-bottom: 15px;
}

.group.loginbutton {
    margin-top: 70px;
}

    .group .button {
        background: #9747FF;
        border: none;
        color: #fff;
        text-transform: uppercase;
    }

.hr {
    height: 2px;
    margin: 60px 0 50px 0;
    background: rgba(255,255,255,.2);
}

.darkmode{
    color:white;
}

span.field-validation-error {
    display: inline-block;
    margin: 0;
    float: right;
    text-align: right;
}

.group.reset {
    text-transform: none;
    font-size: 80%;
    font-weight: 400;
    color: #D4D4D5;
    text-align: center;
}

    .group.policy {
        text-transform: none;
        font-size: 80%;
        font-weight: 400;
        color: #000;
        text-align: center;
    }

.group.policy .ref {
    color: #000;
}

    .invalid-feedback {
        color: red;
    }

/* End Reset Password Page */
</style>
<script>
    import ApiService from "@/core/services/api-service";
    import router from "@/router/index";
    import Swal from "sweetalert2";

    export default {
        name: 'AdminPassword',
        components: {
        },
        data() {
            return {
                verifypassword: '',
                password: '',
                submitted: false,
                ref: '',
                errormsg: '',
                formerrormsg: '',
                showLoader: false,
                email: '',
                resetToken: "",
                showForm: false,
                fullname: "",
                passwordmsg:""

            }
        },
        watch: {
            password(value) {
                this.password = value;
                this.checkPassword(value);
            }
        },
        async created() {
            document.body.id = "login";
            let { t: token } = this.$route.params;
            this.resetToken = token;
            console.log("this is a token", this.resetToken);
            var resetResponse = await ApiService.GenerateGetRequest(`admin/GetUserByToken?identitytoken=${this.resetToken}`);
            if (resetResponse && resetResponse["isSuccess"]) {

                this.showForm = true;
                this.email = resetResponse.content.email;
                
                this.fullname = resetResponse.content.firstName + " " + resetResponse.content.lastName;
                console.log(this.fullname, " ", this.email);
            } else {
                this.formerrormsg="This link is invalid."
            }
        },
        methods: {
            handleSubmit(e) {
                this.submitted = true;
                
                const { email, password, resetToken } = this;
                console.log(this.password + " " + this.email);
                if (this.verifypassword && this.password && this.verifypassword == this.password && !this.passwordmsg) {
                    this.showLoader = true;
                    return ApiService.GeneratePostRequest("Admin/ChangePassword", { 'Email': email, 'Password': password, 'Token': resetToken }).then(
                        async response => {
                            if (response.isSuccess == true) {
                                this.showLoader = false;
                                this.$store.commit("Auth/signOut");
                                this.$store.commit("Admin/logout");
                                this.$store.commit("App/updateToken",null);
                                Swal.fire({
                                    title: 'Password Reset',
                                    text: 'Password reset successfully',
                                    icon: 'success',
                                    confirmButtonText: 'Ok'
                                });
                                router.push("/Admin/login");
                            } else {
                                this.errormsg = response.message;
                                this.showLoader = false;
                            }
                        }
                    );
                }
                else {
                    this.errormsg = "Passwords must match"
                }
                this.showLoader = false;
            },
            checkPassword(value) {
                let msg = "";
                var isGood = true;
                if (value.length < 8) {
                    msg = " 8 characters";
                    isGood = false;
                }
                if (!(/[A-Z]/.test(value))) {
                    msg = msg + "  one uppercase letter";
                    isGood = false;
                }
                if (!(/[0-9]/.test(value))) {
                    msg = msg + "  one number";
                    isGood = false;
                }

                if (!isGood) {
                    this.passwordmsg = "Password must contain at least" + msg;
                } else {
                    this.passwordmsg = ""
                }
            },
        },
        async mounted() {
            //var response = await ApiService.getRef();
            //this.ref = response;
        },
        
    }
</script>