import ApiService from "../../core/services/api-service";
import store from "../../store/index";
import router from "@/router/index";

export default {
    namespaced: true,
    state: () => ({
        loginInformation: null,
        signersConnected: []
    }),
    mutations: {
        // MUTATIONS - Any synchronous global state actions must be called with mutations.
        updateLoginInfo(state, payload) {
            state.loginInformation = {};
            state.loginInformation.token = payload.token;
            state.loginInformation.firstName = payload.firstName;
            state.loginInformation.lastName = payload.lastName;
            state.loginInformation.emailAddress = payload.emailAddress;
            state.loginInformation.role = payload.role;
            state.loginInformation.permissions = payload.permissions;
            state.loginInformation.hasAccessToMenu = payload.hasAccessToMenu;
            state.loginInformation.userId = payload.userId;
            state.loginInformation.clientId = payload.clientId;
            state.loginInformation.clientName = payload.clientName;
            state.loginInformation.additionalRoles = payload.additionalRoles
        },
        switchRole(state, payload) {
            state.loginInformation.role = payload.role;
            state.loginInformation.permissions = payload.permissions;
            state.loginInformation.hasAccessToMenu = payload.hasAccessToMenu;
            state.loginInformation.additionalRoles = payload.additionalRoles
        },
        updateMenuAccess(state, payload) {
            state.loginInformation.hasAccessToMenu = payload.hasAccessToMenu;
        },
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
            state.token = user.token;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
            state.token = null;
            state.loginInformation = null;
            state.signersConnected = [];
        },
        updateSignerConnections(state, payload) {
            state.signersConnected = payload;
        }  
    },
    actions: {
        // ACTIONS - Any global state altering method must be called using an action
        // I.E we might update Signer Information using a mutation but perform an ajax request which updates the token in an action
        async loginUser({ commit }, payload) {
            console.log(payload);
            let response = await ApiService.loginUser(payload);
            if (response.isSuccess) {
                let content = response.content;
                if (content.token != null) {
                    store.commit("App/updateToken", content.token);
                    store.commit("Admin/updateLoginInfo", content);
                    commit("loginSuccess", content);
                }
                return response;
            } else {
                commit("loginFailure", response.message);
                return response;
            }

        },
        async logout({ commit }) {
            commit('logout');
        },
        async isLoggedIn() {
            let response = await ApiService.GenerateGetRequest("account/isUserLogin");
            console.log("Is Logged in Response", response);
            if (response["status"] === 200) {
            }
        },
        addSignerConnection(context, payload) {
            const signerConnections = context.state.signersConnected;
            signerConnections.push(payload)
            context.commit('updateSignerConnections', signerConnections)
        }
    },
    getters: {
        token: (state) => {
            return state.token;
        },
        permission: (state) => (name) => {
            return state.loginInformation.permissions.find(perm => perm.permissionName == name);
        },
        pagePermissions: (state) => (prefix) => {
            return state.loginInformation.permissions.filter(perm => perm.permissionName.indexOf(prefix) >= 0);
        },
        signerSessionInformation: (state) => {
            return state.signerSessionInformation;s
        },

        loginInformation: (state) => {
            return state.loginInformation;
        },
        hasAccessToMenu: (state) => {
            if (state.loginInformation != null) { return state.loginInformation.hasAccessToMenu; }
            else { return true; }
        },
        signersConnected: (state) => {
            return state.signersConnected
        },
    }
}