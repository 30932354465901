<template>
    <div class="row">
        <div class="innerbox col-md-12" style=" text-align: center; padding-top: 10%; }">
            <div class="checkmark"></div>
            <h2>{{ message ? message : 'Process Completed Successfully'}}</h2>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api-service";
    export default {
        name: 'StepperFormCompleted',
        props: ["message"],
        emits: ["componentValid"],
        methods: {
            async isValid() {
                return true;
            }
        }
       
    }
</script>

<style scoped>

</style>
