<template>

    <section style="margin-bottom: 50px">
        <div class="container personal-signup" style="min-height:unset; width: 100%" >
            <div class="col-md-offset-4 col-md-4 col-xs-12">
                <div class="bdr-rad-10 innerbox darkblue-bg" style="min-height:unset">
                    <h2>Signed Out</h2>                
                    <p>To login please use the invitation link from your email.</p>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
    export default {
        name: 'SignOut',
    }
</script>