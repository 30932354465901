
<template>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container" id="test-box" style="border-radius:0px;">
            <div class="row row-flex">
                <div class="col-xs-12 col-md-6">
                    <h4 class="text-center" style="padding-bottom: 1rem">Test Video, Audio and Internet Connection</h4>
                    <hr style="color: #e9e9e9"/>
                </div>
            </div>
            <div class="row row-flex bdr-right">
                <div class="innerbox col-md-6 column-flex">
                    <div class="col-md-12 text-center" style="margin-top:2rem">
                        <!-- test camera -->
                        <testcamera @camID="getCamera" class="component-box" />

                        <!-- test audio -->
                        <testaudio @micID="getMicrophone" @speakerID="getSpeaker" class="component-box"/>

                        <!-- test internet -->
                        <testinternetconnection class="component-box"/>

                        <testlocation class="component-box" />
                    </div>

                </div>
            </div>
        </div>

    </section>
</template>

<style scoped>
    .bdr {
        background: #fff;
        border: none;
    }
</style>
<script>

    import TestAudio from '@/components/forms/invitation-steps/test/TestAudio.vue';
    import TestAudioVideo from '@/components/forms/invitation-steps/test/TestAudioVideo.vue';
    import TestCamera from '@/components/forms/invitation-steps/test/TestCamera.vue';
    import TestHardwareInstructions from '@/components/forms/invitation-steps/test/TestHardwareInstructions.vue';
    import TestInternetConnection from '@/components/forms/invitation-steps/test/TestInternetConnection.vue';
    import TestLocation from '@/components/forms/invitation-steps/test/TestLocation.vue';
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'Setup',
        components: {
            "k-button": Button,
            testaudio: TestAudio,
            testaudiovideo: TestAudioVideo,
            testcamera: TestCamera,
            testhardwareinstructions: TestHardwareInstructions,
            testinternetconnection: TestInternetConnection,
            testlocation: TestLocation,
        },
        data() {
            return {
                selectedCam: "default",
                selectedMic: "default",
                selectedSpeaker: "default",
                location: "",
            }
        },
        async mounted() { },
        methods: {
            getCamera(value) {
                console.log("getCamera: ", value);
                if (value != "") {
                    this.selectedCam = value;
                    this.$emit("camID", this.selectedCam);
                }
            },
            getMicrophone(value) {
                console.log("getMicrophone: ", value);
                if (value != "") {
                    this.selectedMic = value;
                    this.$emit("micID", this.selectedMic);
                }
            },
            getSpeaker(value) {
                console.log("getSpeaker: ", value);
                if (value != "") {
                    this.selectedSpeaker = value;
                    this.$emit("speakerID", this.selectedSpeaker);
                }
            },
            getLocation(value) {
                console.log("getLocation: ", value);
                if (value != "") {
                    this.location = value;
                    this.$emit("location", this.location);
                }
            }
        }
    }

</script>