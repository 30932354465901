<template>
    <section class="question-page">
        <div class="">
            <div class="row">
                <div class="col-md-12" style="text-align:right;">
                    <span >{{attempts}} attempts remaining</span>
                </div>
            </div> 
            <br />
            <div class="row">
                <div class="col-md-12">
                    <span class="close-icon position-right" id="Timer">{{seconds}} seconds remaining</span>
                </div>
            </div>


            <form>
                <div style="margin-top:100px;" class="col-md-8 col-md-offset-2 text-center" v-if="!questions && !questionListError && !retryQuestions">
                    <h1>Hang On</h1>
                    <h3>Loading Questions</h3>
                </div>
                <div style="margin-top:100px;" class="col-md-8 col-md-offset-2 text-center" v-if="questionListError">
                    <h3>{{questionListErrorMsg}}</h3>
                    <br />
                    <div v-if="notaryDetails">
                        <h3 v-if="notaryDetails.name">Notary Officer: {{notaryDetails.name}}</h3>
                        <h3 v-if="notaryDetails.email">{{notaryDetails.email}}</h3>
                        <h3 v-if="notaryDetails.phoneNumber">{{notaryDetails.phoneNumber}}</h3>
                    </div>
                </div>
                <div style="margin-top:100px;" class="col-md-8 col-md-offset-2 text-center" v-if="retryQuestions">
                    <h1>Oops</h1>
                    <h3><span v-if="usermsg">{{usermsg}}</span></h3>
                    <input @click.prevent="restart" value="RETRY" class="btn-secondary">
                </div>
                <div class="col-md-8 col-md-offset-2 text-left" v-for="(question, i) in questions" v-if="questions && !retryQuestions">
                    <br><br>
                    <h3>Question {{i + 1}} of {{questions.length}}</h3>

                    <p>
                        {{question["questionText"]}}
                    </p>
                    <hr>
                    <template v-for="answer in question['answers']">
                        <label>
                            <input @change="questionAnswerSelected($event, question['id'], i)" :name="question['id']" :ref="question['id']+answer['answer']" :id="question['id']+answer['answer']" type="radio" :value="answer['answer']"> {{answer['answer']}}<br>
                        </label>
                        <br>
                    </template>
                </div>

                <div style="margin-top:100px;" class="col-md-8 col-md-offset-2 text-center" v-if="errorMessage != ''">
                    <p class="text-danger">{{errorMessage}}</p>
                </div>
                <div v-if="questions && !retryQuestions && !questionListError">
                    <div class="col-md-8 col-md-offset-2">
                        <k-button :primary="true" @click.prevent="submitQuestions" style="margin-top:1rem; margin-bottom:1rem;">SUBMIT</k-button>
                    </div>
                </div>
            </form>
        </div>

    </section>
</template>

<script>

    import ApiService from "@/core/services/api-service";
    import { Button } from "@progress/kendo-vue-buttons";
    import CookieService from "@/core/services/cookie-service";
    const SECONDS_TIMER = 120;
    export default {
        name: 'VerifyQuestions',
        emits: ["questionsAnswered", "componentValid"],
        props: ["preloadedData"],
        components: { "k-button": Button, },
        data() {
            return {
                questions: [
                ],
                seconds: SECONDS_TIMER,
                retryQuestions: false,
                questionListError: false,
                questionListErrorMsg: "",
                interval: null,
                errorMessage: "",
                usermsg: "",
                attempts: null,
                isRon: this.$store.getters["Invitation/isRon"] || false,
                notaryDetails: null
            }
        },
        async created() {
            console.log("ISRON: ", this.isRon);

            console.log("called created");
            //this.fetchQuestions()
            //    .then(() => {
            //        console.log("TIME STARTED");
            //        this.startTimer();
            //    })
            //    .catch(() => {
            //        // error callback function
            //    });
            //this.startTimer();
            
        },
        async mounted() {
            console.log("called mounted");
            this.usermsg = "";
            if (this.preloadedData !== null)
                this.questions = this.preloadedData;

            this.checkAttempts();

            this.questions = JSON.parse(localStorage.getItem('kbaQuestions'));

            if (this.questions) {
                for (let i = 0; i < this.questions.length; i++) {
                    let q = this.questions[i];
                    if (q["selectedAnswer"] != null) {
                        //set prefilled answers                   
                        for (var a in q["answers"]) {
                            var elID = q['id'] + q["selectedAnswer"];
                            if (q["selectedAnswer"] == q["answers"][a]["answer"])
                                this.$refs[elID].checked = true;
                        }
                    }
                }
            }
                       
        },
        async unmounted() {
            clearInterval(this.interval);
        },
        methods: {
            async isValid() {
                return true;
                console.log("is valid");
                return this.submitQuestions();
            },
            questionAnswerSelected(event, questionId, questionIndex) {
                var selectedAnswer = event.target.value;
                this.questions[questionIndex]["selectedAnswer"] = selectedAnswer;
                localStorage.setItem('kbaQuestions', JSON.stringify(this.questions));

                this.$emit("componentValid", this.isAllQuestionsAnswered());
            },
            isAllQuestionsAnswered() {
                let isValid = true;

                for (let i = 0; i < this.questions.length; i++) {
                    let q = this.questions[i];
                    if (q["selectedAnswer"] === null) {
                        isValid = false;
                        break;
                    }
                }

                return isValid;
            },
            async submitQuestions() {
                this.errorMessage = "";
                console.log(this.isAllQuestionsAnswered());
                if (!this.isAllQuestionsAnswered()) {
                    // show ui error
                    this.errorMessage = "Please complete all questions.";       
                    return false;
                }
                // submit questions for verification
                let response = await ApiService.GeneratePostRequest("verify/answers", this.questions);

                if (response["isSuccess"] && response["content"]) {
                    localStorage.removeItem('kbaQuestions', this.questions);
                    this.$router.push("/signer/photoupload");

                    /*if (this.isRon) {
                        this.$router.push("/signer/photoupload");
                    } else {
                        this.$router.push("/signer/room");
                    }*/
                }

                this.usermsg = "Oops, one of more of your answers are incorrect."
                this.retryQuestions = true;
                return false;
            },

            async startTimer(seconds) {
                if (this.interval)
                    clearInterval(this.interval);
                let cookie = CookieService.Get("qst"); //cookie name qst
                if (cookie === "") {
                    this.seconds = SECONDS_TIMER;
                    CookieService.Create("qst", this.seconds);
                } else {
                    this.seconds = seconds || parseInt(cookie);
                }
                this.interval = setInterval(() => {
                    this.seconds -= 1;
                    CookieService.Update("qst", this.seconds);
                    if (this.seconds === 0) {
                        clearInterval(this.interval);
                        this.logTimeout();
                        this.retryQuestions = true;
                        this.usermsg = "Oops, your session has timed out."
                        CookieService.Delete("qst", this.seconds);
                        
                    }
                }, 1000);
            },
            async logTimeout() {
                await ApiService.GeneratePostRequest("verify/timeout");
            },
            async fetchQuestions() {
                this.questionListError = false;
                if (localStorage.getItem("kbaQuestions") === null) {
                    let response = await ApiService.GenerateGetRequest("verify/questions");
                    if (response["isSuccess"]) {
                        this.questions = response["content"];
                        console.log("questions", this.questions);
                        localStorage.setItem('kbaQuestions', JSON.stringify(this.questions));
                        this.startTimer();                        
                    } else {
                        this.questionListError = true;
                        //this.questionListErrorMsg = "We're having trouble finding your verification questions. Try again later or contact the site administrator.";
                        this.questionListErrorMsg = response["message"];
                    }
                }
                else {
                    this.questions = JSON.parse(localStorage.getItem('kbaQuestions'));
                }
            },
            async checkAttempts() {
                //check for attempts
                await ApiService.GeneratePostRequest("verify/attempts").then(response => {
                    console.log(response);
                    if (response["isSuccess"]) {
                        this.attempts = 2 - response["content"];
                        console.log("attempts ", this.attempts);
                        if (this.attempts <= 0) {
                            console.log("max attempts exceeded");
                            this.questionListError = true;
                            this.questionListErrorMsg = "Maximum number of attempts exceeded. Please contact your Notary Officer";

                            ApiService.GeneratePostRequest("notarize/contactdetails").then(contactResponse => {
                                if (contactResponse["isSuccess"]) {
                                    this.notaryDetails = contactResponse["content"];
                                    console.log('Notary details', this.notaryDetails);
                                }
                            });
                        } else {

                            this.fetchQuestions(SECONDS_TIMER);
                        }
                    };
                });
            },
            async restart() {
                this.retryQuestions = false;
                localStorage.removeItem('kbaQuestions');
                this.questions = [];
                this.checkAttempts();
            }
        },
    }
</script>
