window.url = require("./core/services/urls");
import OT from '@opentok/client';
import ApiService from "@/core/services/api-service";

var session = null;
var publisher = null;
var subscriber = null;
var screen = null;
var apiKey = null;
var sessionId = null;
var token = null;
var subList = [];
var subscriberScreen = null;

export const videoConnection = async (notarizeId, subscriberDiv, screenDiv, callback, type) => {
    subscriber = null;
    var getOTSession = await ApiService.GenerateGetRequest("notarize/session/get/" + notarizeId);


    // If a session has not been created for a notarize id, then create one; else, return the existing session data
    if (getOTSession["content"] != null) {
        console.log("getOTSessionfromDB", getOTSession);
        apiKey = getOTSession["content"].apiKey;
        sessionId = getOTSession["content"].sessionId;
        token = getOTSession["content"].token;
    } else {
        var response = await ApiService.GenerateGetRequest("notarize/session/create/" + notarizeId);
        if (response["isSuccess"]) {
            apiKey = response["content"][0];
            sessionId = response["content"][1];
            token = response["content"][2];
        } else {
            alert('Failed to get opentok sessionId and token.');
        }
    }

    session = OT.initSession(apiKey, sessionId);

    session.connect(token, err => {
        if (err) {
            handleError(err);
        }
    });

    console.log("session connected from OTC ", notarizeId, session);

    session.on('streamCreated', event => {

        console.log("sublist before stream ", subList);

        if (type == "journal") {
            console.log("connecting to journal", subscriberDiv, (event.stream.name).replace(/\s/g, ""));

            //check if new subscriber & add to subscriberDiv list
            if (subscriberDiv.filter(e => e.name == 'journalSubscriber-'+(event.stream.name).replace(/\s/g, "")).length == 0) {
                console.log("new subscriber, add to list");
                subscriberDiv.push({ name: 'journalSubscriber-' + (event.stream.name).replace(/\s/g, ""), height: '99%', width: '99%' });
            }

            for (var x = 0; x < subscriberDiv.length; x++) {
                if (subList.filter(e => e.subscriber == subscriberDiv[x].name).length == 0 && (event.stream.name).replace(/\s/g, "") == (subscriberDiv[x].name).substring('journalSubscriber-'.length)) {
                    subList.push({ "stream": event.stream, "name": event.stream.name, "subscriber": subscriberDiv[x].name });
                    subscriber = session.subscribe(event.stream, subscriberDiv[x].name, { insertMode: 'append', height: subscriberDiv[x].height, width: subscriberDiv[x].width });
                    console.log("after video stream created event OTSession. This is the event stream", subscriber, subscriberDiv[x].name);
                } 
            }
        }
        else {
        //filter out the stream id
            var filteredList = subList.filter(e => e.stream.id === event.stream.id);
            console.log("filtered list before doing anything ", filteredList);

            if (event.stream.videoType == "screen" && type == 'Notary') {
                var i = 0;
                while (i < subList.length) {
                    if (subList[i].subscriber === screenDiv) {
                        if (subscriberScreen != null) {
                            session.unsubscribe(subscriberScreen);
                        }
                        subList.splice(i, 1);
                        console.log("remove stream from list");
                    } else {
                        i++;
                    }
                }
                console.log("screenshare list ", filteredList,"screenshare div", screenDiv);
                subList.push({ "stream": event.stream, "name": event.stream.name, "subscriber": screenDiv });
                callback("showPopup");
                subscriberScreen = session.subscribe(event.stream, screenDiv, { insertMode: 'append', height: '85%', width: '100%' });
                console.log("after screen stream created event OTSession. This is the event stream", subscriber, screenDiv);

            } else if (event.stream.videoType == "camera" && filteredList.filter(e => e.subscriber == subscriberDiv.name).length == 0){
                subList.push({ "stream": event.stream, "name": event.stream.name, "subscriber": subscriberDiv.name });
                subscriber = session.subscribe(event.stream, subscriberDiv.name, { insertMode: 'append', height: subscriberDiv.height, width: subscriberDiv.width });
                console.log("after video stream created event OTSession. This is the event stream", subscriber, subscriberDiv.name);
            }

        }
        
        console.log("sublist after stream created", subList);
    });

    session.on('streamDestroyed', event => {
        console.log("before stream destroyed ", event, "list ", subList);
        var i = 0;
        while (i < subList.length) {
            if (subList[i].stream.id === event.stream.id) {
                subList.splice(i, 1);
                console.log("remove stream from list");
            } else {
                i++;
            }
        }
    });


}

export const publishVideo = (publisherDiv, name) => {
    if (publisher != null) {
        session.unpublish(publisher);
    }

    publisher = OT.initPublisher(publisherDiv.name, {
        insertMode: 'append',
        width: publisherDiv.width,
        height: publisherDiv.height,
        name: name,
    }, handleError);
    
    session.disconnect();
    session.connect(token, err => {
        if (err) {
            handleError(err);
        }
        session.publish(publisher);
    });

    console.log("session after publisher ", session, publisher, publisherDiv);

}

export const publishScreen = (screenshareDiv, name, callback) => {
    if (screen != null) {
        session.unpublish(screen);
    }
    console.log("screenshare div", screenshareDiv);
    screen = OT.initPublisher(screenshareDiv, {
        name: name,
        insertMode: 'append',
        videoSource: 'screen',
        width: '400px',
        height: '350px',
    }, handleError);

    if (session != null && session.isConnected()) {
        console.log("publishing sharescreen");
        session.publish(screen);
    }

    screen.on('streamDestroyed', event => {
        console.log("before screen destroyed ", event);
        callback("stopSharing");
    });

    console.log("finish screen publish");
}

export const disconnectVideo = () => {
    disconnectSession();
}

function handleError(error) {
    if (error) {
        console.error(error);
        alert(error);
    }
}

function disconnectSession() {
    console.log("DISCONNECTING THIS SESSION", session);
    if (publisher != null) {
        console.log("unpublishing publisher", publisher, "session ", session);
        session.unpublish(publisher);
        publisher.destroy();
        console.log("after unpublish");
    }
    if (screen != null) {
        console.log("unpublishing screen", screen);
        session.unpublish(screen);
    }
    if (subscriber != null) {
        console.log("unsubscribing subscriber", subscriber);
        session.unsubscribe(subscriber);
    }
    if (subscriberScreen != null) {
        console.log("unsubscribing screen", subscriberScreen);
        session.unsubscribe(subscriberScreen);
    }
    if (session != null) {
        session.off();
        session.disconnect();
        console.log("after session disconnect");
    }
    
    publisher = null;
    subscriber = null;
    screen = null;
    subList = [];
    subscriberScreen = null;
    
}

export const stopSharescreen = () => {
    if (screen != null) {
        session.unpublish(screen);

    }
}