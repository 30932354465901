<template>
    <div>Subscriber</div>
</template>

<script>
    import OT from '@opentok/client';
    export default {
        name: 'subscriber',
        props: {
            stream: {
                type: OT.Stream,
                required: true
            },
            session: {
                type: OT.Session,
                required: true
            },
            opts: {
                type: Object,
                required: false
            }
        },
        mounted: function () {
            console.log("subscriber mounted from subscriber vue");

            if (this.session && this.stream) {
                console.log("stream from Subscriber.vue", this.stream);
            const subscriber = this.session.subscribe(
                this.stream,
                this.$el,
                {
                    insertMode: 'append',
                    width: '250px',
                    height: '200px'
                },
                err => {
                    if (err) {
                        this.$emit('error', err);
                    } else {
                        console.log('subscriberConnected from subscriber vue', subscriber);
                    }
                }
            );
                console.log('subscriberCreated from subscriber vue', this.stream);
            }
        }
    };
</script>