<template>
    <header>
        <!--<div id="loading">
            <img id="loading-image" src="images/ajax-loader.gif" alt="Loading..." />
        </div>-->

        <nav class="navbar navbar-default">
            <div class="col-md-offset-1 col-md-10">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand" href="Home/Index"><img :src="Logo" alt=""></a>
                </div>
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav navbar-right">
                        <li><router-link to="/optout">Opt-Out</router-link></li>
                        <li id="frmLogout" @click.prevent="logout"><a>Log Out</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>

    

    <section id="screen-page" class="container-fluid" style="padding-bottom:0;position: relative;">
        <router-view @updateLogo="updateLogo"></router-view>


    </section>
    <div id="loader" class="loader" v-if="showLoader">
        <div class="container">
            <div class="animation-items">
                <div class="box1"></div>
                <div class="box2"></div>
                <div class="box3"></div>
                <div class="loading">loading</div>
            </div>
        </div>
    </div>
</template>
<script>
    import FooterContactForm from "@/components/forms/FooterContactForm.vue";
    import ApiService from "@/core/services/api-service";
    import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
    import { startConnection } from "@/video.js";

    export default {
        name: "MainLayout",
        components: { FooterContactForm },
        data() {
                return {
                    Logo: this.getLogo(),
                };
        },
        created: function () {
            var isLoggedIn = this.$store.getters["Auth/isLoggedIn"];
            console.log(isLoggedIn);
            //if (isLoggedIn === true)
            //    startConnection();
        },
            computed: {
                showLoader() {
                    // if override is set use that otherwise use default flag
                    return this.$store.getters["App/showLoaderOverride"] || this.$store.getters["App/showLoader"];
                },
            },
            methods:{
                async logout(){
                    let inviteToken = this.$store.getters["Auth/invite"];
                    await ApiService.GeneratePostRequest(`verify/logExit`);
                    let response = await ApiService.GeneratePostRequest("account/logout");
                    if (response.isSuccess) {
                        this.$store.commit("Auth/signOut");
                        this.$store.commit("Admin/logout");
                        this.$store.commit("App/updateToken",null);
                        //this.$router.push('/');
                        //SGN-2086
                        this.$router.push('/Admin/ThankYou');
                    }
                },
                updateLogo() {
                    var logo = this.$store.getters["App/getLogo"];
                    if (logo == null)
                        logo = url.endPoint + '/api/admin/logo';
                    else
                        logo = url.endPoint + '/api/admin/logo?name=' + logo;
                    this.Logo = logo;
                },
                getLogo() {
                    var logo = this.$store.getters["App/getLogo"];
                    if (logo == null)
                        logo = url.endPoint + '/api/admin/logo';
                    else
                        logo = url.endPoint + '/api/admin/logo?name=' + logo;
                    console.log("H2" + logo);
                    return logo;
                }
            }
        }

</script>
<style>

        .cursor-pointer {
            cursor: pointer;
        }
</style>
