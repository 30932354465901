<template>
    <form>
        <div class=" col-md-12">
            <div class="alert alert-success" role="alert" v-if="showResponse">
                {{ responseMsg }}
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h3><label for="FullName">Full Name</label></h3>
                    <input class="form-control" data-val="true" data-val-required="Please enter Full Name." id="FullName" name="FullName" placeholder="First Name" type="text" v-model="v$.name.$model" />
                    <div v-if="v$.name.$error" class="field-validation-valid text-danger">Name field is required.</div>
                </div>
                <div class="col-md-12">
                    <h3><label for="EmailAddress">Email Address</label></h3>
                    <input class="form-control bdr-rad-0" data-val="true" data-val-required="Please enter Email Address." id="EmailAddress" name="EmailAddress" placeholder="example@example.com" type="text" v-model="v$.email.$model" />
                    <div v-if="v$.email.$error" class="field-validation-valid text-danger">Enter a valid email address.</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h3><label for="CellPhoneNo">Cell Phone #</label></h3>
                    <input class="form-control bdr-rad-0" data-val="true" data-val-required="Please enter Cell Phone #." id="CellPhoneNo" name="CellPhoneNo" placeholder="Phone Number" type="text" v-model="v$.phone.$model" />
                    <div v-if="v$.phone.$error" class="field-validation-valid text-danger">Phone Number is required</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h3><label for="Message">Message</label></h3>
                    <textarea class="form-control bdr-rad-0" cols="20" data-val="true" data-val-required="Please enter Message." id="Message" name="Message" placeholder="Enter Message" rows="2" v-model="v$.message.$model">
</textarea>
                    <div v-if="v$.message.$error" class="field-validation-valid text-danger">Message is required.</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <input type="submit" value="Submit" @click.prevent="handleFormSubmission" />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import ApiService from "@/core/services/api-service";
    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';
    export default {
        name: 'ContactForm',
        setup() {
            return { v$: useVuelidate() }
        },
        data() {
            return {
                name: "",
                email: "",
                phone: "",
                message: "",

                showResponse: false,
                responseMsg: ""
            }
        },
        methods: {
            async handleFormSubmission() {
                this.v$.$touch()
                if (this.v$.$error) return;

                // submit data here.
                let obj = {
                    FullName: this.name,
                    EmailAddress: this.email,
                    CellPhoneNo: this.phone,
                    Message: this.message,
                };

                let response = await ApiService.GeneratePostRequest("Home/Contact", obj);
                // TODO modify API end point to return json. and use that to determine response msg
                this.name = "";
                this.email = "";
                this.phone = "";
                this.message = "";
                this.v$.$reset();

                this.responseMsg = "Saved Successfully";
                this.showResponse = true;
                setTimeout(() => {
                    this.showResponse = false;
                }, 5000)
                // response is just html - find the word successful to say it it was passed
            }
        },
        validations() {
            return {
                name: { required },
                email: { required, email },
                phone: { required },
                message: { required },
            }
        }
    }
</script>