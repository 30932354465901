<template>
  <div>
    <h2>Select Account</h2>
    <form class="account-form" :onsubmit="handleSubmit">
      <div class="form-group">
        <label for="client" class="label">
          Client
        </label>
        <dropdownlist
          :data-items="clients"
          :data-item-key="'clientId'"
          :text-field="'clientName'"
          :name="'client'"
          :value="selected.client"
          @change="handleChange"
          :required="true"
          class="dropdown"
        >
        </dropdownlist>
      </div>

      <div class="form-group">
        <label for="client" class="label">
          Role
        </label>
        <dropdownlist
          :data-items="roles"
          :data-item-key="'roleId'"
          :text-field="'roleName'"
          :name="'role'"
          :value="selected.role"
          @change="handleChange"
          :required="true"
          class="dropdown"
        >
        </dropdownlist>
      </div>
      <kbutton class="btn confirmBtn" type="submit">
        Confirm
      </kbutton>
      <kbutton class="btn" type="button" :onClick="handleBack">
        Cancel
      </kbutton>
    </form>
  </div>
</template>
<script>
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Button } from "@progress/kendo-vue-buttons";
export default {
  name: "SelectAccountLogin",
  components: {
    dropdownlist: DropDownList,
    kbutton: Button,
  },
  props: ["roles", "clients"],
  data() {
    return {
      selected: {
        client: this.clients.length > 1 ? "" : this.clients[0],
        role: this.roles.length > 1 ? "" : this.roles[0],
      },
    };
  },
  methods: {
    handleChange(e) {
      this.selected[e.target.name] = e.target.value;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$emit("onLogin", e, { clientId: this.selected.client.clientId, roleId: this.selected.role.roleId });
    },
    handleBack(){
      this.$emit("setShowAccountSelect", false);
    }
  },
};
</script>
<style scoped>
.account-form {
  margin-top: 20px;
}
h2 {
  font: 600 16px/18px "Arial", "Open Sans", sans-serif;
  font-size: 22px;
  color: #000;
  padding-bottom: 5px;
  border-bottom: 10px solid #CCC;
  width: 220px;
}
    .dropdown {
        width: 100%;
        background-color: #FFF;
        border:1px solid #CCC;
        border-radius: 5px;
        padding: 5px 16px;
        color: #000;
    }
.label {
  color: #000;
  float: left;
}
    .btn {
        margin-top: 20px;
        background: #FFF;
        color: #000;
        width: 100%;
        border-radius: 5px;
        padding: 15px 20px;
    }
    .confirmBtn {
        margin-top: 70px;
        color: #FFF;
        background: #9747FF;
    }
</style>
