<template>
    <header style="">
        <!--<div id="loading">
            <img id="loading-image" src="images/ajax-loader.gif" alt="Loading..." />
        </div>-->
        <nav class="navbar navbar-default">
            <div class="col-md-12 nav-wrapper">
                <div class="navbar-header">
                    <router-link to="/admin" class="navbar-brand logo"><img :src="Logo" alt=""></router-link>
                </div>
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav" style="margin-left:50px">
                    </ul>
                    <ul class="nav navbar-nav navbar-right">
                        <li class="userinfo">
                            <router-link to="/admin/settings" class="pull-left">
                                <div class="circle">{{Initials}}</div>
                            </router-link>
                            <ul class="userHeader">
                                <li class="user">{{User.firstName}} {{User.lastName}}</li>
                                <li class="role">
                                <switch-role v-if="User.additionalRoles.length>0" :activeRole="User.role" :additionalRoles="User.additionalRoles"/>
                                <div v-if="User.additionalRoles.length==0">{{User.role}}</div>
                                </li>
                                <li class="role" v-if="User.clientName">
                                    <div style="margin-top:5px">{{User.clientName}}</div>
                                </li>
                                <li class="user">{{email}}</li>
                            </ul>
                        </li>
                        <li class="logoutarea"
                            style="cursor:pointer"
                            @click.prevent="logOut">
                            <div>
                                <i class="material-icons">logout</i>
                                <span style="vertical-align:middle;">Logout</span>
                            </div>

                        </li>
                    </ul>
                    <ul class="nav navbar-nav navbar-right col-sm-4 col-xs-4 col-md-4">
                        <li class="col-sm-8 col-sm-8 col-md-6 text-right" style="margin:4px 4px 0 0;padding:0">Package Search -</li>
                        <li class="col-sm-10 col-xs-10 col-md-8" style="margin:0 40px 0 0;padding:0;">
                            <autocomplete :style="{ width: '100%' }" :data-items="countries" :value="value" :text-field="'text'" :filter="'startswith'" @change="onChange" :fill-mode="flat" :loading="loading" :icon-name="'search'" :placeholder="'Search'" :list-no-data-render="'no-data-loader'">
                            </autocomplete>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    <div class="container-fluid page-wrap ">
        <div class="row">
            <aside class="side-bar col-xs-1">
                <div class="side-bar-options">
                    <ul style="list-style:none">
                        <li><i class="material-icons-outlined pull-right sidebar close" @click="resizeMenu">keyboard_double_arrow_left</i></li>
                        <li v-if="_canViewLoan && showMenuItem"><router-link to="/admin"><i class="material-icons-outlined">home</i><span>Signing Room Manager</span></router-link></li>
                        <li v-if="notary && showMenuItem"><router-link to="/admin/orders"><i class="material-icons-outlined">filter_alt</i><span>Pipeline</span></router-link></li>
                        <li v-if="notary && showMenuItem"><router-link to="/admin/requests"><i class="material-icons-outlined">video_settings</i><span>Requests</span></router-link></li>
                        <li v-if="showMenuItem"><router-link to="/admin/calendar"><i class="material-icons-outlined">today</i><span>Calendar</span></router-link></li>
                        <li><router-link to="/admin/settings"><i class="material-icons-outlined">settings</i><span>Settings</span></router-link></li>
                        <li v-if="!notary && showMenuItem"><router-link to="/admin/reports-listing"><i class="material-icons-outlined">assessment</i><span>Reports</span></router-link></li>
                        <li v-if="notary && showMenuItem"><router-link to="/admin/waiting" data-toggle="collapse" href="#collapseReview" role="button" aria-expanded="false" aria-controls="collapseReview"><i class="material-icons-outlined">article</i><span>Signing Room</span></router-link></li>
                    </ul>
                </div>
            </aside>
            <div class="content col-sm-11 col-xs-8 col-md-12 contentarea" style="">
                <router-view @updateLogo="updateLogo" :key="$route.fullPath"></router-view>
            </div>
        </div>
    </div>
    <div id="loader" class="loader" v-if="showLoader">
        <div class="container">
            <div class="animation-items">
                <div class="box1"></div>
                <div class="box2"></div>
                <div class="box3"></div>
                <div class="loading">loading</div>
            </div>
        </div>
    </div>
    <audio loop ref="ringAudio">
        <source src="../../assets/ring.mp3" type="audio/mpeg" />
    </audio>
    <section id="screen-page" style="padding:0 !important;background:none !important;">
        <div class="modal fade" ref="incomingCallModal" role="dialog" id="incomingCallModal">
            <div class="modal-dialog">
                <div class="modal-content" style="padding: 20px;">
                    <div class="modal-header">
                        <h4 class="modal-title">Waiting Signers Queue</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row callingdetails">
                            <div class="form-group" id="lstSignersInQueue">
                                <div class='row' id="'divWaitingSigner' + notarizeID + 'data-signer-conenction-id=' + signerConnectionID + 'notarize-id=' + notarizeID + 'data-user-id=' + userID">

                                    <div class='col-md-7 tet-left col-xs-12'>
                                        <b>Caller:</b> <span>{{signerName}}</span><br>
                                    </div>
                                    <div class='col-md-5 col-xs-12 text-right'>
                                        <button data-bb-handler='success' type='button' class='btn btn-success' @click="answerAlert" style='margin-right: 5px'>Answer</button>
                                        <button data-bb-handler='danger' type='button' class='btn btn-danger' @click="declineAlert">Decline</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<style scoped>
    li > i.sidebar {
        padding: 4px;
        margin: 4px 12px 0;
        color: white;
        opacity: 0.8;
        text-shadow: 0 1px 0 #d9d9d9;
        transition: transform 300ms;
    }

        li > i.sidebar.open {
            transform: rotate(180deg);
        }

        li > i.sidebar:hover {
            font-weight: 600;
            text-shadow: 0 1px 0 #fff;
            text-decoration: none;
            cursor: pointer;
        }

    aside {
        width: 300px;
        transition: width 300ms;
    }

        aside > div > ul > li:not(:first-child) {
            width: 300px;
            overflow: hidden;
        }

        aside.closed {
            width: 68px;
        }

        aside > div > ul {
            overflow: hidden;
        }

    div.contentarea {
        padding: 20px;
        width: calc(100% - 300px);
        transition: width 300ms;
    }

        div.contentarea.closed {
            width: calc(100% - 68px);
        }

    header {
        box-shadow: 0px .1px 5px 0px rgba(0,0,0,0.20);
        -webkit-box-shadow: 0px .1px 5px 0px rgba(0,0,0,0.20);
        -moz-box-shadow: 0px .1px 5px 0px rgba(0,0,0,0.20);
        position: fixed;
        top: 0;
        width: 100%;
        background: white;
        z-index: 2;
        height: 85px;
    }

    .logo {
        margin: 3px 0 0 5px;
    }

    .navbar-brand > img {
        display: block;
        width: auto !important;
        height: 42px !important;
        margin-top: -10px;
    }

    .nav-wrapper {
        background: white;
    }

    .page-wrap {
        margin-top: 85px;
    }

        .page-wrap aside {
            position: sticky;
            top: 85px;
        }

    aside {
        padding-left: 0;
        padding-right: 0;
        min-height: 100vh;
        background-color: #4d97ac;
    }

    .page-wrap aside ul {
        padding-left: 0;
        display: block;
        width: 100%;
    }

    .page-wrap aside li {
        margin: 0;
        display: block;
        width: 100%;
        align-items: center;
    }

    .page-wrap aside a {
        display: block;
        width: 100%;
        color: white;
        font-size: 1.2em;
        padding: 15px 0 15px 20px;
        border-left: 3px solid #4d97ac;
    }

        .page-wrap aside a.router-link-active {
            border-left: 3px solid white;
            background: #68a7b8;
        }

    .page-wrap aside img {
        width: 20px;
        height: auto;
    }

    .page-wrap aside span {
        padding-left: 15px;
    }

    .page-wrap aside i {
        font-size: 30px;
        position: relative;
        top: 8px;
        font-weight: 100;
    }

    .page-wrap aside .side-bar-options i {
        position: static;
    }

    .page-wrap aside .side-bar-options a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }

        .page-wrap aside .side-bar-options a:hover {
            font-weight: bold;
        }

    .userinfo {
        border-right: #ccc 3px double;
        margin: -10px 10px 0 0;
        color: initial;
        padding-right: 15px;
    }

    .nav.navbar-nav.navbar-right {
        display: flex;
        align-items: center;
    }

    .circle {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #a5cad5;
        text-align: center;
        vertical-align: middle;
        line-height: 44px;
        font-weight: 600;
        font-size: 1.22em;
        display: inline-block;
        font-family: 'Arial','Open Sans',sans-serif;
        color: #4d97ac;
        float: left;
        margin: 0;
    }

    .userHeader {
        float: left;
        margin: 0;
        padding: 0;
    }

        .userHeader li {
            list-style: none;
        }

    .user {
        margin: 2px 0 0 0;
        color: initial;
    }

    .role {
        font: 600 1.02em/1.05em 'Arial','Open Sans',sans-serif;
        color: #4d97ac;
    }

    .logoutarea {
        padding: 0 0 0 8px;
    }

        .logoutarea a {
            position: relative;
            top: 7px;
        }

        .logoutarea i {
            font-size: 1.75em;
            margin: -10px 0 0 0;
            padding: 0 5px 0 0;
            color: #fb8386;
        }

        .logoutarea span {
            position: relative;
            top: -8px;
            color: #fb8386;
            font-weight: 800;
        }
</style>
<script>
    import LoansGrid from '@/components/page/LoansGrid';
    import ApiService from "@/core/services/api-service";
    import { mapState, mapActions } from 'vuex'
    import { startConnection } from "@/video.js";
    import "@microsoft/signalr";
    import "@/video.js";
    import Setup from "../../components/forms/invitation-steps/test/SettingsSetup";
    import PermissionHelper from "../../core/permission-helper";
    import { Input } from "@progress/kendo-vue-inputs";
    import { AutoComplete } from '@progress/kendo-vue-dropdowns';
    import { filterBy } from '@progress/kendo-data-query';
    import { values } from 'pdf-lib';
    import router from "@/router/index";
    import NoDataLoader from '../../components/NoDataLoader';
    import SwitchRole from "@/components/SwitchRole";

    const CAN_VIEW_SETTINGS = "Settings_CanViewSettings";
    const CAN_VIEW_LOANS = "Dashboard_CanViewLoan";
    const delay = 500;

    export default {
        name: "AdminLayout",
        async created() {
            var perm = PermissionHelper.GetPermission(CAN_VIEW_SETTINGS);
            if (perm[CAN_VIEW_SETTINGS] !== undefined) this._canViewSettings = perm[CAN_VIEW_SETTINGS];

        },
        components: {
            LoansGrid,
            setup: Setup,
            "k-input": Input,
            "autocomplete": AutoComplete,
            "no-data-loader": NoDataLoader,
            SwitchRole
        },
        data: function () {
            return {
                User: {},
                Logo: this.getLogo(),
                Initials: "",
                devicesConfig: false,
                documents: [],
                _canViewSettings: false,
                _canViewLoan: false,
                email: "",
                permissions: {},
                notary: false,
                signerName: "",
                opened: false,
                countries: [],
                value: '',
                selected: null,
                loading: false,
                timeout: null,
            };
        },
        created: function () {
            var user = this.$store.state.Admin.loginInformation;
            if (user == null) {
                this.$store.commit("Auth/signOut");
                this.$store.commit("Admin/logout");
                this.$store.commit("App/updateToken",null);
                this.$store.reset();
                this.$router.push('/Admin/login');
            }
            this.User = user;
            this.Initials = user.firstName[0] + "" + user.lastName[0];
            ApiService.GenerateGetRequest("Admin/IsNotary").then(
                response => {
                    console.log("Admin/IsNotary", response.content);
                    this.notary = response.content.isNotary;
                }
            );
            startConnection(this.callbackFunct);
        },
        async mounted() {
            //var perm_loan = PermissionHelper.GetPermission(CAN_VIEW_LOANS);
            //console.log("perm_loan", perm_loan);
            //if (perm_loan[CAN_VIEW_LOANS] !== undefined) this._canViewLoan = perm_loan[CAN_VIEW_LOANS];

            document.title = this.User.clientName != null ? this.User.clientName + " - eSign" : 'Evolve Smart Solutions - eSign';

            ApiService.GenerateNonLoadingGetRequest("Admin/IsNotary").then(
                response => { this._canViewLoan = response.content.isNotary != null && response.content.isNotary == true ? false : true; }
            );

            ApiService.GenerateNonLoadingGetRequest("Admin/getUserEmail").then(
                response => { this.email = response.content; }
            );
        },
        computed: {
            showLoader() {
                // if override is set use that otherwise use default flag
                return this.$store.getters["App/showLoaderOverride"] || this.$store.getters["App/showLoader"];
            },
            canViewSettings: function () { return this._canViewSettings },
            canViewLoan: function () {
                return this._canViewLoan;
            },
            showMenuItem() {
                return this.$store.getters["Admin/hasAccessToMenu"];
            },

        },
        methods: {
            ...mapActions('Admin', ['logout']),
            resizeMenu(e) {
                let open = $(e.target).hasClass('close');
                let aside = $(e.target).parents('aside');

                if (open) {
                    $(e.target).removeClass('close').addClass('open');
                    aside.toggleClass('closed');
                    $('div.content', aside.parent()).toggleClass('closed')
                } else {
                    $(e.target).removeClass('open').addClass('close');
                    aside.toggleClass('closed');
                    $('div.content', aside.parent()).toggleClass('closed')
                }
            },
            onChange(e) {
                const self = this;
                const value = e.target.value;
                let valueSelected = false;

                if (e.event == null && e.target.value != null) {
                    valueSelected = true;
                }
                self.selected = self.countries.findIndex(itm => itm.text === value);
                if (valueSelected && self.selected > -1) {
                    if (this.notary) {
                        router.push('/admin/notary/' + self.countries[self.selected].packageID);
                        self.value = '';
                        return;
                    }

                    router.push('/admin/detail/' + self.countries[self.selected].packageID);
                    self.value = '';
                    return;
                }
                else if (value != null && value.length > 2) {
                    clearTimeout(self.timeout);
                    self .timeout = setTimeout(async () => {
                        const stateData = value.length < 3 ?
                            { data: [], opened: false } :
                            { data: await self.filterData(value), opened: true };

                        let valueSelected = false;
                        if (e.event == null && e.target.value != null) {
                            valueSelected = true;
                        } else {
                            const eventType = e.event.type;
                            valueSelected = eventType === 'click' ||
                                (eventType === 'keydown' && e.event.keyCode === 13);
                        }
                        self.selected = self.countries.findIndex(itm => itm.text === value);
                        if (valueSelected && self.selected > -1) {
                            if (this.notary) {
                                router.push('/admin/notary/' + self.countries[self.selected].packageID);
                            } else {
                                router.push('/admin/detail/' + self.countries[self.selected].packageID);
                            }
                            self.value = '';

                        }

                        self.countries = stateData.data;
                        self.opened = stateData.opened;

                        self.loading = false;
                    }, delay);
        self .loading = true;
    }
                else {
                    self.countries = [];
                    self.opened = false
                    self.loading = false;
                }
                self.value = value;
            },
            async filterData(value) {
                let result = await ApiService.GenerateNonLoadingGetRequest(`package/search/${value}`);
                if (!result || !result.isSuccess || !result.content || result.content.length == 0)
                    return [];
                var results = [];
                let values = value.split(' ');
                for (var i = 0; i < result.content.length; i++) {
                    var entry = {
                        ...result.content[i],
                        firstNames: result.content[i].firstNameList == null ? [] : result.content[i].firstNameList.split(','),
                        middleNames: result.content[i].middleNameList == null ? [] : result.content[i].middleNameList.split(','),
                        lastNames: result.content[i].lastNameList == null ? [] : result.content[i].lastNameList.split(','),
                        emails: result.content[i].emailList == null ? [] : result.content[i].emailList.split(','),
                        text: "",
                    }

                    entry.text += 'Loan# - ' + result.content[i].loanNumber + ' | ';
                    var idx1 = entry.firstNames.findIndex(function (e) { return e.toLowerCase().indexOf(values[0].toLowerCase()) >= 0; });
                    var idx2 = entry.middleNames.findIndex(function (e) { return e.toLowerCase().indexOf(values[0].toLowerCase()) >= 0; });
                    var idx3 = entry.lastNames.findIndex(function (e) { return e.toLowerCase().indexOf(values[0].toLowerCase()) >= 0; });
                    var idx = (entry.has_fname ? idx1 : entry.has_mname ? idx2 : entry.has_lname ? idx3 : 0);

                    entry.text += (entry.firstNames.length > 0 ? entry.firstNames[idx] : '') + ' ' + (entry.middleNames.length > 0 ? entry.middleNames[idx] : '') + ' ' + (entry.lastNames.length > 0 ? entry.lastNames[idx] : '');

                    if (result.content[i].has_desc)
                        entry.text += ' | Description - ' + result.content[i].loanDescription;
                    else if (result.content[i].has_pkname)
                        entry.text += ' | Package - ' + result.content[i].packageName;
                    else if (result.content[i].has_adr1)
                        entry.text += ' | Address 1 - ' + result.content[i].address1;
                    else if (result.content[i].has_adr2)
                        entry.text += ' | Address 2 - ' + result.content[i].address2;
                    else if (result.content[i].has_city)
                        entry.text += ' | City - ' + result.content[i].city;
                    else if (result.content[i].has_ctry)
                        entry.text += ' | Country - ' + result.content[i].country;
                    else if (result.content[i].has_state)
                        entry.text += ' | State - ' + result.content[i].states;
                    else if (result.content[i].has_pktype)
                        entry.text += ' | Type - ' + result.content[i].packageType;
                    else if (result.content[i].has_email)
                        entry.text += ' | Email - ' + entry.emails[idx];

                    results.push(entry);
                }
                return results;
            },
            async logOut() {
                console.log("hit logout")
                let response = await ApiService.GeneratePostRequest("account/logout");
                if (response.isSuccess) {
                    this.$store.commit("Admin/logout");
                    this.$store.commit("Auth/signOut");
                    this.$store.commit("App/updateToken",null);
                    this.$router.push('/Admin/login');
                }
            },
            getSetup(value) {
                console.log("getSetup: ", value);
                if (value != "") {
                    this.devicesConfig = value;
                }
            },
            updateLogo() {
                var logo = this.$store.getters["App/getLogo"];
                if (logo == null)
                    logo = url.endPoint + '/api/admin/logo';
                else
                    logo = url.endPoint + '/api/admin/logo?name=' + logo;
                this.Logo = logo;
            },
            getLogo() {
                var logo = this.$store.getters["App/getLogo"];
                if (logo == null)
                    logo = url.endPoint + '/api/admin/logo';
                else
                    logo = url.endPoint + '/api/admin/logo?name=' + logo;
                console.log("H2" + logo);
                return logo;
            },
            async callbackFunct(type, data) {
                console.log("callback from video.js", type, data);
                switch (type) {
                    case "signerInQueue":
                        this.signerInQueueAlert(data);
                        break;
                    default:
                        console.log("method not found");
                }

            },
            signerInQueueAlert(data) {
                console.log("signer in queue called ", data);
                this.signerName = data.signerName;
                this.signerId = data.signerID;
                this.$refs.ringAudio.play();
                $(this.$refs.incomingCallModal).modal('show');
                var self = this;
                this.timeOut = setTimeout(function () { self.cancelAlert(); }, 60000);
            },
            cancelAlert() {
                $(this.$refs.incomingCallModal).modal('hide');
                this.$refs.ringAudio.pause();
                clearTimeout(this.timeOut);
            },
            declineAlert() {
                this.cancelAlert();
                ApiService.GeneratePostRequest(`transaction/notaryDeclinedCall/${this.signerId}`);
            },
            answerAlert() {
                this.cancelAlert();
                this.$router.push('/admin/waiting');

            },
            async callTimeout(data) {
                //timeout calling one time.
                this.$refs.ringAudio.pause();
                $(this.$refs.incomingCallModal).modal('hide');
                console.log("timeout data ", data);

            },
        },
    }

</script>