<template>
    <div id="session" @error="errorHandler">
        <publisher :session="session" @error="errorHandler" v-if="callClicked"></publisher>
        <div id="subscribers" v-for="stream in streams" :key="stream.streamId">
            <subscriber @error="errorHandler" :stream="stream" :session="session"></subscriber>
        </div>
        <button @click="initializeSession" v-if="!callClicked" style="float:right">Start Call</button>
        <button @click="disconnectSession" v-if="callClicked" style="float:right">End Call</button>
    </div>
</template>

<script>
    import OT from '@opentok/client';
    import Publisher from '@/components/Video/Publisher.vue';
    import Subscriber from '@/components/Video/Subscriber.vue';

    const errorHandler = err => {
        alert(err.message);
    };

    export default {
        name: 'session',
        components: { Publisher, Subscriber },
        props: {
            apiKey: null,
            sessionId: null,
            token: null,
            
        },
        data: () => ({
            streams: [],
            session: null,
            callClicked: false,
        }),
        mounted() {
            this.initializeSession();
        },       
        methods: {
            errorHandler,

            initializeSession: function () {
                
                this.session = OT.initSession(this.apiKey, this.sessionId);

                console.log("Session.vue", this.session, " api key ", this.apiKey);
                this.session.connect(this.token, err => {
                    if (err) {
                        errorHandler(err);
                    }
                    console.log("Session connected");

                });


                this.session.on('streamCreated', event => {
                    this.streams.push(event.stream);
                    console.log("stream created");
                });

                this.session.on('streamDestroyed', event => {
                    const idx = this.streams.indexOf(event.stream);
                    if (idx > -1) {
                        this.streams.splice(idx, 1);
                    }
                    console.log("stream destroyed");
                });

                this.session.on('sessionDisconnected', function sessionDisconnected(event) {
                    console.log('You were disconnected from the session.', event.reason);
                });

                this.callClicked = true;
            },

            disconnectSession: function () {
                this.callClicked = false;
                this.session.disconnect();
            }
        },

        
    };
</script>

<style>
    .OT_subscriber {
        float: left;
    }

    .OT_publisher {
        float: left;
    }
</style>