<!--
    https://www.positronx.io/vue-js-checkbox-tutorial-with-examples/
    https://stackoverflow.com/questions/40774697/how-can-i-group-an-array-of-objects-by-key?answertab=trending#tab-top
-->
<template>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container">
            <div class="bdr-bottom row">
                <div class="title col-md-6">
                    <div class="col-md-12 ">
                        <h2>Meeting Failure </h2>
                        <p>Select all applicable failure reasons.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="col-md-12 text-right">
                        <k-button :primary="true" id="btnSumit" @click="Submit">Submit</k-button>
                    </div>
                </div>
            </div>

            <div id="divSelectionContainer">
                <div class="col-md-6 text-left profile" v-for="(group, i) in model">
                    <h3 class="checklist-head">{{i}}</h3>
                    <div class="form-group form-check" v-for="item in group" v-bind:key="item.id">
                        <input type="checkbox" v-model="selectedItems" :id="item.reasonId" :value="item.reasonId" style="margin-right:1rem">
                        <label class="form-check-label" :for="item.reasonId">{{item.reasonName}}</label>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<style scoped>
</style>

<script>
    import ApiService from "@/core/services/api-service";
    window.url = require("../core/services/urls");
    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'MeetingFailure',
        components: {
            "k-button": Button,
        },
        data: () => ({
            model: null,
            selectedItems: [],
            meetingFailureReasonIDs: "",
            NotarizeID: 0,
        }),
        async mounted() {

            var cxn = JSON.parse(localStorage.getItem("abortConnection"));
            console.log("meeting failure local storage: ", cxn);
            this.NotarizeID = cxn.NotarizeID;

            console.log("notarizeid in meeting failure: ", this.NotarizeID);
            //Fetch meeting failure reasons
            var reasons = await ApiService.GenerateGetRequest("notarize/meetingfailurereasons/get");
            console.log("meeting failure reasons: ", reasons["content"]);

            function groupBy(xs, f) {
                return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
            }

            //Group reasons
            this.model = groupBy(reasons["content"], (c) => c.groupName);
            console.log(this.model);

        },
        methods: {
            Submit() {
                console.log("selectedItems: ", this.selectedItems);
                if (this.selectedItems.length == 0) {
                    Swal.fire("Please select any failure reason to proceed'", "", "error");
                } else {

                    //Join meeting failure reasons into a string
                    for (var i = 0; i < this.selectedItems.length; i++) {
                        this.meetingFailureReasonIDs = this.selectedItems + ", ";
                    }

                    var meetingFailure = {
                        NotarizeID: this.NotarizeID,
                        MeetingFailureReasonIDs: this.meetingFailureReasonIDs.substring(0, this.meetingFailureReasonIDs.length - 2)
                    };

                    console.log("meetingFailure: ", meetingFailure);

                    //Post meetingfailure
                    return ApiService.GeneratePostRequest("notarize/meetingfailure/save", meetingFailure).then(
                        response => {
                            console.log("notarize/meetingfailure/save:", response["content"]);
                            if (response["isSuccess"]) {
                                //terminate meeting
                                return ApiService.GenerateGetRequest("notarize/terminatemeeting/" + this.NotarizeID).then(
                                    response => {
                                        console.log("notarize/terminatemeeting: ", response["content"]);
                                        if (response["isSuccess"]) {
                                            //abort meeting
                                            return ApiService.GenerateGetRequest("notarize/abortmeeting/" + this.NotarizeID).then(
                                                response => {
                                                    console.log("abortmeeting: ", response["content"]);
                                                    Swal.fire("Success", "The meeting has been aborted!", "success");
                                                    this.$router.push('/admin/waiting');
                                                }
                                            );
                                        }
                                    }
                                );
                            }
                        }
                    );
                }
            }
        },
    }
</script>
