
<template>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container">
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <div class="col-md-11 text-center">
                        <h4>Test Video, Audio and Internet Connection</h4>
                        <!--<img src="../../../../../public/images/icon-left-arrow.png" alt="" class="img-responsive arrow-icon position-left">-->
                    </div>
                </div>
            </div>
            <div class="row row-flex">
                <div class="innerbox col-md-6 column-flex">
                    <div class=" col-md-12 text-center">

                        <!-- test camera -->
                        <testcamera @camID="getCamera" />
                        <hr />

                        <!-- test audio -->
                        <testaudio @micID="getMicrophone" @speakerID="getSpeaker" />
                        <hr />

                        <!-- test internet -->
                        <testinternetconnection />
                         
                        <!-- location -->
                        <testlocation />

                    </div>
                </div>
                <div class="innerbox dark-bg col-md-6 column-flex">
                    <div class=" col-md-12">
                        <br>
                        <br>
                        <br>
                        <ul class="signup-process">
                            <li> <span class="badge">1</span> Camera</li>
                            <li> <span class="badge">2</span> Audio</li>
                            <li> <span class="badge">3</span> Internet Connection</li>
                            <li>
                                <k-button :primary="true" @click="Save">SAVE</k-button>
                            </li>
                        </ul>

                        <br>
                        <br>
                        <br>
                        <br>

                        <br>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<style>
    .pids-wrapper {
        width: 100%;
    }

    .pid {
        width: calc(10% - 10px);
        height: 10px;
        display: inline-block;
        margin: 5px;
    }
</style>

<script>

    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
    import TestAudio from '@/components/forms/invitation-steps/test/TestAudio.vue';
    import TestAudioVideo from '@/components/forms/invitation-steps/test/TestAudioVideo.vue';
    import TestCamera from '@/components/forms/invitation-steps/test/TestCamera.vue';
    import TestHardwareInstructions from '@/components/forms/invitation-steps/test/TestHardwareInstructions.vue';
    import TestInternetConnection from '@/components/forms/invitation-steps/test/TestInternetConnection.vue';
    import TestLocation from '@/components/forms/invitation-steps/test/TestLocation.vue';
    import ApiService from "@/core/services/api-service";
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'Setup',
        components: {
            "k-button": Button,
            testaudio: TestAudio,
            testaudiovideo: TestAudioVideo,
            testcamera: TestCamera,
            testhardwareinstructions: TestHardwareInstructions,
            testinternetconnection: TestInternetConnection,
            testlocation: TestLocation,
        },
        data() {
            return {
                user: {},
                selectedCam: "default",
                selectedMic: "default",
                selectedSpeaker: "default",
                location: "",
            }
        },
        async mounted() {
            //FETCH CURRENT USER OBJ 
            var user = await ApiService.GenerateGetRequest("admin/GetUser");
            console.log("admin/GetUser: ", user);
            this.user = user["content"];
        },
        methods: {
            getCamera(value) {
                console.log("getCamera: ", value);
                if (value != "") {
                    this.selectedCam = value;
                }
            },
            getMicrophone(value) {
                console.log("getMicrophone: ", value);
                if (value != "") {
                    this.selectedMic = value;
                }
            },
            getSpeaker(value) {
                console.log("getSpeaker: ", value);
                if (value != "") {
                    this.selectedSpeaker = value;
                }
            },
            getLocation(value) {
                console.log("getLocation: ", value);
                if (value != "") {
                    this.location = value;
                }
            },
            async Save() {
                var config = {
                    UserId: this.user.id,
                    MicID: this.selectedMic,
                    CamID: this.selectedCam,
                    SpeakerID: this.selectedSpeaker,
                    Location: this.location
                };
                console.log("config: ", config);
                //POST USERSYSTEMCONFIG
                return ApiService.GeneratePostRequest("user/addconfig", config).then(
                    response => {
                        console.log("user/addconfig:", response["content"]);
                        if (response["isSuccess"]) {
                            //HANDLE ROUTING
                            this.$emit("deviceSetup", true);
                            Swal.fire("Configuration Saved!", "", "success");
                        } else {
                            this.$emit("deviceSetup", false);
                            Swal.fire("Error saving configuration", "", "error");
                        }
                    }
                );
            }
        }
    }

</script>