<template>
    <div class="container main-container">
        <div class="col-md-10 col-md-offset-1 col-sm-12">
            <div class="row">
                <h2>Please enter your Social Security Number</h2>
            </div>

            <div class="row mb-3">
                <h4>{{userFullName}}</h4>
                <k-input v-model="ssn"
                         id="SSNNo"
                         :name="SSNNo" type="password"
                         :label="'Social Security Number'"
                         :min-length="9"
                         :max-length="9"
                         :valid="(!!ssn)"
                         :required="true" />
                <span v-show="!reconfirmSSN">Eg. XXXXXXXXX</span>
                <span class="field-validation-valid text-danger row" data-valmsg-for="SSNNo" data-valmsg-replace="true" v-show="msg != ''"> {{msg}}</span>

                <k-input v-show="reconfirmSSN"
                         v-model="ssn2"
                         id="SSNNo2"
                         :name="SSNNo2" type="password"
                         :label="'Confirm Social Security Number'"
                         :min-length="9"
                         :max-length="9"
                         :valid="(!!ssn2)"
                         :required="true" />
                <span v-show="reconfirmSSN">Eg. XXXXXXXXX</span>
                <span class="field-validation-valid text-danger row" data-valmsg-for="SSNNo2" data-valmsg-replace="true" v-show="msg2 != ''"> {{msg2}}</span>
            </div>

            <div class="row mb-3">
                <k-button :primary="true" @click="submit">CONFIRM</k-button>
            </div>

            <div class="row alert alert-info">
                <h3>Is my information secure? </h3>
                All user communications are encrypted and all data at rest (such as documents) are protected using industry-specified encryption protocols such as AES-256. All application accesses are also tracked using an audit trail.
            </div>
        </div>
    </div>
</template>

<style scoped>
    #screen-page h3 {
        color: #31708f !important;
    }
</style>

<script>
    import ApiService from "@/core/services/api-service";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Input } from "@progress/kendo-vue-inputs";
    import {
        Card,
        CardHeader,
        CardBody,
    } from "@progress/kendo-vue-layout";
    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";

    export default {
        name: 'SSN',
        emits: ["componentValid"],
        props: ["preloadedData"],
        components: {
            "k-button": Button,
            'k-input': Input,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
        },
        data() {
            return {
                ssn: "",
                msg: "",
                isRon: this.$store.getters["Invitation/isRon"] || false,
                reconfirmSSN: false,
                ssn2: "",
                msg2: "",
            }
        },
        async created() {
            console.log("ISRON: ", this.isRon);
            let settings = await this.$store.getters["App/appSettings"];
			if (!this.isRon) {
				if (settings.skipSSN)
					if (isMobile())
						this.$router.push({ path: `/signer/room-mobile` });
					else
						this.$router.push({ path: `/signer/room` });
			}
        },
        computed: {
            userFullName() {
                return this.$store.getters["Auth/userFullName"];
            }
        },
        watch: {
            ssn(value) { this.emitValidState() },
            ssn2(value) { this.emitValidState2() },
        },
        methods: {
            async submit() {
                if (!this.reconfirmSSN) {
                    if (this.ssn.trim() === "") {
                        this.msg = "Please enter your SSN";
                        return;
                    }

                    let response = await ApiService.GeneratePostRequest(`verify/checkssn?ssn=${this.ssn}`);
                    console.log(`verify/checkssn?ssn=${this.ssn}`, response);
                    if (!response["isSuccess"]) {
                        Swal.fire(
                            "SSN Verification Failed",
                            response["message"],
                            "error"
                        );
                    } else {
                        var result = response["content"];
                        if (result == "Passed") {
                            //check signing date and package type and signing type for documents
                            var isDateSignable = this.$store.getters["Auth/signerSessionInformation"]["isDateSignable"];

                            if (this.isRon) {// signing date to be confirmed as a condition
                                //console.log("past signing date, push to kba");
                                this.$router.push({ path: `/signer/kba` });
                            } else {
                                //console.log("not signing date, to go photo upload");
								if (isMobile())
									this.$router.push({ path: `/signer/room-mobile` });
								else
									this.$router.push({ path: `/signer/room` });
                            }
                        } else if (result == "Not Found") {
                            this.reconfirmSSN = true;
                        }
                    }
                } else {
                    if (this.ssn.trim() === "") {
                        this.msg = "Please enter your SSN";
                        return;
                    }

                    if (this.ssn2.trim() === "") {
                        this.msg2 = "Please re-enter your SSN";
                        return;
                    }

                    if (this.ssn === this.ssn2) {
                        this.reconfirmSSN = true;
                        if (this.isRon) {// signing date to be confirmed as a condition
                            //console.log("past signing date, push to kba");
                            this.$router.push({ path: `/signer/kba` });
                        } else {
                            //console.log("not signing date, to go photo upload");
							if (isMobile())
								this.$router.push({ path: `/signer/room-mobile` });
							else
								this.$router.push({ path: `/signer/room` });
                        }
                    } else {
                        Swal.fire(
                            "SSN Verification Failed",
                            "SSN does not match",
                            "error"
                        );
                    }
                }

            },
            emitValidState() {
                let hasErrors = this.ssn === "";
                if (!hasErrors) {
                    this.msg = "";
                }
                this.$emit("componentValid", !hasErrors);
            },
            emitValidState2() {
                let hasErrors = this.ssn2 === "";
                if (!hasErrors) {
                    this.msg2 = "";
                }
                this.$emit("componentValid", !hasErrors);
            }
        },
    }
</script>
