<template>
    <div style="width:6.5in;" class="pdfexport">
        <div style="text-align: center;">
            <div id="document-details" class="container" style="padding-left: 0; margin-left:0">
                <div>
                    <h3>Document Details</h3>
                    <table style='text-align:left;'>
                        <tr><td class='detailsName'><strong>Tamper Seal Status:</strong></td><td class='detailValue'>{{tampersealStatus}}</td></tr>
                        <tr><td class='detailsName'><strong>Tamper Seal Applied Date:</strong></td><td class='detailValue'>{{tampersealDate == "" ? "N/A" : moment(tampersealDate).format("MMMM Do, YYYY h:mm a")}}</td></tr>
                        <tr><td class='detailsName'><strong>Package Identifier:</strong></td><td class='detailValue'>{{documentDetails.loanNumber}}</td></tr>
                        <tr><td class='detailsName'><strong>Docmuent Name:</strong></td><td class='detailValue'>{{documentDetails.description}}</td></tr>
                        <tr><td class='detailsName'><strong>Document Type:</strong></td><td class='detailValue'>{{documentDetails.documentType}}</td></tr>
                        <tr><td class='detailsName'><strong>Document Identifier:</strong></td><td class='detailValue'>{{documentDetails.documentID}}</td></tr>
                        <tr><td class='detailsName'><strong>Document Generator:</strong></td><td class='detailValue'>Signia</td></tr>
                        <tr><td class='detailsName'><strong>Date Last Modified:</strong></td><td class='detailValue'>{{moment(modifiedDate).format("MMMM Do, YYYY h:mm a")}}</td></tr>
                        <tr><td class='detailsName'><strong>Modified By:</strong></td><td class='detailValue'>{{modifiedBy}}</td></tr>
                        <tr><td class='detailsName'><strong>Signing Completed:</strong></td><td class='detailValue'>{{docSigned}}</td></tr>
                        <tr><td class='detailsName'><strong>e-Signature Type:</strong></td><td class='detailValue'>{{signingDescription}}</td></tr>
                        <tr><td class='detailsName'><strong>Signing Room Originator:</strong></td><td class='detailValue'>{{lenderName}}</td></tr>                        
                    </table><br />
                </div>
            </div>
            <div id="signer-details" style="text-align:left;">
                <div>
                    <h3>Signer Details</h3>
                </div>
                <table style="width:5.6in;">
                    <thead>
                        <tr>
                            <th style="width:.5in;">
                                First Name
                            </th>
                            <th style="width:.6in;">
                                Last Name
                            </th>
                            <th style="width:1.5in;">
                                Email Address
                            </th>
                            <th style="width:.5in;">
                                e-Consent
                            </th>
                            <th style="width:.8in;">
                                e-Consent Date
                            </th>
                        </tr>
                    </thead>
                    <tr v-for="item in documentParticipants" :key="item.participantId">
                        <td style="width:.5in;">
                            {{item.firstName}}
                        </td>
                        <td style="width:.6in;">
                            {{item.lastName}}
                        </td>
                        <td style="width: 1.5in; word-wrap: anywhere;">
                            {{item.email}}
                        </td>
                        <td style="width:.8in;">
                            {{item.eConsentBool ? item.eConsentBool : 'No'}}
                        </td>
                        <td style="width:.8in;">
                            {{item.eConsentDate ? moment(item.eConsentDate).format("MMMM Do, YYYY h:mm a") : 'N/A' }}
                        </td>
                    </tr>
                </table>
            </div>
            <div id="audit-logs" style="text-align:left;">
                <div>
                    <h3>Audit Log</h3>
                </div>
                <table style="width:5.6in;">
                    <thead>
                        <tr>
                            <th style="width:.8in;">
                                Timestamp
                            </th>
                            <th style="width:1in;">
                                Document Name
                            </th>
                            <th style="width:2in;">
                                Action
                            </th>
                            <th style="width:.8in;">
                                User
                            </th>
                            <th style="width:.8in;">
                                IP Address
                            </th>
                        </tr>
                    </thead>
                    <tr v-for="item in auditLog" :key="item.participantId">
                        <td style="width:.8in;">
                            {{ moment(item.timestamp).format("MM/DD/YYYY h:mm") }}
                        </td>
                        <td style="width: 1in; padding-right: .1in; word-wrap: anywhere; ">
                            {{documentDetails.description}}
                        </td>
                        <td style="width: 2in; padding-right: .3in; word-wrap: anywhere;">
                            {{item.detailDescription}}
                        </td>
                        <td style="width: .8in; padding-right: .1in">
                            {{item.performedByName}}
                        </td>
                        <td style="width:.8in;">
                            {{item.ipAddress}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import { PDFExport, savePDF } from "@progress/kendo-vue-pdf";
    import ApiService from "@/core/services/api-service";
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';


    export default {
        name: "DocumentAuditLogPDF",
        computed: {
            moment: () => moment
        },
        components: {
            grid: Grid,
            pdfexport: PDFExport
        },
        props: ["docId", "packageId"],
        data: function () {
            return {
                documentDetails: "",
                auditLog: [],
                documentParticipants: [],
                docSigned: "",
                tampersealStatus: "Tamperseal not applied",
                tampersealDate: "",
                signingDescription: "",
                lenderName: "",
                modifiedBy: "",
                modifiedDate:""
            }
        },
        async mounted() {
            if (this.docId) {
                await ApiService.GenerateGetRequest(
                    `package/document/${this.docId}`
                ).then((response) => {
                    if (response.isSuccess) {
                        console.log("DOC Details", )
                        this.documentDetails = response.content;
                        this.docSigned = (this.documentDetails.status == "Signed") ? "Yes" : "No";
                    }
                });

                console.log("package ID", this.packageId)

                await ApiService.GeneratePostRequest(
                    "Package/GetLoanPackageById/" + this.packageId
                ).then((response) => {
                    if (response.isSuccess) {
                        this.loanDetail = response.content;
                        this.signingDescription = this.loanDetail.signingDescription;
                        this.lenderName = this.loanDetail.lenderName;
                    }
                });

                await ApiService.GenerateGetRequest(
                    `package/document/${this.docId}/participants`
                ).then((response) => {
                    this.documentParticipants = response.content;
                });

                await ApiService.GenerateGetRequest(
                    "Package/GetDocumentAuditLog/" + this.docId
                ).then((response) => {
                    this.auditLog = response;
                });

            }

            await ApiService.GenerateGetRequest(
                "Package/GetPackageConsentAuditLog/" + this.packageId
            ).then((response) => {
                if (response.isSuccess) {
                    this.consentList = response.content;
                    this.consentList.forEach(c => {
                        let signer = this.documentParticipants.find(x => x.firstName + " " + x.lastName == c.performedByName);
                        if (signer != null) {
                            signer.eConsentBool = "Yes";
                            signer.eConsentDate = c.timestamp;
                        }
                    });
                }
            });
            this.checkTamperSeal();
            this.setLastModified();


        },
        methods: {
            moment() {
                return moment();
            },
            checkTamperSeal() {
                var tamperSealLog = this.auditLog.find((f) => f.actionType.includes("Tamperseal applied"));
                if (tamperSealLog !== undefined) {
                    this.tampersealDate = tamperSealLog.timestamp;
                    this.tampersealStatus = tamperSealLog.actionType;
                }
            },
            setLastModified() {
                if (this.auditLog.length > 0) {
                    this.modifiedDate = this.auditLog[0].timestamp;
                    this.modifiedBy = this.auditLog[0].performedByName;
                }                
            }
        },

    };
</script>

<style scoped>
    .container {
        text-align: left;
    }

    table td, th {
        vertical-align: top;
        padding: 0.5em 1em 0em 0em;
    }

    .audit_list {
        margin: 0;
    }

    .audit_list ul {
        margin: 0;
        padding: 0;
        height: 400px;
        width: 100%;
        float: left;
        overflow-y: scroll;
    }

    .audit_list ul li {
        margin: 5px 0 15px 0;
        list-style: none;
        padding: 2px 0 8px 0;
    }

    .audit_list ul li:hover {
        background: rgba(78, 78, 78, 0.1);
    }

    .audit_list ul li .icon {
        float: left;
        margin: 6px 10px 0 0;
    }

    .audit_list ul li .icon .material-icons {
        font-size: 35px;
    }

    .audit_list ul li .title {
        font: 600 1.14em "Lato", sans-serif;
    }

    .audit_list ul li .time {
    }

    .pdfexport {
        width: 6.5in;
        font-size: 9px;
        overflow: hidden;
    }

    .pdfexport h1 {
        font-size: 14px;
    }

    .pdfexport h2, .pdfexport h3, h2 {
        font-size: 12px !important;
    }

    .pdfexport .logo img {
        width: 50% !important;
        margin-top: 10px;
    }

    .pdfexport td, th {
        padding: 0 2px 0 2px;
    }

    .detailsName {
        width:1.8in;
    }

    .detailValue {
        width:3.5in;
        word-wrap: anywhere;
    }
</style>
