<template>

    <div class="content-loader-overlay" :class="{'fixed-position': isSigningRoom}" v-if="documentNumber < totalDocumentCount || totalDocumentCount == 0">
        <loading-card  :percentage="percentage" :name="name" :documentNumber="documentNumber" :totalDocumentCount="totalDocumentCount" :title="'Preparing Signing Room...'" />
    </div>

</template>

<script>
    import moment from "moment";

    import LoadingCard from "@/components/LoadingCard.vue";

    export default {
        name: "ContentLoaderOverlay",
        props: ["totalDocumentCount", "isSigningRoom"],
        emits: [],
        components: {
            LoadingCard
        },
        data() {
            return {
                documentNumber: 0,
                name: 'Loading Document'
            }
        },
        computed: {
            moment: () => moment,
            percentage() {
                let percent = this.totalDocumentCount === 0 ? 0 : parseInt((this.documentNumber / this.totalDocumentCount) * 100);
                return percent;
            }
        },

        created() {

        },
        async mounted() {

        },
        methods: {
            updateNextDocCount() {
                this.documentNumber++;
            },
            updateNextDocName(val) {
                if (val)
                    this.name = val;
            },
        }
    };
</script>

<style scoped>

    .content-loader-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #FFF;
        z-index: 110;
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .content-loader-overlay .card {
            width: 45%;
        }

        .content-loader-overlay.fixed-position {
            position: fixed;
            height: 100vh;
        }
</style>
