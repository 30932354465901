<template>
    <div class="col-md-12" style="margin-top:20px">
        <div class="cert-grid" style="margin-left:5rem">
            <div class="form-fields">
                <div class="p-m col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div class="row row-flex">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="btn-screen-page position-right">
                                    <k-button class="k-button k-primary" @click="verifyCertificate()" ref="button" title="Upload certificate file and and then click on VERIFY">
                                        VERIFY
                                    </k-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-flex">
                        <div class="col-md-12">
                            <div class="row">
                                <label for="DigitalCertificate">Digital Certificate</label>
                                <input type="file" name="uploadDoc" id="uploadDoc" v-on:change="attachFile" />
                                <p v-if="verified" style="padding-top:2rem; color:green">
                                    {{cert}}
                                    <div class="item">
                                        <img v-if="verified" src="/images/verified_user.png" id="verifiedImg" alt="" class="img-resonsive">
                                        <span class="caption">Verified</span>
                                    </div>
                                </p>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <label for="CertificatePassword">Password</label>
                                <input type="password" v-model="password" name="password" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <br />
                                <k-button class="k-button k-primary" @click="updateCertificate()" ref="button">
                                    UPDATE
                                </k-button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</template>

<style>

    .p-m {
        padding: 16px;
    }

    .pb-m {
        padding-bottom: 16px;
    }

    div.item {
        vertical-align: top;
        display: inline-block;
        text-align: center;
        width: 30%;
    }

    #verifiedImg {
        width: 30%;
        height: 30%;
    }

    .caption {
        display: block;
    }

</style>

<script>

    import ApiService from "@/core/services/api-service";
    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";

    export default {
        name: 'VerifyCertificate',
        emits: ["componentValid"],
        props: ["preloadedData"],
        data() {
            return {
                password: '',
                attachment: null,
                cert: '',
                verified: false
            }
        },
        computed: {},
        watch: {},
        async created() {
            this.getUserProfile();
        },
        methods: {
            attachFile(e) {
                this.attachment = e.target.files[0];
            },
            async getUserProfile() {
                await ApiService.GenerateGetRequest(
                    "Admin/GetUserProfile"
                ).then((response) => {
                    console.log("Admin/GetUserProfile", response);
                    this.verified = response.content.isValidCertificate;
                    var str = response.content.certificate; 
                    var n = str.lastIndexOf('\\');
                    this.cert = str.substring(n + 1);
                });
            },
            async updateCertificate() {

                if (this.password === "" && this.attachment === null) {
                    Swal.fire("Select certificate and enter password!", "", "error");
                } else if (this.password === "" || this.attachment === null) {
                    if (this.password === "") {
                        Swal.fire("Enter password!", "", "error");
                    }
                    if (this.attachment === null) {
                        Swal.fire("Select certificate!", "", "error");
                    }
                }

                var files = this.attachment;
                var data = new FormData();
                data.append("File", files);
                data.append("Password", this.password);

                if (this.password !== "" && this.attachment !== "") {
                    let response = await ApiService.GeneratePostRequest("admin/certificate/upload", data);
                    console.log("Upload Response");
                    console.log(response);

                    if (response["isSuccess"]) {
                        console.log(response["message"]);
                        Swal.fire("Certificate Updated!", "", "success");
                        this.updateMenuAccess();
                    } else {
                        Swal.fire("Certificate Could Not Be Updated!", "", "error");
                    }

                    $(this.$refs.modalSealRef).modal("hide");
                }

            },
            async verifyCertificate() {
                let response = await ApiService.GeneratePostRequest("admin/certificate/verify");
                console.log("Verify Response");
                console.log(response);

                if (response["isSuccess"]) {
                    console.log(response["isSuccess"]);
                    Swal.fire("Certificate is Valid!", "", "success");
                    this.getUserProfile();
                    this.updateMenuAccess();
                } else {
                    Swal.fire("Certificate is Invalid!", "", "warning");
                }

                $(this.$refs.modalSealRef).modal("hide");

            },
            async updateMenuAccess() {
                let res = await ApiService.GenerateGetRequest("Admin/hasMenuPermission");
                console.log("Get Request Res", res);
                if (res.isSuccess)
                    this.$store.commit("Admin/updateMenuAccess", { hasAccessToMenu: res.content });
            }
        },
    }
</script>
