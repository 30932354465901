<template>
    <a href="#" @click="this.onClick" class="link">{{item.packageName}}</a>
</template>
<!--<router-link to="'/admin/detail/{{item.loanNumber}}'">{{item.loanNumber}}</router-link>-->
<style>
    a.link {
        font: 600 1.02em/1.05em 'Arial','Open Sans',sans-serif;
        color: #4d97ac !important;
        word-break: break-word;
    }
</style>
<script>
    import router from "@/router/index";
    export default {
        name: "dashboardCanViewLoanCellTemplate",
        methods: {
            onClick: function (e) {
                e.preventDefault();
                router.push('/admin/detail/' + this.templateArgs.loanPackageID);
            }
        },
        data() {
            return {
                templateArgs: {},
            };
        },
        computed: {
            item() {
                //console.log(this.templateArgs);
                return this.templateArgs;
            },
            cssId() {
                return this.$options._scopeId;
            },
            selected: {
                get() {
                    console.log(this);
                    return this.item.selected;
                },
                set(value) {
                    console.log(this, value);
                    this.templateArgs.parentComponent.updateSelection(this.item, value);
                }
            }
        }
    };
</script>
