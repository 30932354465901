<template>
    <section id="declined-page">
        <div class="declined-page-main">
            <p v-html="declined"></p>
            <div class="confirm-block">
                <div class="go-back">
                    <router-link :to="prevRoutePath" style="color: white; text-decoration: none">Go Back</router-link>
                </div>
                <button class="confirm-final-decline" @click="confirmDecline"> Confirm
                    <!--<router-link to="/declinedfinal" style="color: white; text-decoration: none">Confirm</router-link>-->
                </button>
            </div>
        </div>
    </section>
</template>
<style>

    .declined-page-main h1 {
        padding-top: 5%;
        font-size: 45px;
        text-align: center;
    }

    .declined-page-main p {
        font-size: 30px;
        text-align: center;
    }

    .go-back {
        background: #28a745;
        font-size: 20px;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        cursor: pointer;
    }


    .go-back:hover {
        background: linear-gradient(rgba(0, 153, 0, 1), rgba(0, 153, 0, 1));
        cursor: pointer;
    }

    .confirm-final-decline {
        background: #17a2b8;
        font-size: 20px;
        color: #fff;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }

    .confirm-final-decline:hover {
        background: linear-gradient(rgba(0, 153, 153, 1), rgba(0, 153, 153, 1));
        cursor: pointer;
    }

    .confirm-block {
        display: flex;
        justify-content: space-evenly;
        padding-left: 25%;
        padding-right: 25%;
    }
</style>
<script>
    import ApiService from "../../core/services/api-service";
    export default {
        name: 'Declined',
        data() {
            this.readData();
            return {
                declined: [],
                prevRoute: null,
                lenderid: null,
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from;
            });
        },
        computed: {
            prevRoutePath() {
                console.log(this.prevRoute);
                return this.prevRoute ? this.prevRoute.path : '/signer/details'
            },
        },
        methods: {
            async readData() {
                let lenderid = this.$store.state.App.lenderId;
                return ApiService.GenerateGetRequest(`econsent/declined?lenderid=${lenderid}`).
                    then((response) => {
                        this.declined = response["content"];
                        console.log(response["content"]);
                    });
            },

            confirmDecline() {
                this.signerData = this.$store.getters["Auth/signerSessionInformation"];
                this.lenderid = this.$store.state.App.lenderId;               

                ApiService.GenerateGetRequest(`econsent/consent?lenderid=${this.lenderid}`).
                    then((response) => {
                        ApiService.GeneratePostRequest(`verify/update`, { "agreement": false, "eConsentText": response["content"] }).then((response) => {
                            if (response.isSuccess) {
                                this.$store.commit("App/updateTermsAndConditions", { AgreedTo: false });
                                var payload = {
                                    "SignerId": this.signerData["signerId"],
                                    "SignerName": this.signerData["firstName"] + " " + this.signerData["lastName"],
                                    "SignerEmail": this.signerData["emailAddress"],
                                    "PackageName": this.signerData["description"],
                                    "NotarizeId": this.signerData["notarizeId"],
                                    "RequesterId": this.signerData["requesterId"],
                                    "Lender": this.signerData["lender"]
                                };
                                ApiService.GeneratePostRequest(`econsent/declined/sendBorrowerNotification`, payload).then(() => {
                                    ApiService.GeneratePostRequest(`econsent/declined/sendLenderNotification`, payload).then(() => {
                                        try {
                                            window.parent.CustomSigningDecline()
                                        } catch (error) {
                                            console.error(error)
                                        }

                                        this.$router.push("/declinedfinal");
                                    });
                                });


                            }
                            else {
                                Swal.fire({
                                    title: 'Failed to update eConsent',
                                    text: response.message,
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                });
                            }
                        });
                    });            
            }
        },
    }
</script>