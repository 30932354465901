<template>
    <div>
        <videosession />
    </div>
    <div>
        <sharescreensession />
    </div>
</template>
<style scoped>
    
</style>

<script>
    import VideoSession from '@/components/VideoSession.vue';
    import ShareScreenSession from '@/components/ShareScreenSession.vue';

    export default {
        name: 'VideoTest',
        components: {
            videosession: VideoSession,
            sharescreensession: ShareScreenSession,
        },
        data() {
        },
        computed: {
            
        },
        methods: {
            
        },
    }
</script>