<template>
    <div :class="Type">
        <div class="s-blur" v-show="showPopup"></div>
        <div id="all-video" style="right:.5em;">


            <div id="videochat">
                <div id="subscriber-container"></div>
                <div id="publisher-container"></div><br />
            </div>
            <div id="call-btns">
                    <kbutton @click="viewSharescreen" v-if="Type=='Notary' && callClicked" class="kbtn" style="background: #63aef7; color: white; min-width:190px;">View Signer Screen</kbutton>
                    <kbutton @click="sendSharescreen" v-if="Type=='Signer' && callClicked && !sharing" class="kbtn" style="background: rgb(76, 182, 76); color: white; min-width: 170px;">Share My Screen</kbutton>
                    <kbutton @click="stopSharescreen" v-if="Type=='Signer' && callClicked && sharing" class="kbtn" style="background: rgba(255, 104, 32, 0.87); color: white; min-width: 170px;">Stop Sharing</kbutton>
                <div id="video-btns">
                    <kbutton @click="initializeSession" v-if="!callClicked" class="start-btn kbtn" style="background: rgb(76, 182, 76); color:white;">Connect Video</kbutton>
                    <kbutton @click="disconnectSession" v-if="callClicked" class="end-btn kbtn" style="background:red; color: white; min-width:170px;">Stop Video</kbutton>
                </div>
            </div>
        </div>

        <div v-show="showPopup" id="subscriber-sharescreen">
            <div style="text-align:center;">
                <kbutton @click="closePopup" style="float:right;">Close</kbutton>
                <h3>Signer Screen</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import OT from '@opentok/client';
    import ApiService from "@/core/services/api-service";
    import { Popup } from "@progress/kendo-vue-popup";
    import { Button } from "@progress/kendo-vue-buttons";
    import { videoConnection, publishVideo, disconnectVideo, publishScreen, stopSharescreen} from '../OTConnection';

    const errorHandler = err => {
        console.log("error message for videoshare", err);
    };

    var streamCreated = false;

    export default {
        name: 'videosession',
        props: ['NotarizeID', 'Type'],
        emits: ["startJournal"],
        components: {
            'Popup': Popup,
            'kbutton': Button
        },
        data() {
            return {
                name: "",
            }
        },
        created() {
            window.addEventListener('beforeunload', this.beforeWindowUnload);
        },
        async mounted() {
            if (this.Type == 'Notary') {
                var adminData = this.$store.getters["Admin/loginInformation"];
                this.name = adminData["firstName"] + ' ' + adminData["lastName"];
            }
            else {
                var signerData = this.$store.getters["Auth/signerSessionInformation"];
                console.log("signerDate", signerData);
                this.name = signerData["firstName"] + " " + signerData["middleName"] + " " + signerData["lastName"];
            }
        },
        beforeUnmount() {
            console.log("unmounting session");
            this.disconnectSession();
        },
        data: () => ({
            callClicked: false,
            streaming: false,
            showPopup: false,
            newScreen: false,
            sharing: false,
        }),
        methods: {
            errorHandler,
            handleError: function (error) {
                if (error) {
                    alert(error.message);
                }
            },
            viewSharescreen() {
                this.showPopup = true;
            },
            closePopup() {
                this.showPopup = false;
            },
            sendSharescreen() {
                publishScreen('subscriber-sharescreen', this.name, this.callbacks);
                this.sharing = true;
            },
            stopSharescreen() {
                console.log("stop sthe sharescreen");
                stopSharescreen();
                this.sharing = false;
            },
            callbacks(data) {
                switch (data) {
                    case "showPopup":
                        this.viewSharescreen();
                        break;
                    case "stopSharing":
                        this.stopSharescreen();
                        break;
                }

            },
            async initializeSession() {
                this.callClicked = true;

                if (this.streaming = false)
                    console.log("reconnecting video");
                await videoConnection(this.NotarizeID, { name: 'subscriber-container', height: '180px', width: '200px' }, 'subscriber-sharescreen', this.callbacks, this.Type);

                if (this.streaming == false) {
                    this.streaming = true

                    //log call started
                    this.logSession(true);
                }

                await publishVideo({ name: 'publisher-container', height: '180px', width: '200px' }, this.name);

                if (this.Type == "Notary") {
                    this.$emit('startJournal');
                }

            },

            disconnectSession: function () {
                disconnectVideo();
                this.callClicked = false;
                this.showPopup = false;

                if (this.streaming == true) {
                    this.streaming = false;

                    //Log call disconnected
                    this.logSession(false);
                }
                this.sharing = false;
                streamCreated = false;

                if (this.Type == "Notary") {
                    //end call for everyone
                    ApiService.GeneratePostRequest("transaction/endSession");
                }

            },
            logSession: function (connected) {
                var name;
                var appUserId = 0;
                var signerId = 0

                if (this.Type == 'Notary') {
                    var adminData = this.$store.getters["Admin/loginInformation"];
                    name = adminData["firstName"] + ' ' + adminData["lastName"];
                    appUserId = adminData["userId"]
                }
                else {
                    var signerData = this.$store.getters["Auth/signerSessionInformation"];
                    name = signerData["firstName"] + ' ' + signerData["lastName"];
                    signerId = signerData["signerId"];
                }
                var payload = {
                    "NotarizeID": this.NotarizeID,
                    "Type": this.Type,
                    "SignerId": signerId,
                    "Connected": connected,
                    "Name": name,
                    "AppUserId": appUserId
                };
                ApiService.GeneratePostRequest(`notarize/logmeeting`, payload);
            }
        },
    }
</script>

<style>
    .k-card-header {
        /*height: 215px; */
        margin-right: 1em;
    }

    #subscriber-container > .OT_subscriber {
        margin-left: .3em;
        margin-right: .3em;
        margin-top: 1rem;
    }

    #publisher-container > .OT_publisher {
        margin-left: .3em;
        margin-right: .3em;
        margin-top: 1rem;
    }

    #call-btns{
        clear:both;
        display:flex;
        align-items: center;
        justify-content: space-evenly;
        width:30%;
        margin:auto;
    }

    .s-popup {
        position: fixed !important;
        top: 10%;
        right: 0 !important;
        left: 0 !important;
        height: 80vh;
        width: 80vw;
        margin: auto;
    }

    .s-inner {
        width: 100%;
        height: 100%;
        background: white;
    }

    .Notary {
        width: 100%;
        float: right;
    }

    Notary > #all-video {
        margin-bottom: 2em;
    }

    .Notary > #subscriber-sharescreen {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 90vh;
        width: 90vw;
        z-index: 2;
        background: white;
        border: 4px solid grey;
        border-radius: 10px;
        padding: 0px;
    }

    .Notary > #subscriber-container > .OT_subscriber {
        margin: 1em;
    }

    .Signer > #all-video > #sharescreen-button {
        /*float: right;*/
    }

    .Signer > #all-video > #videochat > #call-btns {
        position: absolute;
        margin: auto;
        width: 50%;
        clear: both;
    }

    .Signer > #all-video > #videochat > #call-btns > .start-btn {
        width: 170px;
    }

    .Signer > #all-video > #videochat > #call-btns > .end-btn {
        width: 170px;
        position: relative;
        top: 50px;
        left: 98%;
    }

    .Notary > #subscriber-sharescreen > .OT_subscriber {
        height: 100%;
    }

    .Signer > #all-video {
        min-height: 200px;
        margin-bottom: 20px;
    }

    .Signer > #publisher-container > .OT_publisher {
        margin-left: 1em;
        margin-right: 1em;
    }

    .Notary > #all-video {
        margin: auto;
        width: 200px;
    }

    .Notary > #all-video > #videochat > #call-btns {
        /*float: left;*/
        margin-bottom: 1em;
        margin-top: 1em;
        width: 200px;
    }

    .kbtn {
        display: block;
        margin: 1em;
        min-width: 170px;
    }

    .s-blur {
        filter: blur(2px);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        background: #ffffff7a;
    }
</style>
