<template>
    <audio loop style="display:none" id="ringAudio" src="/uploads?path=ring.mp3" controls=""></audio>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container">
            <div v-bind:class="[{'col-md-6': showTesting}]" style="padding-right:2em">
                <div class="row row-flex">
                    <div class="col-xs-12">
                        <h4 class="text-center" style="padding-bottom:1rem;padding-top:2.2em;">Connected Participants</h4>
                        <hr style="color: #e9e9e9" />
                    </div>
                </div>
                <div class="row row-flex">
                    <div class="innerbox col-md-12 column-flex">
                        <div class="col-md-12 text-center" style="margin-top:2rem;">
                            <div  class="row" v-if="waitingConnections != null">
                                <h3 style="text-align: center" v-if="waitingConnections.length == 0">No Record(s) to display</h3>
                            </div>
                            <div v-for="(result, i) in waitingConnections" :key="i" style="border-style: ridge; border-color: #69696912; margin-bottom:1rem;">
                                <div class="bdr bdr-rad-10" style="min-height:160px;">
                                    
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <h3 class="col-md-12 text-left" style="margin-top: 10px;">
                                                <span style="font-weight:bold; color:black;">Name</span>
                                            </h3>
                                        </div>
                                        <div class="col-sm-10">
                                            <h3 class="col-md-12 text-left" style="margin-top: 10px;">
                                                <a @click="connectToUser(result.userID, result.contextID, result.notarizeID)" class="text-black" title="Click to Connect" style="cursor: pointer;">
                                                    {{result.firstName}} {{result.lastName}}
                                                </a>
                                                <span class="connected-status" :class="{'connected-status-away' : result.status === 'Away', 'connected-status-waiting': result.status === 'WaitingRoom', 'connected-status-present' : result.status === 'InRoom'}"></span>

                                            </h3>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <h3 class="col-md-12 text-left" v-if="result.propertyAddress != ''" style="margin-top: 10px;">
                                                <span style="font-weight:bold; color:black; white-space: pre-line">Address &nbsp &nbsp</span>
                                            </h3>
                                        </div>
                                        <div class="col-sm-10">
                                            <h3 class="col-md-12 text-left" v-if="result.propertyAddress != ''" style="margin-top: 10px;">
                                                <span style="padding-top: 0px;">{{result.propertyAddress}}</span>
                                            </h3>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <h3>
                                                <span class="col-md-12 text-left" style="font-weight: bold; color: black; margin-bottom:5rem">
                                                    {{result.frontDoc == ''? '' : result.documentTypeID == 1 ? 'License/State ID' : 'Passport'}}
                                                </span>
                                            </h3>
                                        </div>
                                        <div class="col-sm-10">
                                            <h3>
                                                <span class="col-md-12 text-left" style="font-weight: bold; color: black; margin-bottom:5rem">
                                                    <img v-if="result.documentTypeID != 0 && result.frontDoc != ''" v-bind:src="'data:image/png;base64,' + result.frontDoc" alt="" style="width: 50%;height: 50%;margin-left:3.25rem;" />
                                                    <img v-if="result.backDoc != ''" v-bind:src="'data:image/png;base64,' +  result.backDoc" alt="" style="width: 50%; height: 50%; margin-top: 1rem; margin-bottom: 1rem; margin-left:3.25rem" />
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>
            </div>
            <div v-bind:class="[{ 'left-border': showBorder }, {'col-md-6': showTesting}]">
                <setup @camID="getCamera" @micID="getMicrophone" @speakerID="getSpeaker" @location="getLocation" style="margin-top:.5rem"/>
            </div>
        </div>
    </section>
</template>

<script>
    import ApiService from "@/core/services/api-service";
    import Setup from "../components/forms/invitation-steps/test/WaitingSetup";
    window.url = require("../core/services/urls");
    import "@/video.js";

    export default {
        name: 'Waiting',
        components: {
            setup: Setup
        },
        data: () => ({
            isNotaryVerified: false,
            waitingConnections: null,
            connection: "",
            selectedCam: "default",
            selectedMic: "default",
            selectedSpeaker: "default",
            location: "",
            windowHeight: 0,
            windowWidth: 0,
            showBorder: true,
            showTesting: true
        }),
        async mounted() {

            //Fetch waiting connections
            var getWaiting = await ApiService.GenerateGetRequest("notarize/waiting");
            this.isNotaryVerified = getWaiting["message"];
            this.waitingConnections = getWaiting["content"];

            // Connect to our hub if notary is verified
            if (!this.isNotaryVerified) {
                $('#lstSignersInQueue').html('');
                $(this.$refs.VerifyCertificateModal).modal("show");
            }

            window.addEventListener('resize', () => {
                this.windowHeight = window.innerHeight;
                this.windowWidth = window.innerWidth;
                if (this.windowWidth < 980) {
                    this.showBorder = false;
                } else {
                    this.showBorder = true;
                }
                console.log("WINDOWHEIGHT", this.windowHeight);
                console.log("WINDOWWIDTH", this.windowWidth);
                console.log("SHOWBORDER", this.showBorder);
            });
        },
        methods: {
            getCamera(value) {
                console.log("getCamera: ", value);
                if (value != "") {
                    this.selectedCam = value;
                }
            },
            getMicrophone(value) {
                console.log("getMicrophone: ", value);
                if (value != "") {
                    this.selectedMic = value;
                }
            },
            getSpeaker(value) {
                console.log("getSpeaker: ", value);
                if (value != "") {
                    this.selectedSpeaker = value;
                }
            },
            getLocation(value) {
                console.log("getLocation: ", value);
                if (value != "") {
                    this.location = value;
                }
            },
            Save() {
                var config = {
                    UserId: 0,
                    MicID: this.selectedMic,
                    CamID: this.selectedCam,
                    SpeakerID: this.selectedSpeaker,
                    Location: this.location
                };
                console.log("config: ", config);
                //POST USERSYSTEMCONFIG
                return ApiService.GeneratePostRequest("user/addconfig", config).then(
                    response => {
                        console.log("user/addconfig:", response["content"]);
                        if (response["isSuccess"]) {
                            //HANDLE ROUTING
                            this.$emit("deviceSetup", true);
                        } else {
                            this.$emit("deviceSetup", false);
                        }
                    }
                );
            },
            connectToUser(UserID, ContextID, NotarizeID) {
                this.Save();
               // ApiService.GeneratePostRequest(`transaction/sendvideoinvite?receiverID=` + UserID + '&NotarizeID=' + NotarizeID);
                 ApiService.GeneratePostRequest(`transaction/cancelThisNotary?signerConenctionID=` + ContextID);
                // another method to pass values
                this.$store.commit("Auth/updateSigningRoomData",

                    {
                        userId: UserID,
                        contextId: ContextID,
                        notarizeId: NotarizeID,
                        hist: "Waiting"
                    });
                this.$router.push({
                    name: 'AdminNSigningRoom',
                    path: `/admin/signing`,
                    params: {
                        UserID: UserID,
                        ContextID: ContextID,
                        NotarizeID: NotarizeID
                    }
                });
            },
        },
    }
</script>


<style scoped>

    .left-border {
        border-style: solid;
        border-bottom: none;
        border-top: none;
        border-right: none;
        border-color: #e9e9e9;
        border-width: thin;
    }

    .bdr {
        background: #fff;
        border: none;
    }

    .connected-status {
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right:0.5em;
        border-radius: 50%;
    }

    .connected-status-present {
        background: green;
    }


    .connected-status-waiting {
        background: #ccb601;
    }


    .connected-status-away {
        background: red;
    }
</style>
