<template>
  <div class="audit_list">
      <div>
          <div style="text-align:right;">
              <k-button class="k-button k-primary" @click="printPDF">
                  Print PDF
              </k-button>
          </div>
          <div style="overflow:hidden; height:0;">
          
              <AuditLogPDF ref="pdfLog" :docId="docId" :pckgId="pckgId"/>
          </div>
      </div>
    <ul>
      <li v-for="item in auditLog" :key="item.auditLogID">
        <div style="display:inline-block">
          <div class="icon">
            <i class="material-icons">{{ item.operation.toLowerCase() }}</i>
          </div>
        </div>
        <div style="display:inline-block">
          <div class="title">{{ item.detailDescription }}</div>
          <div class="time">{{ item.performedByName }}</div>
          <div class="time">
              {{ moment.parseZone(item.timestamp).format("MM/DD/YYYY hh:mm A")}}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
    import moment from "moment";
    import AuditLogPDF from "@/components/AuditLogs/AuditLogPDF";
    import { Button } from "@progress/kendo-vue-buttons";
    import ApiService from "@/core/services/api-service";

export default {
  name: "AuditLog",
        props: ["auditLog", "docId","documentParticipants", "pckgId"],
        components: {
            AuditLogPDF: AuditLogPDF,
            "k-button": Button,
        },
        computed: { moment: () => moment },
        methods:{
            printPDF: function () {
               
                console.log("clicked print pdf", this.docId, this.pckgId);

                 //fetch log from backend
                var requestURL = "document/auditlog/package?packageId=" + this.pckgId;
                if (this.docId != undefined) requestURL = "document/auditlog/document?documentId=" + this.docId;

                return ApiService.GenerateGetBlobRequest(requestURL)
                    .then((blob) => {
                        console.log("BLOB", blob)
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(blob);
                        a.download = (this.docId != undefined ? "Document" : "Package") + `AuditLog-${moment(Date.now()).format('MM/DD/YYYY')}.pdf`
                        a.click();
                    })
                    .catch((e) => {
                        console.error(e);
                    }); 
               
                //this function exports audit log from front end
                //this.$refs.pdfLog.exportPDFWithComponent();
            },
            reloadPDF: function (packageAuditLog = null, consentAuditLog = null){
                this.$refs.pdfLog.populateLog(packageAuditLog, consentAuditLog);
                console.log("reloadPDF called in AuditLog.vue");
            },
        },
};
</script>

<style scoped>
.audit_list {
  margin: 0;
}

.audit_list ul {
  margin: 0;
  padding: 0;
  height: 400px;
  width: 100%;
  float: left;
  overflow-y: scroll;
}

.audit_list ul li {
  margin: 5px 0 15px 0;
  list-style: none;
  padding: 2px 0 8px 0;
}

.audit_list ul li:hover {
  background: rgba(78, 78, 78, 0.1);
}

.audit_list ul li .icon {
  float: left;
  margin: 6px 10px 0 0;
}

.audit_list ul li .icon .material-icons {
  font-size: 35px;
}

.audit_list ul li .title {
  font: 600 1.14em "Lato", sans-serif;
}

.audit_list ul li .time {
}
</style>
