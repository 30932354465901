<template>
    <form v-on:submit.prevent @submit="submit" class="row mt-20">
        <div class="col-md-6 col-sm-12">
            <button type="button"
                    class="send-code-btn"
                    v-on:click="send2FACode"
                    :disabled="disable2FA">
                {{ disable2FA ? 'Resend SMS' : 'Send Code Via SMS' }}
                <!--Send Verification Code-->
            </button>
        </div>

        <div class="col-sm-6 col-xs-12">
            <button type="button"
                    class="send-code-btn"
                    v-on:click="send2FACodeEmail"
                    :disabled="disable2FA">
                {{ disable2FA ? 'Resend Email' : 'Send Code Via Email' }}
                <!--Send Verification Code-->
            </button>
        </div>

        <div v-if="displayInput">
            <p class="text-center" style="color: #ffffff;">Smart eSign sent you the 6-digit code. Please enter it to confirm your sign in.</p>
            <p class="text-center" style="color: #ffffff;">If you do not receive a code within 60 seconds, please click try again.</p>
            <div class="form-group"
                 v-bind:class="{'has-error':errormsg}">
                <label for="SecurityCode" style="color:red !important; font-weight:bold">{{errormsg}}</label>
                <input class="form-control"
                       id="SecurityCode"
                       name="SecurityCode"
                       placeholder="Verification Code"
                       type="text"
                       required
                       minlength="6"
                       v-model="securityCode" />
            </div>

            <input type="submit" value="Confirm" class="send-code-btn" />
        </div>
    </form>
</template>

<style scoped>
    .send-code-btn {
        display: block;
        background-color: #202020;
        border: none;
        width: 100%;
        margin-bottom: 15px;
        border-radius: 4px;
        font-size: 14px;
        text-transform: uppercase;
        padding: 8px 10px;
        color: #ffffff;
    }

        .btn:hover {
            color: #ffffff;
            background-color: #000000;
        }

    #screen-page p {
        color: #ffffff !important;
        font-size: 16px;
    }
</style>

<script>
    import ApiService from "@/core/services/api-service";
    import store from "../store/index";
    import CookieService from "@/core/services/cookie-service";
    import Swal from 'sweetalert2';
    const MAXTIME = 60;
    export default {
        name: "TwoFactorVerification",
        props: ["inviteToken"],
        emits: ["updateStore"],
        data() {

            return {
                securityCode: "",
                authId: 0,
                displayInput: false,
                disable2FA: false,
                seconds: MAXTIME,
                interval: null,
                errormsg: "",
            };
        },
        mounted() {
            let cookie = CookieService.Get("stt"); //cookie name stt
            let d = new Date(CookieService.Get("sa")); //cookie name stt

            // only start timer on refresh if last date the timer was set is less than current date (old)
            if (d.setSeconds(d.getSeconds() + parseInt(cookie)) > new Date() && cookie != "") {
                this.startTimer();
                return;
            }
            CookieService.Delete("stt")
            CookieService.Delete("sa");
        },
        methods: {
            startTimer(seconds) {
                this.disable2FA = true;
                if (this.interval)
                    clearInterval(this.interval);
                let cookie = CookieService.Get("stt"); //cookie name stt
                if (cookie === "") {
                    this.seconds = MAXTIME;
                    CookieService.Create("stt", this.seconds);
                    CookieService.Create("sa", new Date());
                } else {
                    this.seconds = seconds || parseInt(cookie);
                }
                this.interval = setInterval(() => {
                    this.seconds -= 1;
                    CookieService.Update("stt", this.seconds);
                    if (this.seconds === 0) {
                        clearInterval(this.interval);
                        this.disable2FA = false;
                        CookieService.Delete("stt");
                        CookieService.Delete("sa");
                    }
                }, 1000);
            },
            async send2FACode() {
                this.sms = true;
                let response = await ApiService.GeneratePostRequest(
                    "verify/send_2fa?type=sms&signerInviteToken=" + this.inviteToken,
                    null
                );

                if (response.isSuccess) {

                    this.startTimer();
                    this.authId = response.content;
                    this.displayInput = true;
                } else {
                    Swal.fire({
                        title: 'Unable to send two factor code',
                        text: response.message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            },
            async send2FACodeEmail() {
                this.email = true;
                let response = await ApiService.GeneratePostRequest(
                    "verify/send_2fa?type=email&signerInviteToken=" + this.inviteToken,
                    null
                );

                if (response.isSuccess) {

                    this.startTimer();
                    this.authId = response.content;
                    this.displayInput = true;
                } else {
                    Swal.fire({
                        title: 'Unable to send two factor code',
                        text: response.message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            },
            async submit() {
                let response = await ApiService.GeneratePostRequest("verify/verify_2fa", {
                    authId: parseInt(this.authId),
                    code: this.securityCode,
                    signerInviteToken: this.inviteToken,
                });
                if (!response.isSuccess) {
                    this.errormsg = response.message;
                } else {
                    this.errormsg = "";
                    store.commit("App/updateToken", response.content.token);
                }
                this.$emit("updateStore", response);

            }
        },
    };
</script>