<template>
    <div style="padding-bottom: 50px;">
        <section>
            <div class="container personal-signup">
                <div class="col-md-offset-3 col-md-6 col-xs-12">
                    <div class="bdr-rad-10 innerbox darkblue-bg"
                         style="display: flex; align-items: center; justify-content: center">
                        <div style="width: 100%">
                            <img :src="Logo" alt="" />
                            <div v-if="invitationValid">
                                <h2>
                                    Loading Signing Room<br />
                                    Please Wait...
                                </h2>
                            </div>
                            <div v-else>
                                <!--<h2 style="color:white !important; margin-top:20px">{{this.invitationText}}</h2>-->
                                <div v-html="errorText"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api-service";
    export default {
        name: "AuthenticateIdentityToken",
        components: {
        },
        data() {
            return {
                invitationText: "",
                skipAuth: false,
                invitationValid: false,
                errorText: ""
            };
        },
        async created() {
            this.$store.commit("App/updateLoaderOverride", { showLoaderOverride: false });

            // check if old invite token exists
            let oldInviteToken = this.$store.getters["Auth/invite"];
            let { t: token } = this.$route.params;
            this.inviteToken = token;

            // reset app state if new invite token.
            if (oldInviteToken !== token) {
                this.$store.commit("Auth/resetAuthState");
                this.$store.commit("Invitation/resetInvitationState");
                this.$store.commit("App/resetAppState");
                this.$store.commit("Auth/updateInviteToken", { invite: token });
            }

            var signingDetailsResponse = await ApiService.GenerateGetRequest(
                `signer/invite?identitytoken=${this.inviteToken}`
            );

            console.log("Signing Detail", signingDetailsResponse)
            if (signingDetailsResponse["isSuccess"]) {
                this.invitationValid = true;
                this.skipAuth = true;
                this.$store.commit("App/updateLenderId", { LenderID: signingDetailsResponse["content"]["lenderID"] });
                this.$store.commit("App/updateAppSettings", signingDetailsResponse["content"]["settings"]);
                // since we skipping 2fa we need to get sigenr details directly.
                // we usually got it after verify_2fa call. so this is  just the opposite.
                let response = await ApiService.GeneratePostRequest("verify/skip_2fa", {
                    signerInviteToken: this.inviteToken,
                });
                console.log("SKIP2FA", response);
                if (response["isSuccess"]) {
                    if ((!signingDetailsResponse.content.settings.skip2Factor && response.content.role != "Witness") || response["content"]["isRon"]) {// enable  back 2fa check for ron
                       console.log("skip 2 factor off");
                       this.$store.commit("Invitation/enableRon", response["content"]["isRon"]);
                       this.$router.push({ path: `/authenticate/${this.inviteToken}` });
                    }else{
                        this.$store.commit("App/updateToken", response["content"]["token"]);
                        this.updateStore(response);
                        this.$router.push({ name: "TermsAndConditions" });
                    }
                }
            }else if (!signingDetailsResponse.isSuccess && signingDetailsResponse.content == "redirect") {
                this.$router.push("/admin")
            } else {
                this.errorText = "<h2 style=\"color: white!important; margin-top: 20px\">" + signingDetailsResponse.message + "</h2>"
            }
        },
        computed: {
            alreadyAgreed() {
                return this.$store.getters["App/termsAndConditions"];
            },
        },
        methods: {
            async updateStore(response) {
                this.serverMsg = "";
                if (this.inviteToken !== null) {
                    // GET JWT Token


                    if (response && response["isSuccess"]) {
                        this.$store.commit("Auth/signInSuccessful", {
                            token: response["content"]["token"],
                            invite: this.inviteToken,
                        });

                        //this.$store.commit("Auth/signInSuccessful", { token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaWduZXJJZCI6IjEyODAiLCJub3Rhcml6ZUlkIjoiOTAyIiwiaWF0Ijo2Mzc2OTY2ODc5MzQ3MzQ0MjMsImV4cCI6MTYzNDA3NTU5MywiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NTAwMDEiLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo1MDAwMSJ9.qPULI2NzqheX-Va0CyojYMTC6aihta_2Z1RUsXghU5g", invite: this.inviteToken });
                        this.$store.commit("App/updateVerificationSteps", {
                            VerificationSteps: response["content"]["verificationSteps"],
                        });

                        let stepIndex = response["content"]["verificationSteps"].findIndex(x => x.stepOrder == response["content"]["signerVerification"]["stepOrderId"]);
                        if (response["content"]["signerVerification"]) {
                            this.$store.commit("App/updateSignerVerificationData", {
                                SignerId: response["content"]["signerVerification"]["signerId"],
                                NotarizeId:
                                    response["content"]["signerVerification"]["notarizeId"],
                                AgreedToS: response["content"]["signerVerification"]["agreedToS"],
                                StepOrderId: stepIndex,
                            });
                        }

                        // Get Signer Details
                        let invite_response = await ApiService.GenerateGetRequest("signer/getInvite", {
                            identityToken: this.inviteToken, //"5c17057d-8e77-4dff-a351-68befa7b4a3f",
                        });
                        if (invite_response && invite_response["isSuccess"]) {
                            this.$store.commit("Auth/updateSignerSessionInfo", invite_response["content"]
                            );
                        }

                        this.$store.commit("App/updateRole", {
                            role: response["content"]["role"],
                        });

                        this.$store.commit("App/updateTermsAndConditions", {
                            AgreedTo: response["content"]["signerVerification"]["agreedToS"],
                        });

                    } else {
                        this.serverMsg = invite_response["message"];
                    }
                }
            },
        },
    };
</script>