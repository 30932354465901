<template>
    <div class=" col-md-12 text-center">
        <input type="hidden" id="CameraID" name="CameraID">

        <div class="row">
            <div class="col-xs-2">
                <img src="/images/icon-wifi.png" alt="" class="img-resonsive">
            </div>
            <div class="col-xs-10 text-left">
                <h3>Internet Connection</h3>
                <input type="button" value="Test Connection" @click="handleSpeedTest" class="btn-primary btn-block btn" id="btnTestInternetConnection" />
                <p id="connectionSpeed" style="margin-top:2rem">Your connection speed is: </p>
            </div>
        </div>
        <p></p>
        <!--<br>
    <div class="row">
        <div class="progress progress-striped" id="progessBar">
            <div role="progressbar progress-striped" style="width: 100%;" class="progress-bar"><span></span></div>
        </div>
    </div>-->
        <br>
    </div>
</template>
<style>
    #box{
        margin: 0em 0em 0em 5em;
    }
</style>
<script>
    export default {
        name: 'TestInternetConnection',
        data() {
            return {
                deviceInfos: [],
                cameraCount: 0,
                showVideo: false,
                selectedCamera: null,
            }
        },
        mounted() {
            //handleSpeedTest();
        },
        methods: {
            isValid() {
                console.log("hello");
                return true;
            },
            handleSpeedTest() {

                var imageAddr = "/images/file-download.png";
                var downloadSize = 8732000; //bytes
                var showInternetSpeed = false;

                function ShowProgressMessage(msg, MBs) {
                    //if (console) {
                    //    if (typeof msg == "string") {
                    //        console.log(msg);
                    //    } else {
                    //        for (var i = 0; i < msg.length; i++) {
                    //            console.log(msg[i]);
                    //        }
                    //    }
                    //}

                    var oProgress = document.getElementById("connectionSpeed");
                    if (oProgress) {
                        var actualHTML = (typeof msg == "string") ? msg : msg.join("<br /><br />");
                        var speedMsg = '';

                        if (MBs != undefined) {
                            if (MBs < 2)
                                speedMsg = '<b><span class="label label-danger">Low Connection</span></b>';
                            else if (MBs < 5)
                                speedMsg = '<b><span class="label label-success">Good Connection</span></b>';

                            $('#progessBar').removeClass('active');
                        }
                        oProgress.innerHTML = actualHTML + '</br>' + speedMsg;
                    }
                }

                function InitiateSpeedDetection() {
                    ShowProgressMessage("Testing your internet connection, please wait...");
                    window.setTimeout(MeasureConnectionSpeed, 1);
                };

                InitiateSpeedDetection();

                function MeasureConnectionSpeed() {
                    var startTime, endTime;
                    var download = new Image();
                    download.onload = function () {
                        endTime = (new Date()).getTime();
                        showResults();
                    }

                    download.onerror = function (err, msg) {
                        ShowProgressMessage("Connection is slow or test file not found");
                    }

                    startTime = (new Date()).getTime();
                    var cacheBuster = "?n=" + startTime;
                    download.src = imageAddr + cacheBuster;

                    function showResults() {
                        var duration = (endTime - startTime) / 1000;
                        var bitsLoaded = downloadSize * 8;
                        var speedBps = (bitsLoaded / duration).toFixed(2);
                        var speedKbps = (speedBps / 1024).toFixed(2);
                        var speedMbps = (speedKbps / 1024).toFixed(2);
                        ShowProgressMessage([
                            "Your connection speed is:",
                            //speedBps + " bps",
                            //speedKbps + " kbps",
                            speedMbps + " Mbps"
                        ], speedMbps);
                        $('#btnContinue').show();
                    }
                }
            }

        },
    }


</script>
<style scoped>
    .pids-wrapper {
        width: 100%;
    }

    .pid {
        width: calc(10% - 10px);
        height: 10px;
        display: inline-block;
        margin: 5px;
    }
</style>