<template>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container">
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <div class="col-md-12 text-center">
                        <h2>Test Audio/Video</h2>
                    </div>
                </div>
            </div>
            <div class="row row-flex">
                <div class="innerbox col-md-6 column-flex">
                    <div class=" col-md-12 text-center">
                        <ul class="list-inline">
                            <li><img src="/images/icon-video.png" alt="" class="img-resonsive"></li>
                            <li><img src="/images/icon-mic.png" alt="" class="img-resonsive"></li>
                            <li><img src="/images/icon-headphone.png" alt="" class="img-resonsive"></li>
                            <li><img src="/images/icon-wifi.png" alt="" class="img-resonsive"></li>
                        </ul>
                        <p>Setup your Audio/Video connection. Please click <b>&ldquo;Allow Access&rdquo;</b> to your Camera and Mic when Prompted.</p>
                        <k-button :primary="true" @click="isContinue">CONTINUE</k-button>
                    </div>
                </div>
                <div class="innerbox dark-bg col-md-6 column-flex">
                    <div class=" col-md-12">
                        <br>
                        <br>
                        <br>
                        <ul class="signup-process">
                            <li> <span class="badge">1</span> Camera</li>
                            <li> <span class="badge">2</span> Audio</li>
                            <li> <span class="badge">3</span> {{user.userTypeId == 4 ? 'Internet Connection' : 'Location'}}</li>
                        </ul>

                        <br>
                        <br>
                        <br>
                        <br>

                        <br>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

    import ApiService from "@/core/services/api-service";
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'TestHardwareInstructions',
        components: { "k-button": Button, },
        data() {
            return {
                loginUserType: null,
                user: {},
            }
        },
        async mounted() {
            //FETCH CURRENT USER OBJ 
            var user = await ApiService.GenerateGetRequest("admin/GetUser");
            console.log("admin/GetUser: ", user);
            this.user = user["content"];
        },
        methods: {
            async isContinue() {
                console.log("Go to setup page");
                this.$router.push('/signer/setup');
            }
        },
    }
</script>
