<template>
    <div id="app" v-cloak @drop.prevent="handleFileAddition" @dragover.prevent>
        <h5 v-if="!droppedFile">
            <img src="/images/ic_cloud_upload.png" style="width:10rem;height:10rem;margin-top:1rem;">
            <br />
            Drag & Drop Photo
        </h5>
        <ul>
            <li v-for="f in files">
                <img :src="f" style="height:100%; width:100%; max-width:20rem; max-height:15rem" v-if="f != null">
                <h5 v-if="f == null">
                    <img src="/images/ic_cloud_upload.png" style="width:10rem;height:10rem;margin-top:1rem;">
                    <br />
                    Drag & Drop Photo
                </h5>
            </li>
        </ul>
    </div>
</template>

<style scoped>
    #app{
        border-style:dashed;
        border-color:lightgrey;
        padding-left:2rem;
        padding-right:2rem;
        margin-bottom:2rem;
        text-align:center;
        height:20rem;
    }
</style>

<script>
    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'FileDropArea',
        components: {
            "k-button": Button,
        },
        props: ['clearFiles', 'imageSrc'],
        data() {
            return {
                files: [],
                droppedFile: false,
            }
        },
        watch: { // It listens to the change in prop name
            files: function () {
                //console.log("files change - file drop", this.files);
            },
            clearFiles: function () {
                if (!this.clearFiles) return;
                this.files = [];
                this.droppedFile = false;
                this.$emit("clearFiles", false);
                this.$emit("file", null);
            },
            imageSrc: function () {
                console.log("imageSrc", this.imageSrc);
                this.droppedFile = true;
                this.files = [];
                this.files.push(this.imageSrc);
                this.$emit("file", this.imageSrc);
            }
        },
        created() {
        },
        methods: {
            handleFileAddition(e) {
                let droppedFiles = e.dataTransfer.files;
                if (!droppedFiles) return;
                if (droppedFiles.length > 1) {
                    Swal.fire("Error", "You can only upload one image.", "error");
                    return;
                };
                this.files = [];
                // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
                ([...droppedFiles]).forEach(f => {
                    console.log("f", f);
                    if (f.type.match("image.*")) {                        
                        if (f) {
                            var reader = new FileReader();
                            reader.readAsDataURL(f);
                            reader.onload = () => {
                                this.droppedFile = true;
                                this.files.push(reader.result);
                                this.$emit("file", reader.result);
                            };
                        }
                    } else {
                        Swal.fire("Error", "You can only upload an image.", "error");
                    }
                });
            },
            handleFileRemoval(file) {
                this.files = this.files.filter(f => {
                    return f.file != file;
                });
                this.$emit("file", null);
            },
        },
    }
</script>
