import ApiService from "../../core/services/api-service";
import store from "../../store/index";

export default {
    namespaced: true,
    state: () => ({
        token: null,
        termsAndConditionsAgreedTo: false,
        verificationSteps: [],
        showLoader: false,
        showLoaderOverride: false,
        signerVerificationData: {
            SignerId: null,
            NotarizeId: null,
            AgreedToS: false,
            StepOrderId: -1
        },
        settings: {
            configured: false,
            showEConsentonSign: true,
            skip2Factor: false,
            skipSSN: false,
            skipSignerDetails: false,
            skipSigningRoomAuthorization: false,
            autoNavigate: false,
        },
        logo: null,
        lenderId: null,
        role: null
    }),
    mutations: {
        updateToken(state, token) {
            state.token = {};
            state.token = token;
        },
        updateLoader(state, payload) {
            state.showLoader = payload.showLoader;
        },
        updateLoaderOverride(state, payload) {
            console.log("ovveride updated", payload.showLoaderOverride);
            state.showLoaderOverride = payload.showLoaderOverride;
        },
        updateTermsAndConditions(state, payload) {
            state.termsAndConditionsAgreedTo = payload.AgreedTo;
        },
        updateVerificationSteps(state, payload) {
            state.verificationSteps = payload.VerificationSteps;
        },

        updateSignerVerificationData(state, payload) {
            state.signerVerificationData.SignerId = payload.SignerId;
            state.signerVerificationData.NotarizeId = payload.NotarizeId;
            state.signerVerificationData.AgreedToS = payload.AgreedToS;
            state.signerVerificationData.StepOrderId = payload.StepOrderId;
        },
        updateLenderId(state, payload) {
            state.lenderId = payload.LenderID;
        },
        updateLastReachedStepOrderId(state, payload) {
            state.signerVerificationData.StepOrderId = payload.StepOrderId;
        },
        resetAppState(state) {
            state.signerVerificationData.SignerId = null;
            state.signerVerificationData.NotarizeId = null;
            state.signerVerificationData.AgreedToS = false;
            state.signerVerificationData.StepOrderId = -1;
            state.termsAndConditionsAgreedTo = false;
        },
        updateLogo(state, logo) {
            state.logo = logo;
        },
        updateAppSettings(state, payload) {
            console.log("payload", payload)
            state.settings.configured = true;
            state.settings.showEConsentonSign = payload.showEConsentonSign;
            state.settings.skip2Factor = payload.skip2Factor;
            state.settings.skipSSN = payload.skipSSN;
            state.settings.skipSignerDetails = payload.skipSignerDetails;
            state.settings.skipSigningRoomAuthorization = payload.skipSigningRoomAuthorization;
            state.settings.tagOpacity = payload.tagOpacity;
            state.settings.autoNavigate = payload.autoNavigate;
        },
        updateRole(state, payload) {
            state.role = payload.role;
        },
    },
    getters: {
        token: (state) => {
            return state.token;
        },
        termsAndConditions: (state) => {
            return state.termsAndConditionsAgreedTo;
        },
        verificationSteps: (state) => {
            return state.verificationSteps;
        },
        currentIncompleteVerificationStep: (state) => {
            return state.signerVerificationData.StepOrderId;
        },
        showLoader: (state) => {
            return state.showLoader;
        },
        showLoaderOverride: (state) => {
            return state.showLoaderOverride;
        },
        currentLender: (state) => {
            return state.lenderId;
        },
        getLogo: (state) => {
            return state.logo;
        },
        appSettings: async (state) => {
            if (!state.settings.configured) {
                var inviteToken = store.getters["Auth/invite"];
                var signingDetailsResponse = await ApiService.GenerateGetRequest(
                    `signer/invite?identitytoken=${inviteToken}`
                );
                if (!signingDetailsResponse["isSuccess"])
                    throw 'Cannot get signer settings!';
                store.commit("App/updateAppSettings", signingDetailsResponse["content"]["settings"]);
            }


            return state.settings;
        },
        getRole: (state) => {
            return state.role;
        }
    }
}