<template>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container">
            <div class="bdr-bottom row">
                <div class="title col-md-12 text-center">
                    <h2>Online Notary FAQ</h2>
                </div>
            </div>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>F.A.Q.</h2>
                </div>
            </div>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>Who is E-Notary Seal?</h2>
                </div>
            </div>
            <p>
                E-Notary Seal is an electronic notary public platform service that allows consumers and businesses to complete the notarization process entirely online.
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>Is E-Notary Seal legal in my state?</h2>
                </div>
            </div>
            <p>
                E-Notary Seal is a remote electronic notary public platform that enables Texas notaries, as authorized by Texas law, to notarize documents for use in Texas and in states across the country. Every state provides legal authority for recognition and acceptance of common out-of-state notarizations, including those from Texas.
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>How is E-Notary Seal legal?</h2>
                </div>
            </div>
            <p>
                In July 2018, the State of Texas passed Gov&apos;t Code &sect;406.008(b) which allows appropriately certified Texas notaries to serve customers online. And, these notaries were given the explicit authority to serve Americans no matter where they&apos;re located. These online notarizations are accepted across the country due to the Full Faith and Credit Clause of the United States Constitution and state laws that specifically address and accept out of state notarizations.
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>How does E-Notary Seal work?</h2>
                </div>
            </div>
            <p>
                E-Notary Seal is an electronic notary public platform and software service offered to clients via any web-enabled desktop or laptop with a webcam. E-Notary Seal&apos;s service enables a face-to-face meeting with a notary, just like meeting with a traditional notary public in-person, but the meeting takes place entirely online.
            </p>
            <p>
                You simply register on the E-Notary Seal website, then upload your document(s) and verify your identity. You verify your identity using knowledge-based authentication, which offers you a series of specific questions that only you can answer. Once your ID has been verified, your identity is further validated using a government-issued photo ID. You are then connected with a commissioned Texas Electronic Notary Public via live audio-video call. The notary public re-
                confirms your identity visually using the scanned photo ID, and then witnesses as you electronically sign the document. Our notary then completes the electronic notarization process using digital tools.
            </p>
            <p>
                Once complete, you will receive detailed instructions on how to access your notarized document online. You can choose to print and deliver it or send it electronically to whomever you would like. The whole process is completed in minutes. In accordance with the Texas statutes governing electronic notarization, all video calls are recorded so that any authorized party may verify your electronic notarization.
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>How much does it cost to notarize a document?</h2>
                </div>
            </div>
            <p>
                $25 per each Notary Seal applied.
                <br />
                <br />
                <br />
                +$6 &horbar; For each signers 1st signature.
                <br />
                +$1 &horbar; For each additional signature per signer
            </p>
            <p>
                Please note that we do offer discounted pricing based on volume. If you&apos;d like to explore volume pricing, please email
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>What Documents we DO NOT NOTARIZE?</h2>
                </div>
            </div>
            <p>
                <ul>
                    <li>&bull; Wills, codicils, and trusts</li>
                    <li>&bull; Documents for use in Iowa</li>
                    <li>&bull; Motor vehicle title transfers and odometer statements.</li>
                    <li>&bull; I-9</li>
                    <li>&bull; Our notaries cannot perform marriage ceremonies</li>
                    <li>&bull; Our notaries cannot certify true copies of birth, marriage, death certificates or court records.</li>
                </ul>
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>Can you notarize a document that isn&apos;t written in English?</h2>
                </div>
            </div>
            <p>
                Not at this time. Please follow the following steps to translate into English:
                <br />
                1.) Go to www.onehourtranslation.com and have the document translated.
                <br />
                2.) Upload the translated form to Lets Get Started.
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>Can Marriage certificates, Birth or Death certificates be notarized?</h2>
                </div>
            </div>
            <p>
                Texas notaries are NOT authorized to notarize and certify true copies of these documents at this time.
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>Can I notarize an I-9?</h2>
                </div>
            </div>
            <p>
                We do not notarize I-9 forms at this time.
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>Can I notarize a document for use outside the United States?</h2>
                </div>
            </div>
            <p>
                The Answer is No&hellip;
                <br />
                When a notarized document is intended for use in another country, the notary&apos;s commission and current status must typically be certified by his or her Secretary of State.
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>What are the acceptable forms of ID that I can use on E-Notary Seal?</h2>
                </div>
            </div>
            <p>
                Acceptable forms of ID include the following photo IDs if valid and unexpired:
                <ul>
                    <li>&bull; U.S. State Issued Driver&apos;s License</li>
                    <li>&bull; U.S. State Issued Photo ID</li>
                    <li>&bull; U.S. Passport Book</li>
                    <li>&bull; U.S. Passport Card</li>
                    <li>&bull; Certificate of US citizenship</li>
                    <li>&bull; Certificate of Naturalization</li>
                    <li>&bull; Foreign Passport</li>
                    <li>&bull; Alien Registration Card with Photo</li>
                </ul>
            </p>
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <h2>What type of payments are accepted?</h2>
                </div>
            </div>
            <p>
                We accept all major credit cards and Stripe and PayPal.
            </p>
        </div>
    </section>


</template>

<script>
    export default {
        name: 'FAQ',
    }
</script>